import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Footer from '../footer/Footer';
import WithdrawContentNoEditMain from './no-edit/WithdrawContentNoEditMain';

const DepositContent = (props) => {
    const {
        network,networkType,token,address,amount,gas,balance,fundingBalance,language,
    } = props;
    // const params = new URLSearchParams(window.location.search);
    // const network = params.get('network');
    // const networkType = params.get('networkType');
    // const token = params.get('token');
    // const address = params.get('address');
    // const amount = params.get('amount');
    // const gas = params.get('gas');
    // const balance = params.get('balance');
    // const fundingBalance = params.get('fundingBalance');
    // const language = params.get('language');

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    return (
        <Grid>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between',
            }}>
                {/* <DepositContentHeader /> */}
                <WithdrawContentNoEditMain 
                    network={network}
                    networkType={networkType}
                    token={token}
                    address={address}
                    amount={amount}
                    gas={gas}
                    balance={balance}
                    fundingBalance={fundingBalance}
                    language={language}
                />
                <Footer />
            </Box>
        </Grid>
    );
}

export default DepositContent;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import TTLoadImage from '../common/TTLoadImage';

const HomeContentMainGameProvidersItem = (props) => {
    const { image,index } = props;
    const theme = useTheme();

    const handleClick = () => {
        console.log('点击了游戏供应商'+index);
    }

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            bgcolor:'#2A2A39',
            borderRadius:'8px',
            width:'182px',
            height:'58px',
            cursor:'pointer',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'calc((100vw - 67px) / 3)',
                height:'calc(((100vw - 56px) / 3)*33/106)',
                borderRadius:'5px',
            },
        }} onClick={handleClick}>
            {/* <CardMedia
                component="img"
                image={ image }
                sx={{
                    objectFit:'contain',
                    width:'124px',
                    height:'44px',
                    borderRadius:'24px',
                }}
            /> */}
            <TTLoadImage 
                image={image}
                objectFit={'contain'}
                width={'124px'}
                height={'44px'}
                borderRadius={'24px'}
                borderRadiusMobile={'8px'}
                skeletonBgColor={'#2A2A39'}
            />
        </Box>
    );
}

export default HomeContentMainGameProvidersItem;
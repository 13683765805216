import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Fab from '@mui/material/Fab';
import CardMedia from '@mui/material/CardMedia';
import customerIcon from '../../assets/images/home/home-customer.png';
import { getCustomerLinkService } from '../../network/service/home';
import TTLoading from '../common/TTLoading';

const Customer = () => {
    const theme = useTheme();

    const [ customerLink,setCustomerLink ] = useState('');
    const [ openLoading,setOpenLoading ] = useState(false);

    const handleCustomerClick = () => {
        if(customerLink === '') {
            setOpenLoading(true);
            handleFetchCustomer((link) => {
                setOpenLoading(false);
                window.location.href = link;
            });
            return;
        }
        window.location.href = customerLink;
    };

    const handleFetchCustomer = async (callBack) => {
        try {
            const response = await getCustomerLinkService();
            setCustomerLink(response.data.customer_service_link);
            callBack && callBack(response.data.customer_service_link);
        } catch (error) {
            console.log('handleFetchCustomer error:' + error);
        }
    }

    useEffect(() => {
        handleFetchCustomer();
    }, []);

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />

            <Fab variant='extended' onClick={handleCustomerClick} sx={{
                bgcolor:'transparent',
                position:'fixed',
                bottom:'42px',
                right:'0px',
                float:'right',
                width:'80px',
                height:'50px',
                '&:hover': {
                    bgcolor:'transparent',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'76px',
                    height:'48px',
                    bottom:'71px',
                },
            }}>
                <CardMedia
                    component="img"
                    image={customerIcon}
                    sx={{
                        width:'80px',
                        height:'50px',
                        objectFit:'cover',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'76px',
                            height:'48px',
                        },
                    }}
                />
            </Fab>
        </React.Fragment>
    );
}

export default Customer;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Footer from '../footer/Footer';
import LiveCasinoContentMain from './LiveCasinoContentMain';

const LiveCasinoContent = () => {
    const theme = useTheme();

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    return (
        <Grid sx={{
            pl:'260px',
            pt:'64px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                pl:'0px',
                pt:'114px',
            },
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between',
            }}>
                <LiveCasinoContentMain />
                <Footer />
            </Box>
        </Grid>
    );
}

export default LiveCasinoContent;
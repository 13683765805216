import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ReferralContentMainPlaceholder from './ReferralContentMainPlaceholder';
import ReferralContentMainContent from './ReferralContentMainContent';

const ReferralContentMain = () => {
    const theme = useTheme();
    const token = useSelector((state) => state.user.access_token);

    return (
        <Grid container sx={{
            padding:'36px 60px 145px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'8px 0px 8px 0px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                minHeight:'500px',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1020px',
                }
            }}>
                { token !== undefined && token.length > 0 ? 
                    <ReferralContentMainContent /> : 
                    <ReferralContentMainPlaceholder />
                }
            </Container>
        </Grid>
    );
}   

export default ReferralContentMain;
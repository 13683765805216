import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FooterToken from './FooterToken';
import FooterContent from './FooterContent';
import FooterOnlinePeople from './FooterOnlinePeople';

const Footer = (props) => {
    const { isHover } = props;
    const theme = useTheme();

    if(isHover) {
        return (
            <Grid container sx={{
                bgcolor:'#2A2A39',
                padding:'55px 60px',
                position:'absolute',
                bottom:'0px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'18px',
                },
            }}>
                <Container sx={{
                    // bgcolor:'#333333',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'start',
                    alignItems:'start',
                    gap:'45px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'25px',
                    },
                    '@media': {
                        ml:'60px',
                        mr:'60px',
                        pl:'0px',
                        pr:'0px',
                        maxWidth:'1000px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            ml:'0px',
                            mr:'0px',
                            maxWidth:'100%',
                        },
                    }
                }}>
                    <FooterContent />
                    <FooterToken />
                </Container>
            </Grid>
        );
    }

    return (
        <Grid container sx={{
            bgcolor:'#2A2A39',
            padding:'55px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'18px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#333333',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                gap:'45px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    gap:'25px',
                },
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        ml:'0px',
                        mr:'0px',
                        maxWidth:'100%',
                    },
                }
            }}>
                <FooterContent />
                <FooterToken />
                <FooterOnlinePeople />
            </Container>
        </Grid>
    );
}

export default Footer;
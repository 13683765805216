import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import passwordIcon from '../../assets/images/login-and-register/login-and-register-password.png';
import passwordTipIcon from '../../assets/images/login-and-register/login-and-register-password-tip.png';
import passwordSeeIcon from '../../assets/images/login-and-register/login-and-register-password-see.png';
import passwordHiddenIcon from '../../assets/images/login-and-register/login-and-register-password-hidden.png';
import LoginAndRegisterContentInputTip from "./LoginAndRegisterContentInputTip";

const EmailInput = styled(InputBase)(({ theme }) => ({
    // '& .MuiInputBase-input': {
      position: 'relative',
      border: '1px solid',
      borderColor: 'transparent',
      fontSize: '14px',
      width:'100%',
      height:'100%',
      color:'#FFFFFF',
      fullWidth:true,
      backgroundColor: 'transparent',
      [`& .${inputBaseClasses.input}::placeholder`]: {
        color: '#6D728D',
        opacity: 1,
      },
      [`& .${inputBaseClasses.input}:-webkit-autofill`]: {
        backgroundColor: 'transparent !important',
        color:'#FFFFFF !important',
        fontSize: '14px !important',
        transition: theme.transitions.create(['background-color','color','font-size'], {
            duration: 99999,
            easing: 'ease-in-out',
            delay: 99999,
        }),
        [theme.breakpoints.down(MobileBreakpoint.match)]: {
            fontSize:'12px !important',
        },
      },
      [theme.breakpoints.down(MobileBreakpoint.match)]: {
        // width:'calc(100vw - 150px)',
        fontSize:'12px',
      },
    // },
    [`& .${inputBaseClasses.input}`]: {
        caretColor:'#1CA45F',
    },
}));

const CustomInput = (props) => {    
    const { placeholder,defaultText,isAutoFocus,isMobileReset,isInputTip,tipText,handleInputChanged } = props;
    const theme = useTheme();
    const { focused } = useFormControl() || {};

    const [ inputType, setInputType ] = useState('password');

    const borderColor = React.useMemo(() => {
        return focused ? '#1CA45F' : (isInputTip ? '#C25156' : 'transparent');
    }, [focused,isInputTip]);
    const icon = React.useMemo(() => {
        return isInputTip ? (focused ? passwordIcon : passwordTipIcon) : passwordIcon;
    }, [focused,isInputTip]);
    const showInputTip = React.useMemo(() => {
        return isInputTip ? (focused ? false : true) : false;
    }, [focused,isInputTip]);

    const handleSeeOrHiddenPWDClick = () => {
        if(inputType === 'password') {
            setInputType('text');
        }else {
            setInputType('password');
        }
    };

    const handleInputValueChanged = (e) => {
        const filteredValue = e.target.value.replace(/[\u4E00-\u9FA5]/g, '');
        e.target.value = filteredValue;

        handleInputChanged && handleInputChanged(e);
    }

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'stretch',
            alignItems:'center',
            width:'100%',
            height:'50px',
            backgroundColor: '#0D0F13',
            border: '1px solid',
            borderColor: borderColor,
            borderRadius:'25px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'100%',
                backgroundColor:'#171721',
                height:isMobileReset ? '36px' : '50px',
                borderRadius:isMobileReset ? '18px' : '25px',
            },
        }}>
            <CardMedia
                component='img'
                image={icon}
                sx={{
                    ml:'20px',
                    mr:'12px',
                    width:'17px',
                    height:'20px',
                }}
            />
            <EmailInput 
                type={inputType} 
                placeholder={placeholder} 
                value={defaultText}
                autoFocus={isAutoFocus}
                onChange={handleInputValueChanged} 
            />
           
            <Button variant="image" disableRipple onClick={handleSeeOrHiddenPWDClick} sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'62px',
                height:'100%',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}>
                <CardMedia
                    component='img'
                    image={(inputType === 'password') ? passwordSeeIcon : passwordHiddenIcon}
                    sx={{
                        width:'32px',
                        height:'32px',
                    }}
                />
            </Button>

            <Box sx={{
                position:'absolute',
                left:'5px',
                top:'23px',
                zIndex:1,
                pointerEvents: 'none',
            }}>
                <LoginAndRegisterContentInputTip 
                    open={showInputTip}
                    text={tipText}
                />
            </Box>
        </Box>
    );
};

const LoginAndRegisterContentPasswordInput = (props) => {
    const { placeholder,defaultText,isAutoFocus,isMobileReset,isInputTip,tipText,handleInputChanged } = props;

    return (
        <FormControl sx={{
            width:'100%',
        }}>
             <CustomInput 
                placeholder={placeholder} 
                defaultText={defaultText}
                autoFocus={isAutoFocus}
                isMobileReset={isMobileReset}
                isInputTip={isInputTip}
                tipText={tipText}
                handleInputChanged={handleInputChanged} 
            />
        </FormControl>
    )
}

export default LoginAndRegisterContentPasswordInput;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import QRCode from 'qrcode.react';

const DepositContentQR = (props) => {
    const { qrText,theAddressDepositing,itDoesDeposit,token } = props;
    const theme = useTheme();
    
    return (
        <Box sx={{
            bgcolor:'#1D1D28',
            borderRadius:'18px',
            padding:'45px 60px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            gap:'16px',
            width:'calc(100% - 120px)',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                gap:'18px',
                borderRadius:'0px',
                padding:'18px',
                width:'calc(100% - 36px)',
            },
        }}>
            <QRCode value={qrText} size={130} />
            <Typography sx={{
                color:'#6D718D',
                fontSize:'22px',
                fontStyle:'normal',
                fontWeight:500,
                textAlign:'center',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                },
            }}>
                {`${theAddressDepositing} ${token}. ${itDoesDeposit}`}
            </Typography>
        </Box>
    )
}

export default DepositContentQR;
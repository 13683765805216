import React from 'react';
import { useTranslation } from "react-i18next";
import Customer from '../component/common/Customer';
import TTAppBar from '../component/common/TTAppBar';
import SearchContent from '../component/search/SearchContent';
import { useLocation } from 'react-router-dom';

const Search = () => {
    const location = useLocation();
    const type = location.state && location.state.type;
    const gameProvider = location.state && location.state.gameProvider;

    const { t } = useTranslation();
    const textData = {
        search: t("search"),
        home: t("home"),
        popular_slots: t("popular_slots"),
        new_slots: t("new_slots"),
        hot_slots: t("hot_slots"),
        fishing: t("fishing"),
        favorite: t("favorite"),
        recent: t("recent"),
    };

    const handleCategoryText = () => {
        let categoryText = '';
        if(type === 'home') {
            categoryText = textData.home;
        }else if(type === 'popular') {
            categoryText = textData.popular_slots;
        }else if(type === 'new') {
            categoryText = textData.new_slots;
        }else if(type === 'hot') {
            categoryText = textData.hot_slots;
        }else if(type === 'fishing') {
            categoryText = textData.fishing;
        }else if(type === 'favorite') {
            categoryText = textData.favorite;
        }else if(type === 'recent') {
            categoryText = textData.recent;
        }
        return categoryText;
    };

    return (
        <React.Fragment>
            <Customer />
            <TTAppBar style={1} title={handleCategoryText()} />
            <SearchContent type={type} gameProvider={gameProvider} />
        </React.Fragment>
    )
}

export default Search;
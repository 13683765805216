import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SearchContentMain from './SearchContentMain';

const SearchContent = (props) => {
    const { type,gameProvider } = props;
    const theme = useTheme();

    return (
        <Grid sx={{
            pl:'260px',
            pt:'64px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                pl:'0px',
                pt:'114px',
            },
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between',
            }}>
                <SearchContentMain type={type} gameProvider={gameProvider} />
            </Box>
        </Grid>
    );
}

export default SearchContent;
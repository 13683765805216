import React from 'react';
import Customer from '../component/common/Customer';
import FAQContent from '../component/faq/FAQContent';
import TTAppBar from '../component/common/TTAppBar';

const FAQ = () => {
    return (
        <React.Fragment>
            <Customer />
            <TTAppBar  
                style={4} 
                title={'FAQ'}
            />
            <FAQContent />
        </React.Fragment>
    )
}

export default FAQ;
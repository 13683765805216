import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBreakpoint from '../tools/MobileBreakpoint';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import TokenRecordContent from '../component/token-record/TokenRecordContent';
import { useLocation } from 'react-router-dom';
import TTAppBar from '../component/common/TTAppBar';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { switchAppCategory } from '../features/appSlice';

const TokenRecord = () => {
    const location = useLocation();
    const token = location.state && location.state.token;
    const category = location.state && location.state.category;

    const { t } = useTranslation();
    const textData = {
        transaction: t("transaction"),
        back: t("back"),
    };
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const dispatch = useDispatch();
    const appCategory = useSelector((state) => state.app.appCategory);
    useEffect(() => {
        if(appCategory !== 'casino') {
            dispatch(switchAppCategory({appCategory:'casino'}));
        }
    }, []);

    if(screenMD) {
        return (
            <React.Fragment>
                <Customer />
                <TTAppBar  
                    style={3} 
                    title={textData.transaction} 
                    rightBackTitle={textData.back}
                />
                <TokenRecordContent token={token} category={category} screenMD={screenMD} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.poker} drawerIndex={-1} />
            <TokenRecordContent token={token} category={category} />
        </React.Fragment>
    )
}

export default TokenRecord;
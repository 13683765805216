import React, { useEffect } from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import HomeContent from '../component/home/HomeContent';
import LaunchLoading from '../component/common/LaunchLoading';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserCookies, setCookieAccessToken, setCookieUserId, setCookieUsername } from '../tools/CommonTool';
import { tgBotDisposableLoginService, userInfoService } from '../network/service/user';
import { userLogin, userRefreshUserInfo } from '../features/userSlice';
import { launchRedirectedSport, switchAppCategory, tgBotLogged } from '../features/appSlice';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const urlObj = new URL(window.location.href);
    const params = new URLSearchParams(urlObj.search);
    // const params = new URLSearchParams(window.location.search);
    const disposableToken = params.get('disposable_token');
    const inviteCode = params.get('invite_code');
    // console.log(`disposable_token:${disposableToken},inviteCode:${inviteCode}`);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const launchRedirectSport = useSelector((state) => state.app.launchRedirectSport);
    const allowSport = useSelector((state) => state.user.user.allow_sport);
    const homeShowLoading = useSelector((state) => state.app.homeShowLoading);
    const token = useSelector((state) => state.user.access_token);
    const userId = useSelector((state) => state.user.user.pk);
    useEffect(() => {   
        if(disposableToken !== undefined && 
            disposableToken !== null && 
            disposableToken.length > 0) {
            const cacheDisposableToken = localStorage.getItem('TTDisposableToken');
            if(cacheDisposableToken !== undefined && 
                cacheDisposableToken !== null && 
                cacheDisposableToken.length > 0 && 
                cacheDisposableToken === disposableToken) {
                    console.log('当前的disposable_token已授权过');
                    if(token !== undefined && 
                        token !== null && 
                        token.length > 0 && 
                        launchRedirectSport &&
                        allowSport) {
                        dispatch(switchAppCategory({appCategory:'sports'}));
                        dispatch(launchRedirectedSport());
                        navigate(RoutePath.sports);
                    }else {
                        dispatch(switchAppCategory({appCategory:'casino'}));
                        dispatch(tgBotLogged());
                    }
                }else {
                    // 先清除旧的登录信息
                    clearUserCookies();
                    
                    handleTGBotLogin();
                }
        }else {
            if(token !== undefined && 
                token !== null && 
                token.length > 0 && 
                launchRedirectSport) {
                handleGetUserInfo();
            }else {
                dispatch(switchAppCategory({appCategory:'casino'}));
                dispatch(tgBotLogged());
            }
        }
    }, []);

    const handleTGBotLogin = async () => {
        try {
            const param = {
                disposable_token:disposableToken,
                invite_code:inviteCode
            }
            const response = await tgBotDisposableLoginService(param);
            setCookieAccessToken(response.data.access_token);
            setCookieUserId(response.data.user.pk);
            setCookieUsername(response.data.user.username);
            dispatch(userLogin(response.data));

            // 缓存上次登录的授权token
            localStorage.setItem('TTDisposableToken',disposableToken);

            if(launchRedirectSport && allowSport) {
                dispatch(switchAppCategory({appCategory:'sports'}));
                dispatch(launchRedirectedSport());
                navigate(RoutePath.sports);
            }else {
                dispatch(tgBotLogged());
            }
        } catch (error) {
            console.log('handleTGBotLogin error:'+error);
            navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.home}});
        }
    };
    const handleGetUserInfo = async () => {
        try {
            const response = await userInfoService(userId);
            dispatch(userRefreshUserInfo(response.data));

            if(response.data.allow_sport) {
                dispatch(switchAppCategory({appCategory:'sports'}));
                dispatch(launchRedirectedSport());
                navigate(RoutePath.sports);
            }else {
                dispatch(switchAppCategory({appCategory:'casino'}));
                dispatch(tgBotLogged());
            }
        } catch (error) {
            console.log('getUserInfo error:'+error);
            dispatch(switchAppCategory({appCategory:'casino'}));
            dispatch(tgBotLogged());
        }
    };

    return (
        <React.Fragment>
            {homeShowLoading ?
                <LaunchLoading /> :
                <React.Fragment>
                    <Customer />
                    <Header fromPage={RoutePath.home} drawerIndex={0} />
                    <HomeContent />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Home;
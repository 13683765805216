import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import bannerIcon from '../../assets/images/referral/referral-banner.png';
import ReferralContentMainContentEarning from './ReferralContentMainContentEarning';
import ReferralContentMainContentAgent from './ReferralContentMainContentAgent';
import ReferralContentMainContentCode from './ReferralContentMainContentCode';
import ReferralContentMainContentReferralData from './ReferralContentMainContentReferralData';
import { getReferralGetReferralInfoService } from '../../network/service/referral';
import { useDispatch, useSelector } from 'react-redux';
import { userReferralInfoRefresh } from '../../features/referralSlice';

const ReferralContentMainContent = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();

    const referralInfo = useSelector((state) => state.referral.referralInfo);

    const handleFetchReferralInfo = async () => {
        try {
            const response = await getReferralGetReferralInfoService();
            dispatch(userReferralInfoRefresh({referralInfo:response.data.data}));
        } catch (error) {
            console.log('handleFetchReferralInfo error:'+error);
        }
    };

    useEffect(() => {
        handleFetchReferralInfo();
    }, []);

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width:'100%',
            gap:'48px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                gap:'8px',
            },
        }}>
            {screenMD ? 
                <Box sx={{

                }}>
                    <CardMedia
                        component="img"
                        image={bannerIcon}
                        sx={{
                            width:'calc(100vw - 36px)',
                            height:'calc((100vw - 36px) * 99px / 339px)',
                            objectFit:'cover',
                            borderRadius:'8px',
                            padding:'0px 18px',
                        }}
                    />
                </Box> : ''
            }
            <ReferralContentMainContentEarning 
                tokenArr={referralInfo.total_commission}
                amount1={referralInfo.subordinate_cnt}
                amount2={referralInfo.yesterday_active}
            />
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'flex-start',
                gap:'18px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    gap:'0px',
                },
            }}>
                <ReferralContentMainContentAgent 
                    amount1={referralInfo.yesterday_active}
                    amount2={referralInfo.last_7_day_active_avg}
                />
                <ReferralContentMainContentCode />
            </Grid>
            <ReferralContentMainContentReferralData 
                yesterdayArr={referralInfo.yesterday_commission}
                sevenDayArr={referralInfo.last_7_day_commission}
                thirtyDayArr={referralInfo.last_30_day_commission}
            />
        </Box>
    );
}

export default ReferralContentMainContent;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import bannerIcon from '../../assets/images/home/home-effect-banner.webp';
import coinIcon1 from '../../assets/images/home/home-effect-coin-1.webp';
import coinIcon3 from '../../assets/images/home/home-effect-coin-3.webp';
import coinIcon5 from '../../assets/images/home/home-effect-coin-5.webp';
import coinIcon6 from '../../assets/images/home/home-effect-coin-6.webp';
import lightIcon from '../../assets/images/home/home-effect-light.webp';
import TTLoadImage from '../common/TTLoadImage';
import { keyframes } from '@mui/system';


const HomeContentMainEffectBanner = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    let coin1TranslateY = '26px';
    let coin3TranslateY = '-26px';
    let coin5TranslateY = '20px';
    let coin6TranslateX = '-12px';
    let coin6TranslateY = '12px';

    if(screenMD) {
        coin1TranslateY = '12px';
        coin3TranslateY = '-12px';
        coin5TranslateY = '8px';
        coin6TranslateX = '-5px';
        coin6TranslateY = '6px';
    }

    // 创建关键帧动画
    const coin1Animation = keyframes`
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(${coin1TranslateY});
        }
        100% {
            transform: translateY(0);
        }
    `;
    const coin3Animation = keyframes`
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(${coin3TranslateY});
        }
        100% {
            transform: translateY(0);
        }
    `;
    const coin5Animation = keyframes`
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(${coin5TranslateY});
        }
        100% {
            transform: translateY(0);
        }
    `;
    const coin6Animation = keyframes`
        0% {
            transform: translate(0,0);
        }
        50% {
            transform: translate(${coin6TranslateX},${coin6TranslateY});
        }
        100% {
            transform: translate0(0,0);
        }
    `;
    const light1Animation = keyframes`
        0% {
            opacity: 0;
            scale: 0;
        }
        25% {
            opacity: 0;
            scale: 0;
        }
        50% {
            opacity: 1;
            scale: 1;
        }
        75% {
            opacity: 0;
            scale: 0;
        }
        100% {
            opacity: 0;
            scale: 0;
        }
    `;
    const light2Animation = keyframes`
        0% {
            opacity: 0;
            scale: 0;
        }
        25% {
            opacity: 1;
            scale: 1;
        }
        50% {
            opacity: 0;
            scale: 0;
        }
        75% {
            opacity: 0;
            scale: 0;
        }
        100% {
            opacity: 0;
            scale: 0;
        }
    `;
    const light3Animation = keyframes`
        0% {
            opacity: 0;
            scale: 0;
        }
        25% {
            opacity: 0;
            scale: 0;
        }
        50% {
            opacity: 0;
            scale: 0;
        }
        75% {
            opacity: 1;
            scale: 1;
        }
        100% {
            opacity: 0;
            scale: 0;
        }
    `;
    
    return (
        <Box sx={{
            position:'relative',
            width:'100%',
            height:'188px',
            borderRadius:'17px',
            mt:'48px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                width:'100%',
                height:'calc((100vw - 36px) * 66 / 339)',
                borderRadius:'8px',
                mt:'25px',
            },
        }}>
            <TTLoadImage 
                image={bannerIcon}
                objectFit={'cover'}
                width={'100%'}
                height={'100%'}
                borderRadius={'17px'}
                borderRadiusMobile={'8px'}
                skeletonBgColor={'#2A2A39'}
            />
            <CardMedia
                component="img"
                image={coinIcon1}
                sx={{
                    objectFit:'contain',
                    position:'absolute',
                    width:'48px',
                    height:'48px',
                    top:'0%',
                    left:'38%',
                    animation:`${coin1Animation} 3s ease-in-out infinite`,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'24px',
                        height:'24px',
                    },
                }}
            /> 
            <CardMedia
                component="img"
                image={coinIcon3}
                sx={{
                    objectFit:'contain',
                    width:'40px',
                    height:'43px',
                    position:'absolute',
                    top:'35%',
                    left:'46%',
                    animation:`${coin3Animation} 4s ease-in-out infinite`,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'20px',
                        height:'21px',
                        left:'44%',
                    },
                }}
            /> 
            <CardMedia
                component="img"
                image={coinIcon5}
                sx={{
                    objectFit:'contain',
                    width:'81px',
                    height:'55px',
                    position:'absolute',
                    top:'47%',
                    left:'47%',
                    animation:`${coin5Animation} 3s ease-in-out infinite`,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'40px',
                        height:'28px',
                        top:'40%',
                        left:'44%',
                    },
                }}
            /> 
            <CardMedia
                component="img"
                image={coinIcon6}
                sx={{
                    objectFit:'contain',
                    width:'76px',
                    height:'30px',
                    position:'absolute',
                    top:'34%',
                    left:'51%',
                    animation:`${coin6Animation} 4s ease-in-out infinite`,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'35px',
                        height:'20px',
                        top:'29%',
                        left:'49%',
                    },
                }}
            /> 
            <CardMedia
                component="img"
                image={lightIcon}
                sx={{
                    objectFit:'contain',
                    width:'135px',
                    height:'99px',
                    position:'absolute',
                    top:'69.8%',
                    left:'47.8%',
                    animation:`${light1Animation} 8s ease-in-out infinite`,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'89px',
                        height:'66px',
                        top:'55%',
                        left:'43%',
                    },
                }}
            /> 
            <CardMedia
                component="img"
                image={lightIcon}
                sx={{
                    objectFit:'contain',
                    width:'135px',
                    height:'99px',
                    position:'absolute',
                    top:'71%',
                    left:'71%',
                    animation:`${light2Animation} 8s ease-in-out infinite`,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'89px',
                        height:'66px',
                        top:'55%',
                        left:'66%',
                    },
                }}
            /> 
            <CardMedia
                component="img"
                image={lightIcon}
                sx={{
                    objectFit:'contain',
                    width:'135px',
                    height:'99px',
                    position:'absolute',
                    top:'30%',
                    left:'62%',
                    animation:`${light3Animation} 8s ease-in-out infinite`,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'89px',
                        height:'66px',
                        top:'19%',
                        left:'58%',
                    },
                }}
            /> 
        </Box>
    );
}

export default HomeContentMainEffectBanner;
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import bannerBig1 from '../../assets/images/home/home-banner-1.webp';
import bannerBig2 from '../../assets/images/home/home-banner-2.webp';
import bannerBig3 from '../../assets/images/home/home-banner-3.webp';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';
import { useSelector } from 'react-redux';
import TTLoadImage from '../common/TTLoadImage';
import '../../styles/ttSwiper.css';

const HomeContentMainBannerNew = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const accessToken = useSelector((state) => state.user.access_token);

    const imageMobileArr = [
        bannerBig1,
        bannerBig2,
        bannerBig3,
    ];

    const [hovered, setHovered] = useState(new Array(imageMobileArr.length).fill(false));

    const handleSlideClick = (index) => {
        if(index === 0 || index === 3) {
            navigate(RoutePath.inHouseGames);
        }else if(index === 1 || index === 4) {
            navigate(RoutePath.poker);
        }else if(index === 2 || index === 5) {
            if(accessToken === undefined || accessToken.length <= 0) {
                navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.home}});
                return;
            }
            navigate(RoutePath.wallet);
        }
    };
    const onMouseEnter = (index) => {
        const newHovered = new Array(imageMobileArr.length).fill(false);
        newHovered[index] = true;
        setHovered(newHovered);
    };
    const onMouseLeave = (index) => {
        const newHovered = new Array(imageMobileArr.length).fill(false);
        newHovered[index] = false;
        setHovered(newHovered);
    };

    return (
        <Box sx={{
            mt:'8px',
            width:'100%',
            height:'170px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                height:'calc((100vw - 36px) * 152 / 339)',
                mt:'18px'
            },
        }}>
            <Swiper 
                slidesPerView={screenMD ? 1 : 3}
                spaceBetween={'18px'}
                pagination={{ clickable: true }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                loop={true}
                modules={[Autoplay,Pagination]} 
                style={{
                    width:"100%",
                    height:'100%',
                    borderRadius:'8px',
                    zIndex:0,
                    '--swiper-pagination-color':'#fff',
                    '--swiper-pagination-bullet-width': '8px',
                    '--swiper-pagination-bullet-height': '8px',
                    '--swiper-pagination-bottom': '0px',
                    '--swiper-pagination-bullet-inactive-color': '#47494C',
                    '--swiper-pagination-bullet-inactive-opacity': 1,
                }}
            >
                {imageMobileArr.map((item,index) => (
                    <SwiperSlide key={index} style={{
                        height:'calc(100% - 30px)',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        cursor:'pointer',
                        opacity: hovered[index] ? 0.8 : 1,
                        transition: 'opacity 0.3s ease'
                    }} onClick={() => handleSlideClick(index)} onMouseEnter={() => onMouseEnter(index)} onMouseLeave={() => onMouseLeave(index)}>
                        <TTLoadImage 
                            image={item}
                            objectFit={'cover'}
                            width={'100%'}
                            height={'100%'}
                            borderRadius={'8px'}
                            borderRadiusMobile={'8px'}
                            skeletonBgColor={'#2A2A39'}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
}

export default HomeContentMainBannerNew;
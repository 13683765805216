import api from "../api";
import httpManager from "../httpManager";

// 获取钱包余额
export const userWalletInfosService = () => {
    return httpManager({
        url:api.gameGetSimpleTokensInfo,
        method:'post',
        data:{},
    });
}

// 获取重置地址
export const walletDepositInfoService = (params) => {
    return httpManager({
        url:api.gameGetTokensInfo,
        method:'post',
        data:params,
    });
}

// 划转
export const walletTransferService = (params) => {
    return httpManager({
        url:api.walletTransfer,
        method:'post',
        data:params,
    });
}

// 提现
export const walletWithdrawService = (params) => {
    return httpManager({
        url:api.walletWithdrawRequest,
        method:'post',
        data:params,
    });
};

// 充值记录
export const walletDepositRecordService = (params) => {
    return httpManager({
        url:api.walletGetDepositRecord,
        method:'get',
        data:params,
    });
}

// 提现记录
export const walletWithdrawRecordService = (params) => {
    return httpManager({
        url:api.walletGetWithdrawRecord,
        method:'get',
        data:params,
    });
}
import React from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";

const LoginAndRegisterContentRightHeaderButton = (props) => {
    const { title, isSelected, onClick } = props;
    const theme = useTheme();

    const CustomButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        backgroundColor: 'transparent',
        background: isSelected ? 'linear-gradient(270deg, rgba(178, 42, 40, 0.00) 3.34%, rgba(178, 42, 40, 0.79) 52.24%, rgba(178, 42, 40, 0.00) 99.79%)' : 'none',
        color: isSelected ? '#FFFFFF' : '#ACB3D7',
        borderRadius:'0px',
        textAlign:'center',
        height:'100%',
        width:'100%',
        textDecoration:'none',
        fontsize:'18px',
        fontStyle:'normal',
        fontWeight:400,
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.down(MobileBreakpoint.match)]: {
            borderRadius:'8px',
            width:'calc((100% - 18px) / 2)',
        },
    });

    return (
        <CustomButton variant='contained' disableRipple onClick={onClick}>
            {title}
        </CustomButton>
    );
}

const LoginAndRegisterContentRightHeader = (props) => {
    const { login,signUp,loginOnClick,registerOnClick,currentMode } = props;
    const theme = useTheme();

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'429px',
            height:'30px',
            position:'absolute',
            top:'120px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                bgcolor:'#1D1D28',
                borderRadius:'8px',
                top:'60px',
                gap:'18px',
                margin:'0px 18px',
                width:'calc(100% - 36px)',
            },
        }}>
            <LoginAndRegisterContentRightHeaderButton 
                title={login}
                isSelected={currentMode === 'login' ? true : false}
                onClick={loginOnClick}
            />
            <LoginAndRegisterContentRightHeaderButton 
                title={signUp}
                isSelected={currentMode === 'login' ? false : true}
                onClick={registerOnClick}
            />
        </Box>
    );
}

export default LoginAndRegisterContentRightHeader;
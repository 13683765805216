import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import HomeContentMainInHouseGameItem from './HomeContentMainInHouseGameItem';
import HomeContentMainInHouseGameTitle from './HomeContentMainInHouseGameTitle';
import gameIcon1 from '../../assets/images/temp/game1.png';
import gameIcon2 from '../../assets/images/temp/game2.png';
import RoutePath from '../../tools/RoutePath';
import { useNavigate } from 'react-router-dom';

const HomeContentMainInHouseGame = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const textData = {
        inHouseGame: t("in_house_game"),
        more: t("more"),
        comingSoon: t("coming_soon"),
    };
    const itemArr = [
        // gameIcon1,
        gameIcon2,
        gameIcon1,
    ];
    const titleArr = [
        // 'Heads&Tails',
        'Plinko','',
    ];
    const gameUrlArr = [
        // 'https://www.ttbetgame.com/heads-and-tails/',
        'https://www.ttbetgame.com/plinko/',''
    ];

    const handleMoreClick = () => {
        navigate(RoutePath.inHouseGames);
    }
    
    return (
        <Grid container sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'start',
            mt:'31px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'18px',
                padding:'0px',
            },
        }}>
            <HomeContentMainInHouseGameTitle 
                inHouseGame={textData.inHouseGame}
                more={textData.more.toLowerCase()}
                onClick={handleMoreClick}
            />
            <Grid container sx={{
                display:'flex',
                justifyContent:'start',
                alignItems:'start',
                rowGap:'22px',
                columnGap:'10px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    rowGap:'15px',
                    columnGap:'15px',
                    mt:'20px',
                },
            }}>
                {itemArr.map((item,index) => (
                    <HomeContentMainInHouseGameItem 
                        image={item}
                        title={titleArr[index]}
                        gameUrl={gameUrlArr[index]}
                        playerNum={888}
                        isActive={index === (itemArr.length - 1) ? false : true}
                        comingSoon={textData.comingSoon}
                        inHouseGame={textData.inHouseGame}
                        key={index}
                    />
                ))}
            </Grid>
        </Grid>
    );
}

export default HomeContentMainInHouseGame;
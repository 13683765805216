import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LaunchLoading = () => {
    return (
        <Backdrop open={true} sx={{
            color:'#FFFFFF',
            zIndex: (theme) => theme.zIndex.modal + 1,
        }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default LaunchLoading;
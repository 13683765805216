import React, { useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const TTLoading = (props) => {
    const { open,isForce,closeClick } = props;

    const [ canOpen,setCanOpen ] = useState(open);

    const handleClose = () => {
        setCanOpen(false);
        closeClick && closeClick();
    };

    if(isForce) {
        return (
            <Backdrop open={open} sx={{
                color:'#FFFFFF',
                zIndex: (theme) => theme.zIndex.modal + 1,
            }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <Backdrop open={canOpen} onClick={handleClose} sx={{
            color:'#FFFFFF',
            zIndex: (theme) => theme.zIndex.modal + 1,
        }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default TTLoading;
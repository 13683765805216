import React from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import RoutePath from "../../tools/RoutePath";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import usdtIcon from '../../assets/images/header/header-usdt.png';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const WalletContentMainLeftBalance = (props) => {
    const { yourBalance,transaction,type } = props;
    const theme = useTheme();

    const navigate = useNavigate();
    const totalBalance = useSelector((state) => state.wallet.totalBalance);
    const balanceLeft = totalBalance.toString().split('.').length > 1 ? totalBalance.toString().split('.')[0] : totalBalance;
    const balanceRight = totalBalance.toString().split('.').length > 1 ? totalBalance.toString().split('.')[1] : '0000';
    const symbol = useSelector((state) => state.wallet.symbol);

    const handleTransactionClick = () => {
        console.log('category:'+type);
        navigate(RoutePath.record,{state: {token:'USDT',category:type}});
    };

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width:'100%',
            gap:'18px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                gap:'8px',
                padding:'0px 18px',
                width:'calc(100% - 36px)',
            },
        }}>
            <Typography sx={{
                color:'#6D728D',
                fontSize:'20px',
                fontStyle:'normal',
                fontWeight:400,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                },
            }}>
                {yourBalance}
            </Typography>
            <Grid container sx={{
                bgcolor:'#1D1D28',
                borderRadius:'18px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                width:'100%',
                padding:'16px 18px',
                gap:'16px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    borderRadius:'8px',
                    padding:'18px',
                },
            }}>
                <Box sx={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'flex-start',
                    gap:'6px',
                }}>
                    <CardMedia
                        component="img"
                        image={usdtIcon}
                        sx={{
                            width:'28px',
                            height:'28px',
                            objectFit:'contain',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'18px',
                                height:'18px',
                            },
                        }}
                    />
                    <Typography sx={{
                        color:'#FFD233',
                        fontSize:'24px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'15px',
                        },
                    }}>
                        {symbol}
                    </Typography>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'24px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'15px',
                        },
                    }}>
                        {`${balanceLeft}.`}
                        <span style={{color:'#6D728D'}}>{balanceRight}</span>
                    </Typography>
                </Box>
                <Button variant="text" sx={{
                    bgcolor:'#2A2A39',
                    color:'#ACB3D7',
                    fontSize:'18px',
                    fontStyle:'normal',
                    fontWeight:500,
                    textTransform:'none',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    height:'46px',
                    padding:'0px 18px',
                    borderRadius:'23px',
                    '&:hover': {
                        bgcolor:'#2A2A39',
                        color: '#FFFFFF',
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        display:'none',
                    },
                }} onClick={handleTransactionClick}>
                    {transaction}
                </Button>
            </Grid>
        </Box>
    );
}

export default WalletContentMainLeftBalance;
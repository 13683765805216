import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";


const TransitionLeft = (props) => {
    return <Slide {...props} direction="left" />;
}

const TTAlert = (props) => {
    const { type,message,isOpen,noAutoHide,mt,mb,closeCallback,closeBtnClick } = props;

    const handleActionClose = () => {
        closeBtnClick && closeBtnClick();
        closeCallback && closeCallback();
    };

    return (
        <React.Fragment>
            <Snackbar 
                open={isOpen} 
                autoHideDuration={(noAutoHide !== undefined && noAutoHide !== null && noAutoHide) ? null : 3000} 
                onClose={closeCallback}
                TransitionComponent={TransitionLeft}
                anchorOrigin={{ vertical:'top', horizontal:'right' }}
                key={'left'}
                sx={{
                    mt:mt,
                    mb:mb,
                }}
            >
                <Alert onClose={handleActionClose} severity={type} sx={{ width: 'auto', textTransform: 'none' }}>
                    {message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default TTAlert;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Footer from '../footer/Footer';
import GameContentMain from './GameContentMain';

const GameContent = (props) => {
    const {
        category,fromPage,gameIcon,gameName,gameUrl,gameUid,isCloseFreePlayMode,isFavorite,favoriteNum,
    } = props;
    const theme = useTheme();

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    return (
        <Grid sx={{
            pl:'260px',
            pt:'64px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                pl:'0px',
                pt:'114px',
            },
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between',
            }}>
                <GameContentMain 
                    category={category}
                    fromPage={fromPage}
                    gameIcon={gameIcon}
                    gameName={gameName}
                    gameUrl={gameUrl}
                    gameUid={gameUid}
                    isCloseFreePlayMode={isCloseFreePlayMode}
                    isFavorite={isFavorite}
                    favoriteNum={favoriteNum}
                />
                <Footer />
            </Box>
        </Grid>
    );
}

export default GameContent;
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import HomeContentMainInHouseGameTitle from './HomeContentMainInHouseGameTitle';
import HomeContentMainSlotsItem from './HomeContentMainSlotsItem';
import RoutePath from '../../tools/RoutePath';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { popularSlotsRefresh } from '../../features/homeSlice';
import TTGameItemSkeleton from '../common/TTGameItemSkeleton';
import popularIcon from '../../assets/images/home/slots-popular.png';
import { getCasinoPopularGamesService } from '../../network/service/game';

const HomeContentMainPopularSlots = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t,i18n } = useTranslation();
    const textData = {
        popularSlots: t("popular_slots"),
        more: t("more"),
        comingSoon: t("coming_soon"),
    };

    const slotsData = useSelector((state) => state.home.popularSlots);
    // 获取游戏列表
    const handleFetchSlots = async () => {
        try {
            const param = {
                game_type:"CasinoSlot",
                start:0,
                end:screenMD ? 4 : 7,
            };
            const response = await getCasinoPopularGamesService(param);
            dispatch(popularSlotsRefresh({popularSlots:response.data.data}));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        handleFetchSlots();
    }, [screenMD,i18n.language]);

    const handleMoreClick = () => {
        navigate(RoutePath.popularSlots, { state: { category:1,fromPage:RoutePath.popularSlots } } );
    }

    const handleImageUrl = (gameName) => {
        const name = gameName.replace(/ /g, '-');
        return name;
    }

    return (
        <Grid container sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'start',
            mt:'36px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'18px',
            },
        }}>
            <HomeContentMainInHouseGameTitle 
                inHouseGame={textData.popularSlots}
                iconImage={popularIcon}
                more={textData.more.toLowerCase()}
                onClick={handleMoreClick}
            />
            <Grid container sx={{
                display:'flex',
                justifyContent:'start',
                alignItems:'start',
                rowGap:'22px',
                columnGap:'10px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    rowGap:'13px',
                    columnGap:'13px',
                    mt:'20px',
                },
            }}>
                {!slotsData ?
                    (screenMD ? Array(4).fill('') : Array(6).fill('')).map((_,index) => (
                        <TTGameItemSkeleton 
                            itemWidth={screenMD ? 'calc((100vw - 86px) / 4)' : '148px'}
                            key={index}
                        />
                    )) : 
                    slotsData.map((item,index) => (
                        <HomeContentMainSlotsItem 
                            slots={textData.popularSlots}
                            image={item.game_image}
                            title={item.game_name}
                            gameUid={item.game_uid}
                            isCloseFreePlayMode={!item.has_demo_mode}
                            isFavorite={item.is_favorite}
                            favoriteNum={item.favorite_count}
                            comingSoon={textData.comingSoon}
                            key={index}
                        />
                    ))
                }
            </Grid>
        </Grid>
    );
}

export default HomeContentMainPopularSlots;
import React from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CardMedia from '@mui/material/CardMedia';
import privacyNormalIcon from '../../assets/images/login-and-register/login-and-register-privacy-normal.png';
import privacySelectedIcon from '../../assets/images/login-and-register/login-and-register-privacy-selected.png';
import { useNavigate } from "react-router-dom";
import RoutePath from "../../tools/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../features/userSlice";

const LoginAndRegisterContentPrivacyPolicy = (props) => {
    const { iamThe,privacyPolicy,and,termsOfService,isAgree,isShow,agreeOnClick, } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isForceLogin = useSelector((state) => state.user.forceLogin);

    const handlePrivacyPolicy = () => {
        console.log('privacy policy');
    };
    const handleTermsOfService = () => {
        if(isForceLogin) {
            dispatch(userLogout());
        }
        navigate(RoutePath.helpCenter,{state:{categoryIndex:3}});
    };

    return (
        <Box sx={{
            display:isShow ? 'flex' : 'none',
            justifyContent:'flex-start',
            alignItems:'center',
            gap:'0px',
            mt:'18px',
            width:'100%',
        }}>
            {/* <Button variant="image" disableRipple onClick={agreeOnClick} sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'36px',
                height:'26px',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}>
                <CardMedia
                    component='img'
                    image={isAgree ? privacySelectedIcon : privacyNormalIcon}
                    sx={{
                        width:'17px',
                        height:'17px',
                    }}
                />
            </Button> */}
            <IconButton onClick={agreeOnClick}>
                <CardMedia
                    component='img'
                    image={isAgree ? privacySelectedIcon : privacyNormalIcon}
                    sx={{
                        width:'17px',
                        height:'17px',
                    }}
                />
            </IconButton>
            <Typography sx={{
                color:'#6D728D',
                fontSize:'11px',
                fontStyle:'normal',
                fontWeight:400,
                mr:'20px',
            }}>
                {`${iamThe} `}<span style={{color:'#1CA45F',cursor:'pointer'}} onClick={handlePrivacyPolicy}>{privacyPolicy}</span>{` ${and} `}
                <span style={{color:'#1CA45F',cursor:'pointer'}} onClick={handleTermsOfService}>{termsOfService}</span>
            </Typography>
        </Box>
    );
}

export default LoginAndRegisterContentPrivacyPolicy;
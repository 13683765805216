import React, { useState } from 'react';
import RoutePath from '../../tools/RoutePath';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { menuClasses } from '@mui/material/Menu';
import { menuItemClasses } from '@mui/material/MenuItem';
import { popoverClasses } from '@mui/material/Popover';
import depositIcon from '../../assets/images/header/header-deposit.png';
import headerArrowRight from '../../assets/images/header/header-arrow-right.png';
import defaultAvatarIcon from '../../assets/images/header/header-default-avatar.png';
import accountIcon from '../../assets/images/header/header-account.png';
import transactionIcon from '../../assets/images/header/header-transaction.png';
import logoutIcon from '../../assets/images/header/header-logout.png';
import { useDispatch, useSelector } from "react-redux";
import { clearUserCookies } from '../../tools/CommonTool';
import TTAlert from '../common/TTAlert';
import TTLoading from '../common/TTLoading';
import { logoutService } from '../../network/service/user';
import { userLogout } from '../../features/userSlice';
import { walletLogout } from '../../features/walletSlice';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
      padding:'0px 0px 15px 0px',
      marginTop: 10,
      bgcolor:'#2A2A39',
      borderRadius: 18,
      color: '#FFFFFF',
      [`& .${menuClasses.list}`]: {
        padding: '0px',
      },
      [`& .${menuItemClasses.root}`]: {
        '&:active': {
          backgroundColor: alpha(
            '#000000',
            0.8,
          ),
        },
      },
    },
    [`& .${popoverClasses.paper}`]: {
        backgroundColor: '#2A2A39',
    }
}));

const HeaderLoggedIn = (props) => {
    const { deposit,account,transaction,logout,isHidden } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const menuTitleArr = [account,transaction,logout];
    const menuImgArr = [accountIcon,transactionIcon,logoutIcon];

    const [ alertType,setAlertType ] = useState('info');
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const [ openLoading,setOpenLoading ] = useState(false);

    const nickname = useSelector((state) => state.user.user.username);
    const profileImageUrl = useSelector((state) => state.user.user.profile_image_url);

    let avatarUrl = '';
    if(profileImageUrl !== undefined && profileImageUrl.length > 0) {
        if(profileImageUrl.startsWith('avatars://')) {
            avatarUrl = profileImageUrl.slice(10);
        }
    }

    const handleDepositClick = () => {
        navigate(RoutePath.wallet);
    };
    const handleUserClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleAvatarClick = () => {
        navigate(RoutePath.profile);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = async (index) => {
        handleClose();
        if(index === 0) {
            navigate(RoutePath.profile);
        }else if(index === 1) {
            navigate(RoutePath.record,{state: {token:'USDT',category:0}});
        }else if(index === 2) {
            setOpenLoading(true);
            try {
                const response = await logoutService();
                setOpenLoading(false);
                clearUserCookies();
                dispatch(userLogout(response.data));
                dispatch(walletLogout());
                navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.home,isLogout:true}});
            } catch (error) {
                setOpenLoading(false);
                
                const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
                let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
                setAlertType('error');
                setAlertMessage(errorMsg);
                setOpenAlert(true);
            }
        }
    }

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={alertType}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
                mt='70px'
            />

            <Box sx={{
                display:isHidden ? 'none' : 'flex',
                justifyContent:'end',
                alignItems:'center',
                gap:'20px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    gap:'6px',
                },
            }}>
                <Button variant="contained" sx={{
                    bgcolor:'#1CA45F',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'8px',
                    height:'28px',
                    borderRadius:'14px',
                    padding:'0px 16px',
                    fontSize:'14px',
                    fontWeight:400,
                    fontStyle:'normal',
                    color:'#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#1CA45F',
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                        padding:'0px 12px',
                    },
                }} onClick={handleDepositClick}>
                    {deposit}
                    <CardMedia
                        component="img"
                        image={depositIcon}
                        sx={{
                            display:screenMD ? 'none' : 'block',
                            width:'20px',
                            height:'20px',
                            objectFit:'contain',
                        }}
                    />
                </Button>
                <Button variant="contained" sx={{
                    bgcolor:'#3A3E53',
                    display:screenMD ? 'none' : 'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'8px',
                    textTransform: 'none',
                    height:'28px',
                    borderRadius:'14px',
                    padding:'0px 16px',
                    fontSize:'14px',
                    fontWeight:400,
                    fontStyle:'normal',
                    color:'#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#3A3E53',
                    },
                }} onClick={handleUserClick}>
                    <CardMedia
                        component="img"
                        image={avatarUrl.length > 0 ? require(`../../assets/images/profile/avatar/${avatarUrl}.png`) : defaultAvatarIcon}
                        sx={{
                            width:'18px',
                            height:'18px',
                            objectFit:'contain',
                        }}
                    />
                    {nickname}
                    <Box sx={{
                        filter:'brightness(0) invert(1)',
                    }}>
                        <CardMedia
                            component="img"
                            image={headerArrowRight}
                            sx={{
                                width:'5px',
                                height:'12px',
                                objectFit:'contain',
                            }}
                        />
                    </Box>
                </Button>
                <IconButton onClick={handleAvatarClick} sx={{
                    display:screenMD ? 'block' : 'none',
                }}>
                    <CardMedia
                        component="img"
                        image={avatarUrl.length > 0 ? require(`../../assets/images/profile/avatar/${avatarUrl}.png`) : defaultAvatarIcon}
                        sx={{
                            width:'28px',
                            height:'28px',
                            objectFit:'contain',
                        }}
                    />
                </IconButton>
            </Box>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuTitleArr.map((item,index) => (
                    <MenuItem onClick={() => handleMenuClick(index)} disableRipple key={index} sx={{
                        display:'flex',
                        justifyContent:'start',
                        alignItems:'center',
                        gap:'8px',
                        mt:'15px'
                    }}>
                        <CardMedia
                            component="img"
                            image={menuImgArr[index]}
                            sx={{
                                width:'24px',
                                height:'24px',
                                objectFit:'contain',
                            }}
                        />
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'12px',
                            fontStyle:'normal',
                            fontWeight:400
                        }}>
                            {item}
                        </Typography>
                    </MenuItem>
                ))}
            </StyledMenu>
        </React.Fragment>
    );
}

export default HeaderLoggedIn;
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import iphoneIcon from '../../assets/images/poker/poker-apple.webp';
import androidIcon from '../../assets/images/poker/poker-android.webp';
import pokerIcon from '../../assets/images/poker/poker-poker.webp';
import mobilePokerIcon from '../../assets/images/poker/poker-mobile-poker.webp';
import tfDownloadInstructionsImage from '../../assets/images/poker/poker-tf-download-instructions.jpg';
import superDownloadInstructionsImage from '../../assets/images/poker/poker-super-signature-instructions.jpg';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import { useDispatch, useSelector } from 'react-redux';
import { pokerDownloadLinkService } from '../../network/service/home';
import { pokerDownloadLinkRefresh } from '../../features/appSlice';
import TTLoading from '../common/TTLoading';

const DownloadButton = (props) => {
    const { btnBgColor, btnIconImage, btnText, btnClick } = props;
    const theme = useTheme();

    const CustomButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        backgroundColor: btnBgColor,
        color: '#FFFFFF',
        borderRadius:'3px',
        display:'flex',
        alignItems:'center',
        height:'65px',
        minWidth:'210px',
        justifyContent:'center',
        padding:'0px 10px',
        gap:'10px',
        textDecoration:'none',
        '&:hover': {
          backgroundColor: btnBgColor,
          opacity:0.8,
        },
        [theme.breakpoints.down(MobileBreakpoint.match)]: {
            height:'48px',
            minWidth:'0px',
            width:'calc((100vw - 54px) / 2)',
            padding:'0px 10px',
            gap:'8px',
        },
    });

    return (
        <CustomButton variant='contained' onClick={btnClick}>
            <CardMedia
                component="img"
                image={btnIconImage}
                sx={{
                    width:'22px',
                    height:'24px',
                    objectFit:'contain',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'18px',
                        height:'19px',
                    },
                }}
            />
            <Typography sx={{
                fontSize:'14px',
                fontWeight:500,
                textDecoration:'none',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'11px',
                },
            }}>
                {btnText}
            </Typography>
        </CustomButton>
    );
}

const PokerContentPoker = (props) => {
    const {
        welcomeToOf,ttPoker,weBringDedication,iphoneDownload,
        androidDownload,downloadTheMode,alternateDownloadLink,
        tfDownloadInstructions,superSignatureInstructions
    } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const [ openLoading,setOpenLoading ] = useState(false);

    const pokerLinkConfig = useSelector((state) => state.app.download_app_links_config);

    const handleIOSDownloadClick = () => {
        if(pokerLinkConfig.ios_main_link.length > 0) {
            handleDownloadWithURL(pokerLinkConfig.ios_main_link,false);
        }else {
            setOpenLoading(true);
            handleFetchDownloadLink((linkConfig) => {
                setOpenLoading(false);
                
                handleDownloadWithURL(linkConfig.ios_main_link,false);
            });
        }
    };
    const handleAndroidDownloadClick = () => {
        if(pokerLinkConfig.android_link.length > 0) {
            handleDownloadWithURL(pokerLinkConfig.android_link,true);
        }else {
            setOpenLoading(true);
            handleFetchDownloadLink((linkConfig) => {
                setOpenLoading(false);
                
                handleDownloadWithURL(linkConfig.android_link,true);
            });
        }
    };
    const handleAlternateDownloadClick = () => {
        if(pokerLinkConfig.ios_backup_link.length > 0) {
            handleDownloadWithURL(pokerLinkConfig.ios_backup_link,false);
        }else {
            setOpenLoading(true);
            handleFetchDownloadLink((linkConfig) => {
                setOpenLoading(false);
                
                handleDownloadWithURL(linkConfig.ios_backup_link,false);
            });
        }
    };
    const handleDownloadWithURL = (url,isAndroid) => {
        const link = document.createElement('a');
        link.href = url;
        if(!isAndroid) {
            link.target = '_blank';
        }
        link.click();
    };
    
    const handleFetchDownloadLink = async (callBack) => {
        try {
            const response = await pokerDownloadLinkService();
            dispatch(pokerDownloadLinkRefresh(response.data));
            callBack && callBack(response.data.download_app_links_config);
        } catch (error) {
            setOpenLoading(false);
            console.log('handleFetchDownloadLink error:' + error);
        }
    };

    const [ isTF, setIsTF ] = useState(true);
    const handleTFDownloadInstructionsClick = () => {
        setIsTF(true);
        setOpenShowLarge(true);
    };
    const handleSuperSignatureClick = () => {
        setIsTF(false);
        setOpenShowLarge(true);
    };
    const [ openShowLarge, setOpenShowLarge ] = useState(false);
    const handleCloseShowLargeClick = () => {
        setOpenShowLarge(false);
    };

    useEffect(()=> {
        handleFetchDownloadLink();
    },[]);

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />

            <Dialog
                open={openShowLarge}
                onClose={handleCloseShowLargeClick}
                sx={{
                    [`& .${dialogClasses.paper}`]: {
                        bgcolor:'transparent',
                        borderRadius:'18px',
                        maxWidth:'calc(100% - 36px)',
                        maxHeight:'calc(100% - 36px)',
                        margin:'0px',
                        padding:'0px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'8px',
                            maxWidth:'calc(100% - 28px)',
                            maxHeight:'calc(100% - 28px)',
                            width:'calc(100% - 28px)',
                        },
                    },
                }}
            >
                <DialogContent sx={{
                    bgcolor:'transparent',
                    borderRadius:'18px',
                    // minWidth:'500px',
                    maxWidth:'100%',
                    m:'0px',
                    p:'0px',
                    overflow:'auto',
                    scrollbarWidth: 'none', // Firefox
                    msOverflowStyle: 'none', // Internet Explorer
                    '&::-webkit-scrollbar': {
                        display: 'none', // Chrome, Safari, Edge
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                        borderRadius:'8px',
                    },
                    transition: theme.transitions.create(['all'], {
                        duration: 300,
                        easing: 'ease-out',
                    }),
                }}>
                    <Box sx={{
                        bgcolor:'#1D1D28',
                        borderRadius:'18px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'36px',
                        padding:'28px',
                        width:'calc(100% - 56px)',
                        maxWidth:'584px',
                        overflow:'auto',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'8px',
                            maxWidth:'calc(100% - 36px)',
                            padding:'18px',
                            gap:'18px',
                        },
                    }}>
                        <Grid container sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            width:'100%',
                        }}>
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'24px',
                                fontWeight:400,
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'15px',
                                },
                            }}>
                                {isTF ? tfDownloadInstructions : superSignatureInstructions}
                            </Typography>
                            <IconButton sx={{
                                mt:'-8px',
                                mr:'-8px',
                            }} onClick={handleCloseShowLargeClick}>
                                <CardMedia
                                    component='img'
                                    image={closeIcon}
                                    sx={{
                                        objectFit:'contain',
                                        width:'32px',
                                        height:'32px',
                                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                            width:'18px',
                                            height:'18px',
                                        },
                                        '&:hover': {
                                            filter: 'brightness(0) invert(1)',
                                        },
                                    }}
                                />
                            </IconButton>
                        </Grid>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            overflow:'scroll',
                            width:'100%',
                            height:'500px',
                        }}>
                            <CardMedia
                                component='img'
                                image={isTF ? tfDownloadInstructionsImage : superDownloadInstructionsImage}
                                sx={{
                                    objectFit:'contain',
                                    width:isTF ? '1462px' : '2267px',
                                }}
                            />
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                gap:'32px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    gap:'18px',
                },
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'465px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                    },
                }}>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'40px',
                        fontStyle:'normal',
                        fontWeight:700,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'29px',
                        },
                    }}>
                        {welcomeToOf}
                    </Typography>
                    <Typography sx={{
                        color:'#B22A28',
                        fontSize:'64px',
                        fontStyle:'normal',
                        fontWeight:700,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'47px',
                        },
                    }}>
                        {ttPoker}
                    </Typography>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'17px',
                        fontStyle:'normal',
                        fontWeight:500,
                        mt:'18px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'12px',
                            mt:'13px',
                        },
                    }}>
                        {weBringDedication}
                    </Typography>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        gap:'16px',
                        mt:'22px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'18px',
                        },
                    }}>
                        <DownloadButton 
                            btnBgColor='#B22A28'
                            btnIconImage={iphoneIcon}
                            btnText={iphoneDownload}
                            btnClick={handleIOSDownloadClick}
                        />
                        <DownloadButton 
                            btnBgColor='#1CA45F'
                            btnIconImage={androidIcon}
                            btnText={androidDownload}
                            btnClick={handleAndroidDownloadClick}
                        />
                    </Grid>
                    <Button sx={{
                        textTransform:'none',
                        bgcolor:'#2A2A39',
                        borderRadius:'3px',
                        width:'100%',
                        height:'52px',
                        mt:'18px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'8px',
                        color:'#FFFFFF',
                        fontSize:'11px',
                        fontWeight:500,
                        '&:hover':{
                            bgcolor:'#2A2A39',
                            opacity:0.8,
                        }
                    }} onClick={handleTFDownloadInstructionsClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                        <path d="M15.8876 0.660242C15.6768 0.449493 15.3921 0.331787 15.0939 0.331787H1.90637C1.28645 0.331787 0.784241 0.833998 0.784241 1.45391V16.3241C0.784241 16.8599 1.00284 17.3722 1.39071 17.7421C1.74382 18.1154 2.23482 18.3284 2.74824 18.3318H16.216V1.45391C16.216 1.15685 16.0972 0.870991 15.8876 0.660242ZM10.6043 4.86065H13.6904C14.1444 4.86065 14.511 5.22834 14.5121 5.68235C14.5121 6.13636 14.1444 6.50293 13.6904 6.50405H10.6043C10.1502 6.50405 9.78256 6.13636 9.78256 5.68235C9.78256 5.22834 10.1502 4.86065 10.6043 4.86065ZM9.78256 9.3301C9.78256 8.8761 10.1502 8.50841 10.6043 8.50841H13.6904C14.1444 8.50841 14.511 8.8761 14.5121 9.3301C14.5121 9.78411 14.1444 10.1507 13.6904 10.1518H10.6043C10.1502 10.1518 9.78256 9.78411 9.78256 9.3301ZM2.6978 1.84515C2.90294 1.64561 3.19104 1.53126 3.48026 1.53126C3.76948 1.53126 4.05758 1.64897 4.26272 1.84515C4.46787 2.04468 4.58669 2.32269 4.58669 2.60407C4.58669 2.88544 4.46787 3.16345 4.26272 3.36299C4.05758 3.56253 3.76948 3.67687 3.48026 3.67687C3.19104 3.67687 2.90294 3.56141 2.6978 3.36299C2.49265 3.16345 2.37383 2.88544 2.37383 2.60407C2.37383 2.32269 2.49265 2.04468 2.6978 1.84515ZM4.58669 5.25076V9.78187C4.58669 10.302 4.11475 10.7224 3.53071 10.7224C2.94666 10.7224 2.47472 10.302 2.47472 9.78187V5.25076C2.47472 4.73062 2.94666 4.31024 3.53071 4.31024C4.11475 4.31024 4.58669 4.73062 4.58669 5.25076ZM15.0939 17.1189H2.74824C2.26509 17.0987 1.88843 16.6918 1.90637 16.2086V14.6908C1.88843 14.2076 2.26509 13.8007 2.74824 13.7805H15.0939V17.1189Z" fill="white"/>
                        </svg>
                        {tfDownloadInstructions}
                    </Button>
                    <Button sx={{
                        textTransform:'none',
                        bgcolor:'#6D728D',
                        borderRadius:'3px',
                        width:'100%',
                        height:'52px',
                        mt:'18px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'8px',
                        color:'#FFFFFF',
                        fontSize:'11px',
                        fontWeight:500,
                        '&:hover':{
                            bgcolor:'#6D728D',
                            opacity:0.8,
                        }
                    }} onClick={handleAlternateDownloadClick}>
                        <CardMedia
                            component="img"
                            image={iphoneIcon}
                            sx={{
                                width:'16px',
                                height:'20px',
                                objectFit:'contain',
                            }}
                        />
                        {alternateDownloadLink}
                    </Button>
                    <Button sx={{
                        textTransform:'none',
                        bgcolor:'#2A2A39',
                        borderRadius:'3px',
                        width:'100%',
                        height:'52px',
                        mt:'18px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'8px',
                        color:'#FFFFFF',
                        fontSize:'11px',
                        fontWeight:500,
                        '&:hover':{
                            bgcolor:'#2A2A39',
                            opacity:0.8,
                        }
                    }} onClick={handleSuperSignatureClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                        <path d="M15.8876 0.660242C15.6768 0.449493 15.3921 0.331787 15.0939 0.331787H1.90637C1.28645 0.331787 0.784241 0.833998 0.784241 1.45391V16.3241C0.784241 16.8599 1.00284 17.3722 1.39071 17.7421C1.74382 18.1154 2.23482 18.3284 2.74824 18.3318H16.216V1.45391C16.216 1.15685 16.0972 0.870991 15.8876 0.660242ZM10.6043 4.86065H13.6904C14.1444 4.86065 14.511 5.22834 14.5121 5.68235C14.5121 6.13636 14.1444 6.50293 13.6904 6.50405H10.6043C10.1502 6.50405 9.78256 6.13636 9.78256 5.68235C9.78256 5.22834 10.1502 4.86065 10.6043 4.86065ZM9.78256 9.3301C9.78256 8.8761 10.1502 8.50841 10.6043 8.50841H13.6904C14.1444 8.50841 14.511 8.8761 14.5121 9.3301C14.5121 9.78411 14.1444 10.1507 13.6904 10.1518H10.6043C10.1502 10.1518 9.78256 9.78411 9.78256 9.3301ZM2.6978 1.84515C2.90294 1.64561 3.19104 1.53126 3.48026 1.53126C3.76948 1.53126 4.05758 1.64897 4.26272 1.84515C4.46787 2.04468 4.58669 2.32269 4.58669 2.60407C4.58669 2.88544 4.46787 3.16345 4.26272 3.36299C4.05758 3.56253 3.76948 3.67687 3.48026 3.67687C3.19104 3.67687 2.90294 3.56141 2.6978 3.36299C2.49265 3.16345 2.37383 2.88544 2.37383 2.60407C2.37383 2.32269 2.49265 2.04468 2.6978 1.84515ZM4.58669 5.25076V9.78187C4.58669 10.302 4.11475 10.7224 3.53071 10.7224C2.94666 10.7224 2.47472 10.302 2.47472 9.78187V5.25076C2.47472 4.73062 2.94666 4.31024 3.53071 4.31024C4.11475 4.31024 4.58669 4.73062 4.58669 5.25076ZM15.0939 17.1189H2.74824C2.26509 17.0987 1.88843 16.6918 1.90637 16.2086V14.6908C1.88843 14.2076 2.26509 13.8007 2.74824 13.7805H15.0939V17.1189Z" fill="white"/>
                        </svg>
                        {superSignatureInstructions}
                    </Button>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'14px',
                        fontStyle:'normal',
                        fontWeight:700,
                        mt:'22px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'10px',
                        },
                    }}>
                        {downloadTheMode}
                    </Typography>
                </Box>
                <CardMedia
                    component="img"
                    image={screenMD ? mobilePokerIcon : pokerIcon}
                    sx={{
                        width:'500px',
                        height:'306px',
                        objectFit:'cover',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'100%',
                            height:'calc((100vw - 36px) * 225 / 335)',
                            borderRadius:'13px',
                        },
                    }}
                />
            </Grid>
        </React.Fragment>
    );
}

export default PokerContentPoker;
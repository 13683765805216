import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ReferralContentMainPlaceholderSettlement = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        dailyAutomaticSettlement: t("daily_automatic_settlement"),
        weMakeAction: t("we_make__action"),
    };

    const arr = [
        textData.weMakeAction,
    ];

    return (
        <Box sx={{
            bgcolor:'#1D1D28',
            borderRadius:'18px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            alignSelf:'stretch',
            gap:'18px',
            padding:'28px 18px',
            width:'calc(100 - 36px)',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                borderRadius:'0px',
            },
        }}>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'18px',
                fontStyle:'normal',
                fontWeight:500,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                },
            }}>
                {textData.dailyAutomaticSettlement}
            </Typography>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'20px',
            }}>
                {arr.map((item,index) => (
                    <Typography sx={{
                        color:'#6D728D',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                    }}>
                        {item}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
}

export default ReferralContentMainPlaceholderSettlement;

import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const TTGameItemSkeleton = (props) => {
    const {
        itemWidth,
    } = props;
    const theme = useTheme();

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            width:itemWidth,
            gap:'18px',
        }}>
            <Skeleton 
                variant="rounded" 
                animation="wave"
                sx={{
                    bgcolor:'#2A2A39',
                    width:'100%',
                    height:itemWidth,
                    borderRadius:'24px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'16px',
                    },
            }} />
            <Skeleton 
                variant="rounded" 
                animation="wave"
                sx={{
                    bgcolor:'#2A2A39',
                    width:'100%',
                    height:'20px',
            }} />
        </Box>
    );
}

export default TTGameItemSkeleton;
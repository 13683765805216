import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';

const HomeContentMainInHouseGameTitle = (props) => {
    const { inHouseGame,iconImage,more,onClick } = props;
    const theme = useTheme();

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
            }}>
                {(iconImage !== undefined && iconImage !== null) ? 
                    <CardMedia
                        component="img"
                        image={iconImage}
                        sx={{
                            width:'32px',
                            height:'32px',
                            objectFit:'contain',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'24px',
                                height:'24px',
                            },
                        }}
                    /> : ''
                }
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'28px',
                    fontStyle:'normal',
                    fontWeight:500,
                    ml:(iconImage !== undefined && iconImage !== null) ? '8px' : '0px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'20px',
                    },
                }}>
                    {inHouseGame}
                </Typography>
            </Box>
            <Button variant='text' disableRipple sx={{
                color:'#6D728D',
                fontSize:'28px',
                fontStyle:'normal',
                fontWeight:500,
                textTransform:'none',
                height:'63px',
                textAlign:'right',
                '&:hover': {
                    background: 'transparent',
                    color:'#ACB3D7',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                    height:'28px',
                },
            }} onClick={onClick}>
                {more+'>>'}
            </Button>
        </Grid>
    );
}

export default HomeContentMainInHouseGameTitle;

const themeComponent = (mode) => {
    return {
        MuiButton: {
          styleOverrides: {
            root: {
              '&:hover': {
                backgroundColor: '#B22A28', // 替换为你想要的 hover 颜色
              },
              // fontFamily: 'Gilroy',
              fontFamily: [
                'Gilroy',
                'Ubuntu-Regular',
                '"Helvetica Neue"',
                'Condensed',
                'DisplayRegular',
                'Helvetica',
                'Arial',
                '"PingFang SC"',
                '"Hiragino Sans GB"',
                '"WenQuanYi Micro Hei"',
                '"Microsoft Yahei"',
                'sans-serif',
              ].join(','),
            },
          },
        },
    };
  };
  
  export default themeComponent;
import React from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import GameContent from '../component/game/GameContent';
import { useLocation } from 'react-router-dom';

const Game = () => {
    const location = useLocation();
    let category = location.state && location.state.category;
    let fromPage = location.state && location.state.fromPage;
    let gameIcon = location.state && location.state.gameIcon;
    let gameName = location.state && location.state.gameName;
    let gameUrl = location.state && location.state.gameUrl;
    let gameUid = location.state && location.state.gameUid;
    let isCloseFreePlayMode = location.state && location.state.isCloseFreePlayMode;
    let isFavorite = location.state && location.state.isFavorite;
    let favoriteNum = location.state && location.state.favoriteNum;

    console.log('fromPage:'+fromPage);

    if((gameUrl === undefined || gameUrl === null || gameUrl.length <= 0) && 
    !(gameUid !== undefined && gameUid !== null && gameUid.length > 0)) {
        const jsonString = localStorage.getItem('TTLatestGame');
        if(jsonString !== undefined && jsonString !== null && jsonString.length > 0) {
            try {
                const parsedObject = JSON.parse(jsonString);
                if(parsedObject !== undefined && parsedObject !== null &&
                    parsedObject.gameUrl !== undefined && parsedObject.gameUrl !== null && 
                    parsedObject.gameUrl.length > 0) {
                    category = parsedObject.category;
                    fromPage = parsedObject.fromPage;
                    gameIcon = parsedObject.gameIcon;
                    gameName = parsedObject.gameName;
                    gameUrl = parsedObject.gameUrl;
                    isCloseFreePlayMode = parsedObject.isCloseFreePlayMode;
                    isFavorite = parsedObject.isFavorite;
                    favoriteNum = parsedObject.favoriteNum;
                    if(gameUid !== undefined && gameUid !== null && gameUid.length > 0) {
                        gameUid = parsedObject.gameUid;
                    }
                }
            } catch (error) {
                console.log('Invalid JSON string:', error);
            }
        }
    }else {
        let cache = {
            category:category,
            fromPage:fromPage,
            gameIcon:gameIcon,
            gameName:gameName,
            gameUrl:gameUrl,
            gameUid:gameUid,
            isCloseFreePlayMode:isCloseFreePlayMode,
            isFavorite:isFavorite,
            favoriteNum:favoriteNum,
        };
        const cacheStr = JSON.stringify(cache);
        localStorage.setItem('TTLatestGame',cacheStr);
    }
    
    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.home} drawerIndex={-1} />
            <GameContent 
                category={category}
                fromPage={fromPage}
                gameIcon={gameIcon}
                gameName={gameName}
                gameUrl={gameUrl}
                gameUid={gameUid}
                isCloseFreePlayMode={isCloseFreePlayMode}
                isFavorite={isFavorite}
                favoriteNum={favoriteNum}
            />
        </React.Fragment>
    )
}

export default Game;
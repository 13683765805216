import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import footerToken1 from '../../assets/images/footer/footer-token-1.png';
import footerToken2 from '../../assets/images/footer/footer-token-2.png';
import footerToken3 from '../../assets/images/footer/footer-token-3.png';
import footerToken4 from '../../assets/images/footer/footer-token-4.png';
import footerToken5 from '../../assets/images/footer/footer-token-5.png';
import footerToken6 from '../../assets/images/footer/footer-token-6.png';
import footerToken7 from '../../assets/images/footer/footer-token-7.png';
import footerToken8 from '../../assets/images/footer/footer-token-8.png';
import footerToken9 from '../../assets/images/footer/footer-token-9.png';
import footerToken10 from '../../assets/images/footer/footer-token-10.png';
import footerToken11 from '../../assets/images/footer/footer-token-11.png';
import footerToken12 from '../../assets/images/footer/footer-token-12.png';
import footerToken13 from '../../assets/images/footer/footer-token-13.png';
import footerToken14 from '../../assets/images/footer/footer-token-14.png';
import footerToken15 from '../../assets/images/footer/footer-token-15.png';
import footerToken16 from '../../assets/images/footer/footer-token-16.png';
import footerToken17 from '../../assets/images/footer/footer-token-17.png';
import footerToken18 from '../../assets/images/footer/footer-token-18.png';
import footerToken19 from '../../assets/images/footer/footer-token-19.png';
import footerToken20 from '../../assets/images/footer/footer-token-20.png';
import footerToken21 from '../../assets/images/footer/footer-token-21.png';
import footerToken22 from '../../assets/images/footer/footer-token-22.png';
import footerToken23 from '../../assets/images/footer/footer-token-23.png';
import footerToken24 from '../../assets/images/footer/footer-token-24.png';

const FooterToken = () => {
    const tokenArr = [
        footerToken1,footerToken2,footerToken3,footerToken4,footerToken5,footerToken6,
        footerToken7,footerToken8,footerToken9,footerToken10,footerToken11,footerToken12,
        footerToken13,footerToken14,footerToken15,footerToken16,footerToken17,footerToken18,
        footerToken19,footerToken20,footerToken21,footerToken22,footerToken23,footerToken24
    ];
    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            width:'100%',
            gap:'12px',
        }}>
            {tokenArr.map((item,index) => (
                <Box sx={{
                    width:'24px',
                    height:'24px',
                }} key={index}>
                    <CardMedia
                        component="img"
                        image={ item }
                    />
                </Box>
            ))}
        </Grid>
    );
}

export default FooterToken;
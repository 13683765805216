import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import TTLoadImage from './TTLoadImage';

const TTTosPopup = (props) => {
    const { 
        isOpen,
        closeCallback,
        title,
        content,
        imageUrl,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        announcement: t("announcement"),
    };
    
    return (
        <Dialog
            open={isOpen}
            // onClose={handleCloseClick}
            sx={{
                [`& .${dialogClasses.paper}`]: {
                    bgcolor:'transparent',
                    borderRadius:'18px',
                    maxWidth:'calc(100% - 36px)',
                    maxHeight:'calc(100% - 36px)',
                    margin:'0px',
                    padding:'0px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'8px',
                        maxWidth:'calc(100% - 28px)',
                        maxHeight:'calc(100% - 28px)',
                        width:'calc(100% - 28px)',
                    },
                },
            }}
        >
            <DialogContent sx={{
                bgcolor:'transparent',
                borderRadius:'18px',
                // minWidth:'500px',
                maxWidth:'100%',
                m:'0px',
                p:'0px',
                overflow:'auto',
                scrollbarWidth: 'none', // Firefox
                msOverflowStyle: 'none', // Internet Explorer
                '&::-webkit-scrollbar': {
                    display: 'none', // Chrome, Safari, Edge
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'100%',
                    borderRadius:'8px',
                },
                transition: theme.transitions.create(['all'], {
                    duration: 300,
                    easing: 'ease-out',
                }),
            }}>
                <Box sx={{
                    bgcolor:'#1D1D28',
                    borderRadius:'18px',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'36px',
                    padding:'28px',
                    width:'calc(100% - 56px)',
                    maxWidth:'584px',
                    overflow:'auto',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:'8px',
                        maxWidth:'calc(100% - 36px)',
                        padding:'18px',
                        gap:'18px',
                    },
                }}>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width:'100%',
                    }}>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'24px',
                            fontWeight:400,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'15px',
                            },
                        }}>
                            {textData.announcement}
                        </Typography>
                        <IconButton sx={{
                            mt:'-8px',
                            mr:'-8px',
                        }} onClick={closeCallback}>
                            <CardMedia
                                component='img'
                                image={closeIcon}
                                sx={{
                                    objectFit:'contain',
                                    width:'32px',
                                    height:'32px',
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        width:'18px',
                                        height:'18px',
                                    },
                                    '&:hover': {
                                        filter: 'brightness(0) invert(1)',
                                    },
                                }}
                            />
                        </IconButton>
                    </Grid>
                    {(title !== undefined && title !== null && title.length > 0) ? 
                        <Box sx={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'flex-start',
                            gap:'8px',
                        }}>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                width:'29px',
                                height:'32px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'15px',
                                    height:'15px',
                                },
                            }}>
                                 {screenMD ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <path d="M7.83154 9.12406C7.25905 9.12406 6.9728 9.53975 6.9728 10.1612C6.9728 10.854 7.25905 11.1983 7.83154 11.1983C8.40404 11.1983 8.69029 10.854 8.69029 10.1612C8.6209 9.53975 8.40404 9.19124 7.83154 9.12406Z" fill="#C25156"/>
                                    <path d="M10.8762 2.74184C11.2318 3.57321 11.3056 4.79507 10.5899 5.48368C9.40157 0.96993 6.35259 0 6.35259 0C6.70823 2.33035 5.06447 4.86645 3.44673 6.80211C3.37734 5.83218 3.30361 5.21075 2.80485 4.3332C2.66172 6.03792 1.37794 7.42354 0.948567 9.06108C0.662319 10.4887 0.879173 11.8029 1.95044 12.9828C3.6419 14.5741 8.0484 16.556 12.5243 13.05C16.6228 8.9939 11.8824 3.43465 10.8805 2.74184H10.8762ZM4.99508 11.8029V10.4887H3.58986V11.8029H2.80485V8.57402H3.58986V9.88825H5.06447V8.57402H5.84949V11.8029H4.99508ZM7.82721 11.8701C6.75594 11.8029 6.18345 11.2487 6.13574 10.2326C6.20513 9.14926 6.77763 8.59501 7.82721 8.52783C8.89847 8.59501 9.39724 9.14926 9.47097 10.2326C9.47097 11.2487 8.89847 11.7987 7.82721 11.8701ZM11.6612 9.19544V11.8701H10.8762V9.19544H9.80492V8.57402H12.7108V9.19544H11.6612Z" fill="#C25156"/>
                                    </svg> :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="32" viewBox="0 0 29 32" fill="none">
                                        <path d="M15.207 19.4647C13.9857 19.4647 13.375 20.3515 13.375 21.6772C13.375 23.1552 13.9857 23.8897 15.207 23.8897C16.4283 23.8897 17.039 23.1552 17.039 21.6772C16.891 20.3515 16.4283 19.608 15.207 19.4647Z" fill="#C25156"/>
                                        <path d="M21.7022 5.84925C22.4609 7.62284 22.6182 10.2295 21.0916 11.6985C18.5564 2.06918 12.0519 0 12.0519 0C12.8106 4.97142 9.30391 10.3818 5.85274 14.5112C5.7047 12.442 5.54741 11.1163 4.48338 9.24415C4.17804 12.8809 1.43931 15.8369 0.523317 19.3303C-0.0873465 22.3759 0.375275 25.1796 2.66064 27.6966C6.2691 31.0915 15.6696 35.3195 25.2182 27.8399C33.9618 19.187 23.8488 7.32724 21.7115 5.84925H21.7022ZM9.15587 25.1796V22.3759H6.15807V25.1796H4.48338V18.2912H6.15807V21.0949H9.30392V18.2912H10.9786V25.1796H9.15587ZM15.1977 25.3229C12.9124 25.1796 11.6911 23.9972 11.5893 21.8295C11.7373 19.5184 12.9586 18.336 15.1977 18.1927C17.4831 18.336 18.5471 19.5184 18.7044 21.8295C18.7044 23.9972 17.4831 25.1706 15.1977 25.3229ZM23.3769 19.6169V25.3229H21.7022V19.6169H19.4169V18.2912H25.616V19.6169H23.3769Z" fill="#C25156"/>
                                    </svg>
                                }
                            </Box>
                            
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'24px',
                                fontWeight:400,
                                mt:'-5px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'15px',
                                },
                            }}>
                                {title}
                            </Typography>
                        </Box> : ''
                    }
                    {(imageUrl !== undefined && imageUrl !== null && imageUrl.length > 0) ? 
                        <TTLoadImage 
                            image={imageUrl}
                            objectFit={'contain'}
                            width={'100%'}
                            height={'auto'}
                            borderRadius={'18px'}
                            borderRadiusMobile={'8px'}
                        /> : ''
                    }
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        width:'100%',
                        color:'#ACB3D7',
                        fontSize:'20px',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'12px',
                        },
                    }} dangerouslySetInnerHTML={{ __html: content }}>

                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default TTTosPopup;
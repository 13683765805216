import React, { useEffect } from 'react';
import WithdrawContent from '../component/withdraw/WithdrawContent';
import TTAppBar from '../component/common/TTAppBar';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { switchAppCategory } from '../features/appSlice';

const Withdraw = () => {
    const { t } = useTranslation();
    const textData = {
        withdraw: t("withdraw"),
    }

    const location = useLocation();
    const network = location.state && location.state.network;
    const networkType = location.state && location.state.networkType;
    const token = location.state && location.state.token;
    const address = location.state && location.state.address;
    const amount = location.state && location.state.amount;
    const gas = location.state && location.state.gas;
    const balance = location.state && location.state.balance;
    const fundingBalance = location.state && location.state.fundingBalance;
    const language = location.state && location.state.language;

    const dispatch = useDispatch();
    const appCategory = useSelector((state) => state.app.appCategory);
    useEffect(() => {
        if(appCategory !== 'casino') {
            dispatch(switchAppCategory({appCategory:'casino'}));
        }
    }, []);

    return (
        <React.Fragment>
            <TTAppBar
                style={0} 
                title={textData.withdraw}
            />
            <WithdrawContent 
                network={network}
                networkType={networkType}
                token={token}
                address={address}
                amount={amount}
                gas={gas}
                balance={balance}
                fundingBalance={fundingBalance}
                language={language}
            />
        </React.Fragment>
    )
}

export default Withdraw;
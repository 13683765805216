import api from "../api";
import httpManager from "../httpManager";

// 获取在线人数
export const getOnlineUserNumberService = () => {
    return httpManager({
        url:api.serverStatusOnlineUserNumber,
        method:'get',
        data:{},
    });
}

// 排行榜
export const getBiggestTodayService = () => {
    return httpManager({
        url:api.serverStatusBiggestToday,
        method:'get',
        data:{},
    });
}
// 排行榜
export const getLatestRoundService= () => {
    return httpManager({
        url:api.serverStatusLatestRound,
        method:'get',
        data:{},
    });
}
// 获取客服链接
export const getCustomerLinkService = () => {
    return httpManager({
        url:api.tosCustomerServiceLink,
        method:'get',
        data:{},
    });
}
// 获取首页slots游戏
export const getHomeSlotsService = (param) => {
    return httpManager({
        url:api.openBoxIndexGames,
        method:'post',
        data:param,
    });
}
// 获取首页live casino游戏
export const getHomeLiveCasinoService = (param) => {
    return httpManager({
        url:api.openBoxIndexGames,
        method:'post',
        data:param,
    });
}

// 获取poker下载链接
export const pokerDownloadLinkService = () => {
    return httpManager({
        url:api.tosDownloadLinkConfig,
        method:'get',
        data:{},
    });
}

// 广告弹窗配置
export const popupConfigService = () => {
    return httpManager({
        url:api.tosPopupConfig,
        method:'get',
        data:{},
    });
}

// 获取体育启动url
export const getSportsGetStartUrlService = (param) => {
    return httpManager({
        url:api.sportsGetStartUrl,
        method:'post',
        data:param,
    });
}
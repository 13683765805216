import { createAsyncThunk,  createSlice } from '@reduxjs/toolkit';
import { getCookieAccessToken, getCookieEmail, getCookieUserId, getCookieUsername } from '../tools/CommonTool';

// export const loginThunk = createAsyncThunk('user/login', async (param) => {
//     console.log('param:'+JSON.stringify(param));
//     try {
//         const response = await loginService(param);
//         return response.data;
//     } catch (error) {
//         return error.response;
//     }
// });
// export const registerThunk = createAsyncThunk('user/register', async (param) => {
//     try {
//         const response = await registerService(param);
//         return response.data;
//     } catch (error) {
//         console.log('loginThunk error:',error);
//     }
// });

// const initialState = {
//     loadingStatus:'idle',
//     header: {
//         errorMsg:'',
//         isSuccess:false,
//         code:0,
//     },
//     body: {
//         access_token:'',
//         refresh_token:'',
//         user: {
//             pk:0,
//             username:'',
//             email:'',
//             first_name:'',
//             last_name:'',
//         },
//     }
// }

const initialState = {
    access_token:getCookieAccessToken(),
    refresh_token:'',
    user: {
        pk:getCookieUserId(),
        username:getCookieUsername(),
        email:getCookieEmail(),
        first_name:'',
        last_name:'',
        desc:'',
        gender:'',
        profile_image_url:'',
        card_number:'',
        referral_code:'',
        invite_code:'',
        parent_invite_code:'',
        joined_time:0,
        total_bet_count:0,
        total_bet_amount:0,
        total_win_amount:0,
        is_telegram_user:false,
        is_credit:false,
        allow_casino:false,
        allow_sport:false
    },
    logoutFlag:false,
    forceLogin:false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userLogin(state,action) {
            if(action.payload !== undefined) {
                if(action.payload.access_token !== undefined && action.payload.access_token !== null) {
                    state.access_token = action.payload.access_token;
                }else {
                    state.access_token = getCookieAccessToken();
                }
                if(action.payload.refresh_token !== undefined && action.payload.refresh_token !== null) {
                    state.refresh_token = action.payload.refresh_token;
                }else {
                    state.refresh_token = '';
                }
                if(action.payload.user.pk !== undefined && action.payload.user.pk !== null) {
                    state.user.pk = action.payload.user.pk;
                }else {
                    state.user.pk = getCookieUserId();
                }
                if(action.payload.user.username !== undefined && action.payload.user.username !== null) {
                    state.user.username = action.payload.user.username;
                }else {
                    state.user.username = getCookieUsername();
                }
                if(action.payload.user.email !== undefined && action.payload.user.email !== null) {
                    state.user.email = action.payload.user.email;
                }else {
                    state.user.email = getCookieEmail();
                }             
                if(action.payload.user.first_name !== undefined && action.payload.user.first_name !== null) {
                    state.user.first_name = action.payload.user.first_name;
                }else {
                    state.user.first_name = '';
                }
                if(action.payload.user.last_name !== undefined && action.payload.user.last_name !== null) {
                    state.user.last_name = action.payload.user.last_name;
                }else {
                    state.user.last_name = '';
                }
                if(action.payload.user.referral_code !== undefined && action.payload.user.referral_code !== null) {
                    state.user.referral_code = action.payload.user.referral_code;
                }else {
                    state.user.referral_code = '';
                }
                if(action.payload.invite_code !== undefined && action.payload.invite_code !== null) {
                    state.user.invite_code = action.payload.invite_code;
                }else {
                    state.user.invite_code = '';
                }
                if(action.payload.parent_invite_code !== undefined && action.payload.parent_invite_code !== null) {
                    state.user.parent_invite_code = action.payload.parent_invite_code;
                }else {
                    state.user.parent_invite_code = '';
                }
                if(action.payload.allow_casino !== undefined && action.payload.allow_casino !== null) {
                    state.user.allow_casino = action.payload.allow_casino;
                }else {
                    state.user.allow_casino = true;
                }
                if(action.payload.allow_sport !== undefined && action.payload.allow_sport !== null) {
                    state.user.allow_sport = action.payload.allow_sport;
                }else {
                    state.user.allow_sport = true;
                }
                state.logoutFlag = false;
                state.forceLogin = false;
                // console.log('loginDispatch state:',JSON.stringify(state));
            }
        },

        userLogout(state) {
            state.access_token = '';
            state.refresh_token = '';
            state.user.pk = 0;
            state.user.username = ''
            state.user.email = '';
            state.user.first_name = '';
            state.user.last_name = '';
            state.user.referral_code = '';
            state.user.invite_code = '';
            state.user.parent_invite_code = '';
            state.user.is_credit = false;
            state.user.allow_casino = true;
            state.user.allow_sport = true;
            state.logoutFlag = true;
            state.forceLogin = false;
            // console.log('userLogout state:',JSON.stringify(state));
        },
        userForceLogin(state) {
            state.access_token = '';
            state.refresh_token = '';
            state.user.pk = 0;
            state.user.username = ''
            state.user.email = '';
            state.user.first_name = '';
            state.user.last_name = '';
            state.user.referral_code = '';
            state.user.invite_code = '';
            state.user.parent_invite_code = '';
            state.user.is_credit = false;
            state.user.allow_casino = true;
            state.user.allow_sport = true;
            state.logoutFlag = true;
            state.forceLogin = true;
            // console.log('userForceLogin state:',JSON.stringify(state));
        },
        userRefreshUserInfo(state,action) {
            if(action.payload.name !== undefined && action.payload.name.length > 0) {
                state.user.username = action.payload.name;
            }
            if(action.payload.desc !== undefined && action.payload.desc.length > 0) {
                state.user.desc = action.payload.desc;
            }
            if(action.payload.gender !== undefined && action.payload.gender.length > 0) {
                state.user.gender = action.payload.gender;
            }
            if(action.payload.profile_image_url !== undefined && action.payload.profile_image_url.length > 0) {
                state.user.profile_image_url = action.payload.profile_image_url;
            }
            if(action.payload.card_number !== undefined && action.payload.card_number.length > 0) {
                state.user.card_number = action.payload.card_number;
            }
            if(action.payload.is_credit !== undefined) {
                state.user.is_credit = action.payload.is_credit;
            }
            if(action.payload.allow_casino !== undefined) {
                state.user.allow_casino = action.payload.allow_casino;
            }
            if(action.payload.allow_sport !== undefined) {
                state.user.allow_sport = action.payload.allow_sport;
            }
            if(action.payload.referral_code !== undefined) {
                state.user.referral_code = action.payload.referral_code;
            }
            if(action.payload.invite_code !== undefined) {
                state.user.invite_code = action.payload.invite_code;
            }
            if(action.payload.parent_invite_code !== undefined) {
                state.user.parent_invite_code = action.payload.parent_invite_code;
            }
            if(action.payload.joined_time !== undefined) {
                state.user.joined_time = action.payload.joined_time;
            }
            if(action.payload.total_bet_count !== undefined) {
                state.user.total_bet_count = action.payload.total_bet_count;
            }
            if(action.payload.total_bet_amount !== undefined) {
                state.user.total_bet_amount = action.payload.total_bet_amount;
            }
            if(action.payload.total_win_amount !== undefined) {
                state.user.total_win_amount = action.payload.total_win_amount;
            }
            if(action.payload.is_telegram_user !== undefined) {
                state.user.is_telegram_user = action.payload.is_telegram_user;
            }
            // console.log('userRefreshUserInfo state:',JSON.stringify(state));
        }
    },
    // extraReducers: builder => {
    //     builder
    //       .addCase(loginThunk.pending, (state, action) => {
    //         state.loadingStatus = 'loading';
    //     })
    //       .addCase(loginThunk.fulfilled, (state, action) => {
    //         state.loadingStatus = 'idle';
    //         if(action.payload !== undefined) {
    //             if(action.payload.access_token !== undefined) {
    //                 state.body = action.payload;
    //                 // setCookieAccessToken(action.payload.access_token);
    //                 // setCookieUserId(action.payload.user.pk);
    //                 state.header = {
    //                     errorMsg: '',
    //                     isSuccess: true,
    //                     code: 200
    //                 }
    //             }else if(action.payload.data !== undefined) {
    //                 state.header = {
    //                     errorMsg: Object.values(action.payload.data).length > 0 ? 
    //                     Object.values(action.payload.data)[0] : 
    //                     JSON.stringify(action.payload.data),
    //                     isSuccess: false,
    //                     code: action.payload.status
    //                 }
    //             }
    //         }            
    //     })
    // }
});

export const { userLogin,userLogout,userForceLogin,userRefreshUserInfo } = userSlice.actions;

export default userSlice.reducer;
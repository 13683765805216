import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import arrowRightIcon from '../../assets/images/wallet/wallet-arrow-right.png';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const WalletContentMainFAQ = (props) => {
    const { faq } = props;
    const navigate = useNavigate();

    const handleFAQClick = () => {
        navigate(RoutePath.faq);
    };

    return (
        <Box sx={{
            bgcolor:'#1D1D28',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            padding:'18px',
            mt:'8px',
            width:'calc(100% - 36px)',
        }}>
            <Button variant='contained' sx={{
                bgcolor:'#2A2A39',
                borderRadius:'24px',
                height:'48px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                padding:'0px 28px',
                width:'100%',
                color:'#ACB3D7',
                fontSize:'18px',
                fontStyle:'normal',
                fontWeight:400,
                '&:hover': {
                    bgcolor:'#2A2A39',
                }
            }} onClick={handleFAQClick}>
                {faq}
                <CardMedia
                    component="img"
                    image={ arrowRightIcon }
                    sx={{
                        objectFit:'contain',
                        width:'13px',
                        height:'13px',
                    }}
                />
            </Button>
        </Box>
    );
}

export default WalletContentMainFAQ;
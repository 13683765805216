import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import HomeContentMainTop from './HomeContentMainTop';
import HomeContentMainBanner from './HomeContentMainBanner';
import HomeContentMainInHouseGame from './HomeContentMainInHouseGame';
import HomeContentMainGameProviders from './HomeContentMainGameProviders';
import HomeContentMainHistory from './HomeContentMainHistory';
import HomeContentMainEffectBanner from './HomeContentMainEffectBanner';
import HomeContentMainPopularSlots from './HomeContentMainPopularSlots';
import HomeContentMainNewSlots from './HomeContentMainNewSlots';
import HomeContentMainHotSlots from './HomeContentMainHotSlots';
import HomeContentMainSearch from './HomeContentMainSearch';
import HomeContentMainBannerNew from './HomeContentMainBannerNew';
import HomeContentMainCategory from './HomeContentMainCategory';

const HomeContentMain = () => {
    const theme = useTheme();

    return (
        <Grid container>
            <Container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1180px',
                }
            }}>
                <HomeContentMainTop />
            </Container>

            <Grid container sx={{
                padding:'0px 60px 145px 60px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'18px',
                },
            }}>                
                <Container sx={{
                    // bgcolor:'#bbbbbb',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'start',
                    alignItems:'start',
                    '@media': {
                        pl:'0px',
                        pr:'0px',
                        maxWidth:'1100px',
                    }
                }}>
                    {/* <HomeContentMainBanner /> */}
                    <HomeContentMainBannerNew />
                    <HomeContentMainCategory />
                    <HomeContentMainSearch 
                        mt={'31px'} 
                        mobileMT={'18px'} 
                        type={'home'}
                    />
                    <HomeContentMainPopularSlots />
                    <HomeContentMainNewSlots />
                    <HomeContentMainHotSlots />
                    {/* <HomeContentMainSlots /> */}
                    {/* <HomeContentMainLive /> */}
                    <HomeContentMainInHouseGame />
                    <HomeContentMainEffectBanner />
                    <HomeContentMainGameProviders />
                    <HomeContentMainHistory />
                    {/* <HomeContentMainTestimonials /> */}
                </Container>
            </Grid>
        </Grid>
    );
}

export default HomeContentMain;
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ProfileContentMain from './ProfileContentMain';
import Footer from '../footer/Footer';

const ProfileContent = (props) => {
    const { screenMD } = props;

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    return (
        <Grid sx={{
            pl:screenMD ? '0px' : '240px',
            pt:screenMD ?  '68px' : '64px',
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:screenMD ? 'flex-start' : 'space-between',
            }}>
                <ProfileContentMain />
                {screenMD ? '' : <Footer />}
            </Box>
        </Grid>
    );
}

export default ProfileContent;
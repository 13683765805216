import Cookies from 'js-cookie';

export const filterDecimal = (inputString) => {
  const decimalPattern = /(\d*\.\d*|\d+)/;
  
  const decimalMatch = inputString.match(decimalPattern);
  
  if (decimalMatch) {
    return decimalMatch[0];
  } else {
    return '';
  }
}
// export const multiplyDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     const result = decimal1 * decimal2;
//     return result;
//   } else {
//     return 0; 
//   }
// }
// export const subtractionDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     const result = decimal1 - decimal2;
//     return result;
//   } else {
//     return 0; 
//   }
// }
// export const addDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     const result = decimal1 + decimal2;
//     return result;
//   } else {
//     return 0; 
//   }
// }
// export const compareDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     if (decimal1 < decimal2) {
//       return -1;
//     } else if (decimal1 > decimal2) {
//       return 1;
//     } else {
//       return 0
//     }
//   } else {
//     return -2;
//   }
// }
// export const isEqualDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     if (decimal1 = decimal2) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }
// export const isGreaterThanDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     if (decimal1 > decimal2) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }
// export const isLessThanDecimals = (str1, str2) => {
//   const decimal1 = parseFloat(str1);
//   const decimal2 = parseFloat(str2);

//   if (!isNaN(decimal1) && !isNaN(decimal2)) {
//     if (decimal1 < decimal2) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }

export const getCookieAccessToken = () => {
  return (Cookies.get('accessToken') !== undefined && Cookies.get('accessToken').length > 0) ? Cookies.get('accessToken') : '';
};
export const getCookieUserId = () => {
  return (Cookies.get('userId') !== undefined && Cookies.get('userId').length > 0) ? Cookies.get('userId') : ''; 
};
export const getCookieUsername = () => {
  return (Cookies.get('username') !== undefined && Cookies.get('username').length > 0) ? Cookies.get('username') : ''; 
};
export const getCookieEmail = () => {
  return (Cookies.get('email') !== undefined && Cookies.get('email').length > 0) ? Cookies.get('email') : ''; 
};
export const setCookieAccessToken = (token) => {
  Cookies.set('accessToken',token);
};
export const setCookieUserId = (userId) => {
  Cookies.set('userId',userId);
}
export const setCookieUsername = (username) => {
  Cookies.set('username',username);
}
export const setCookieEmail = (email) => {
  Cookies.set('email',email);
}
export const getFingerPrintInfo = () => {
  return Cookies.get('WinGameFingerPrint')
}
export const setFingerPrintInfo = (uuid) => {
  Cookies.set('WinGameFingerPrint', uuid);
}
export const clearUserCookies = () => {
  Cookies.remove('accessToken');
  Cookies.remove('userId');
  Cookies.remove('username');
};
import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import arrowRightIcon from '../../assets/images/wallet/wallet-arrow-right.png';
import arrowDownIcon from '../../assets/images/wallet/wallet-withdraw-arrow-down.png';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const CustomTableRow = styled(TableRow)({
    backgroundColor:'#2A2A39',
    [`& .${tableCellClasses.root}`]: {
        borderBottom: 'none',
    }
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <React.Fragment>
        <CustomTableRow sx={{
            bgcolor:'#2A2A39',
        }}>
          <TableCell align="left" sx={{
            color:'#ACB3D7',
            fontSize:'14px',
            fontStyle:'normal',
            fontWeight:400,
            cursor:'pointer',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            gap:'10px',
          }} onClick={() => setOpen(!open)}>
            {row.title}
              <CardMedia
                 component="img"
                 image={open ? arrowDownIcon : arrowRightIcon}
                 sx={{
                    width:open ? '15px' : '13px',
                    height:open ? '13px' : '13px',
                    objectFit:'contain',
                 }}
               /> 
          </TableCell>
        </CustomTableRow>
        <CustomTableRow sx={{
            bgcolor:'#2E2E38',
        }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0,paddingLeft:'8px',paddingRight:'8px' }} >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400
                }}>
                  {row.desc}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </CustomTableRow>
      </React.Fragment>
    );
}

const FAQContent = () => {
    const { t, i18n } = useTranslation();
    const textData = {
        howToCurrency: t("how_to__currency"),
        howToCurrencyDesc: t("how_to__currency_desc"),
        howLongArrive: t("how_long___arrive"),
        howLongArriveDesc: t("how_long___arrive_desc"),
        howToCrypto: t("how_to___crypto"),
        howToCryptoDesc: t("how_to___crypto_desc"),
        whyThereDeposit: t("why_there___deposit"),
        whyThereDepositDesc: t("why_there___deposit_desc"),
    };

    const faqArr = [
        {
            title:textData.howToCurrency,desc:textData.howToCurrencyDesc
        },
        {
            title:textData.howLongArrive,desc:textData.howLongArriveDesc
        },
        {
            title:textData.howToCrypto,desc:textData.howToCryptoDesc
        },
        {
            title:textData.whyThereDeposit,desc:textData.whyThereDepositDesc
        },
    ];

    return (
        <TableContainer component={Paper} sx={{
            bgcolor:'#2A2A39',
            mt:'75px',
        }}>
            <Table aria-label="collapsible table">
                <TableBody>
                {faqArr.map((row) => (
                    <Row key={row.title} row={row} />
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default FAQContent;
const api = {
    // 登录
    authLogin:'auth/login/',
    // 邮箱验证码注册
    authRegistrationConfirmCode:'auth/registration/confirm-code/', 
    // 邮箱注册发送验证码
    authRegistrationSendCode:'auth/registration/send-code/',
    // 注册是否强制输入邀请码
    gameGetGameConfig:'game/get_game_config/',
    // 退出登录
    authLogout:'auth/logout/',
    // 重置密码
    authRegistrationResetPasswordConfirmCode:'auth/registration/reset-password-confirm-code/',
    // 重置邮箱登录密码 发送验证码
    authRegistrationResetPasswordSendCode:'auth/registration/reset-password-send-code/',
    // 用户个人信息
    playeruser:'playeruser/',
    // 个人wallet钱包(现金账户和资金账户)
    gameGetSimpleTokensInfo:'game/get_simple_tokens_info/',
    // 充值地址
    gameGetTokensInfo:'game/get_tokens_info/',
    // 划转
    walletTransfer:'wallet/transfer/',
    // 提现
    walletWithdrawRequest:'wallet/withdraw-request/',
    // 充值记录
    walletGetDepositRecord:'wallet/get-deposit-record/',
    // 提现记录
    walletGetWithdrawRecord:'wallet/get-withdraw-record/',
    // telegram登录
    authTelegramRegister:'auth/telegram/register/',
    // 网页在线人数
    serverStatusOnlineUserNumber:'server_status/online_user_number/',
    // 排行榜
    serverStatusBiggestToday:'server_status/biggest_today/',
    // 排行榜
    serverStatusLatestRound:'server_status/lastest_round/',
    // 游戏详情下注数据 - latest bets
    latestBets:'latest_bets/',
    // 游戏详情下注数据 - my bets
    myBets:'mybets/',
    // 推荐人收益
    referralGetReferralInfo:'referral/get-referral-info/',
    // 绑定邀请码
    authBindInviteCode:'auth/bind_invite_code/',
    // 绑定邀请码 new
    openBoxBindInviteCode:'openbox/bind_invite_code',
    // 获取游戏url
    openBoxLogin:'openbox/login/',
    // 获取客服链接
    tosCustomerServiceLink:'tos/customer-service-link/',
    // 获取首页游戏
    openBoxIndexGames:'openbox/index_games/',
    // 获取游戏列表
    openBoxOnlineGames:'openbox/online_games/',
    // 获取游戏最近记录
    openBoxBetHistory:'openbox/bet_history/',
    // 收藏的游戏列表
    openBoxFavoriteGames:'openbox/favorite_games/',
    // 收藏游戏
    openBoxFavoriteGame:'openbox/favorite_game/',
    // 取消收藏游戏
    openBoxUnfavoriteGame:'openbox/unfavorite_game/',
    // 最近玩过游戏列表
    openBoxRecentGames:'openbox/recent_games/',
    // 获取casino popular
    openBoxPopularGames:'openbox/popular_games/',
    // 获取casino new
    openBoxNewGames:'openbox/new_games/',
    // 获取casino hot
    openBoxHotGames:'openbox/hot_games/',
    // 获取casino fishing
    openBoxFishGames:'openbox/fish_games/',
    // 搜索游戏
    openBoxSearchGames:'openbox/search_games/',
    // TG Bot 里快捷授权登录
    authTelegramDisposableLogin:'auth/telegram/disposableLogin/',
    // poker下载链接地址
    tosDownloadLinkConfig:'tos/download-link-config/',
    // 广告弹窗配置
    tosPopupConfig:'tos/popup-config/',
    // 获取体育启动url
    sportsGetStartUrl:'sports/get_start_url',
}

export default api;
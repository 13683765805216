import React, { useEffect } from 'react';
import LoginAndRegisterContent from '../component/login-and-register/LoginAndRegisterContent';
import { useLocation } from 'react-router-dom';
import Customer from '../component/common/Customer';
import { useDispatch, useSelector } from 'react-redux';
import { switchAppCategory } from '../features/appSlice';
import RoutePath from '../tools/RoutePath';

const LoginAndRegister = () => {
    const location = useLocation();
    let type = location.state && location.state.type;
    let fromPage = location.state && location.state.fromPage;
    const isLogout = location.state && location.state.isLogout;
    if(type === undefined || type === null || type.length === 0) {
        if(location.pathname.includes('login')) {
            type = 'login'
        }else if(location.pathname.includes('register')) {
            type = 'register'
        }
    }
    if(fromPage === undefined || fromPage === null || fromPage.length === 0) {
        fromPage = RoutePath.home
    }

    const dispatch = useDispatch();
    const appCategory = useSelector((state) => state.app.appCategory);
    useEffect(() => {
        if(appCategory !== 'casino') {
            dispatch(switchAppCategory({appCategory:'casino'}));
        }
    }, []);

    return (
        <React.Fragment>
            <Customer />
            <LoginAndRegisterContent 
                type={type}
                fromPage={fromPage}
                isLogout={isLogout}
            />
        </React.Fragment>
    )
}

export default LoginAndRegister;
import React, { useEffect } from 'react';
import TTAppBar from '../component/common/TTAppBar';
import DepositContent from '../component/deposit/DepositContent';
import { useSelector } from 'react-redux';
import { fpDepositEvent } from '../tools/FacebookPixelEvent';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Deposit = () => {
    const { t } = useTranslation();
    const textData = {
        deposit: t("deposit"),
    }

    const nickname = useSelector((state) => state.user.user.username);
    const userId = useSelector((state) => state.user.user.pk);

    const user = {
        userId:userId,
        nickname:nickname,
    }

    const location = useLocation();
    const network = location.state && location.state.network;
    const token = location.state && location.state.token;
    const address = location.state && location.state.address;
    const language = location.state && location.state.language;
    const depositLimit = location.state && location.state.depositLimit;


    useEffect(() => {
        fpDepositEvent(user);
    }, []);

    return (
        <React.Fragment>
            <TTAppBar
                style={0} 
                title={textData.deposit}
            />
            <DepositContent 
                network={network}
                token={token}
                address={address}
                language={language}
                depositLimit={depositLimit}
            />
        </React.Fragment>
    )
}

export default Deposit;
import en from "./en.json";
import zh from "./zh.json";
import ko from "./ko.json";
import pt from "./pt.json";
import es from "./es.json";

export const resources = {
    "en": {
        translation: en
    },
    "zh": {
        translation: zh
    },
    "ko": {
        translation: ko
    },
    "pt": {
        translation: pt
    },
    "es": {
        translation: es
    }
}

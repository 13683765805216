import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import logoIcon from '../../assets/images/wallet/wallet-tt-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { walletDepositRecordService, walletWithdrawRecordService } from '../../network/service/wallet';
import { walletRecordRefresh } from '../../features/walletSlice';

const TokenRecordContentHistory = (props) => {
    const {
        orderNo,type,amount,status,time,deposit,withdraw,prepare,inProgress,success,failed,noData,category,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const dispatch = useDispatch();

    const recordList = useSelector((state) => state.wallet.recordList);
    useEffect(() => {
        handleRecord();
    },[category]);

    const handleRecord = async () => {
        try {
            if(category === 0) {
                const response = await walletDepositRecordService();
                dispatch(walletRecordRefresh({recordList: response.data.data}));
            }else if(category === 1) {
                const response = await walletWithdrawRecordService();
                dispatch(walletRecordRefresh({recordList: response.data.data}));
            }
        } catch (error) {
            console.log('handleRecord error:'+error);
        }
    };
    const handleStatus = (status) => {
        // 交易状态 1:成功 2:准备中 3:进行中 4:失败 5:拒绝 6:链上拒绝
        if(status === 1) {
            return success;
        }else if(status === 2) {
            return prepare;
        }else if(status === 3) {
            return inProgress;
        }else {
            return failed;
        }
    };

    return (
        <Grid container sx={{
            bgcolor:'#1D1D28',
            borderRadius:'18px',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            width:'100%',
            minHeight:'636px',
            padding:'5px 40px',
            mt:'36px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'18px',
                padding:'0px 18px 18px 18px',
                bgcolor:'transparent',
            },
        }}>
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                minHeight:'58px',
                width:'100%',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    minHeight:'31px',
                },
            }}>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'18px',
                    fontStyle:'normal',
                    fontWeight:400,
                    width:'calc(100% / 3)',
                }}>
                    {type}
                </Typography>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'18px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textAlign:'center',
                    width:'calc(100% / 3)',
                }}>
                    {amount}
                </Typography>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'18px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textAlign:'right',
                    width:'calc(100% / 3)',
                }}>
                    {status}
                </Typography>
            </Grid>
            {recordList.length === 0 ? 
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    height:'578px',
                    gap:'30px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        height:'400px',
                        gap:'18px',
                    },
                }}>
                    <CardMedia
                        component="img"
                        image={ logoIcon }
                        sx={{
                            objectFit:'contain',
                            width:'219px',
                            height:'70px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'185px',
                                height:'58px',
                            },
                        }}
                    />
                    <Typography sx={{
                        color:'#6D728D',
                        fontSize:'18px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'12px',
                        },
                    }}>
                        {noData}
                    </Typography>
                </Box> : 
                recordList.map((item,index) => (
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        minHeight:'58px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            minHeight:'50px',
                            mt:'18px',
                        },
                    }} key={index}>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:400,
                            width:'calc(100% / 3)',
                        }}>
                            {category === 0 ? deposit : withdraw}
                        </Typography>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:400,
                            textAlign:'center',
                            width:'calc(100% / 3)',
                        }}>
                            {item.amount}
                        </Typography>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:400,
                            textAlign:'right',
                            width:'calc(100% / 3)',
                        }}>
                            {handleStatus(item.status)}
                        </Typography>
                    </Grid>
                ))
            }
            
        </Grid>
    );
}

export default TokenRecordContentHistory;
import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TTSearchAlert from '../common/TTSearchAlert';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const HomeContentMainSearch = (props) => {
    const { 
        mt,mobileMT,type,gameProvider,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const { t } = useTranslation();
    const textData = {
        input_search: t("input_search"),
    };

    const selectSearchText = useSelector((state) => state.gameSearch.searchText);
    let searchText = null;
    if(type === 'home') {
        searchText = selectSearchText.home;
    }else if(type === 'popular') {
        searchText = selectSearchText.popular;
    }else if(type === 'new') {
        searchText = selectSearchText.new;
    }else if(type === 'hot') {
        searchText = selectSearchText.hot;
    }else if(type === 'fishing') {
        searchText = selectSearchText.fishing;
    }else if(type === 'favorite') {
        searchText = selectSearchText.favorite;
    }else if(type === 'recent') {
        searchText = selectSearchText.recent;
    }

    const [ boxOffsetTop, setBoxOffsetTop ] = useState(64);
    const searchRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const boxRect = searchRef.current.getBoundingClientRect();
            if(boxRect.top < 64) {
                setBoxOffsetTop(64);
            }else {
                setBoxOffsetTop(boxRect.top);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSearchClick = () => {
        if(!screenMD) {
            setShowPopup(true);
        }else {
            navigate(RoutePath.search,{ 
                state: { 
                    type:type,
                    gameProvider:gameProvider,
                }
            });
        }
    };

    // alert
    const [ showPopup,setShowPopup ] = useState(false);
    const handleCloseSearchAlert = () => {
        setShowPopup(false);
    };

    return (
        <React.Fragment>
            {showPopup ? 
            <TTSearchAlert 
                type={type}
                gameProvider={gameProvider}
                isOpen={showPopup}
                closeCallback={handleCloseSearchAlert}
                topHeight={boxOffsetTop}
            /> : ''
            }

            <Grid container sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                mt:mt,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    mt:mobileMT,
                },
            }}>
                <Box ref={searchRef} sx={{
                    display:'flex',
                    padding:'0px 18px',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    borderRadius:'100px',
                    border:'1px solid #3A3E53',
                    bgcolor:'#000000',
                    gap:'8px',
                    height:'48px',
                    width:'calc(100% - 36px)',
                    cursor:'text',
                    '&:hover': {
                        border:'1px solid #ACB3D7',
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        height:'40px',
                    },
                }} onClick={handleSearchClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path d="M19.7337 18.1817L14.7383 13.6622L14.8577 13.5756C16.0882 12.2163 16.7952 10.4674 16.7952 8.57997C16.7952 4.36352 13.168 0.943604 8.4573 0.943604C3.74656 0.943604 0 4.36352 0 8.57131C0 12.7791 3.75574 16.3289 8.4573 16.3289C9.62351 16.3289 10.7254 16.1124 11.7264 15.7228L11.8641 15.6276L17.0799 20.6839C17.4472 21.0302 18.0349 21.0302 18.4022 20.6839L19.7245 19.4371C20.0918 19.0908 20.0918 18.5367 19.7245 18.1904L19.7337 18.1817ZM8.4573 14.8484C4.61892 14.8484 1.56107 12.0605 1.56107 8.62326C1.56107 5.18603 4.60973 2.39815 8.44812 2.39815C12.2865 2.39815 15.2709 5.18603 15.2709 8.62326C15.2709 12.0605 12.2865 14.8484 8.44812 14.8484H8.4573Z" fill="#6D728D"/>
                    </svg>
                    <Typography sx={{
                        color:searchText.length > 0 ? '#FFFFFF' : '#6D728D',
                        fontSize:'20px',
                        fontWeight:500,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'16px',
                        },
                    }}>
                        {searchText.length > 0 ? searchText : textData.input_search}
                    </Typography>
                </Box>
            </Grid>
        </React.Fragment>
    );
}

export default HomeContentMainSearch;
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import helpCenter from '../../assets/images/drawer/drawer-menu-help-center.png';
import arrowRight from '../../assets/images/drawer/drawer-arrow_right.png';

const DrawerMenuHelpCenter = (props) => {
    const { btnText, btnClick } = props;

    const CustomButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        backgroundColor: '#2A2A39',
        borderRadius:'8px',
        display:'flex',
        alignItems:'center',
        height:'51px',
        justifyContent:'space-between',
        padding:'0px 18px',
        margin:'4px 12px 0px 12px',
        width:'calc(100% - 24px)',
        gap:'12px',
        textDecoration:'none',
        '&:hover': {
            backgroundColor: '#3A3E53',
        },
    });

    return (
        <CustomButton variant='contained' onClick={btnClick}>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'12px',
            }}>
                <CardMedia
                    component="img"
                    image={ helpCenter }
                    sx={{
                        width:'20px',
                        height:'20px',
                        objectFit:'contain',
                    }}
                />
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                }}>
                    {btnText}
                </Typography>
            </Box>
            
            <CardMedia
                component="img"
                image={ arrowRight }
                sx={{
                    width:'7px',
                    height:'16px',
                    objectFit:'contain',
                }}
            />
        </CustomButton>
    );
}

export default DrawerMenuHelpCenter;

import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FavoriteContentItem from '../favorite/FavoriteContentItem';
import gameIcon1 from '../../assets/images/temp/game1.png';
import gameIcon2 from '../../assets/images/temp/game2.png';
import gameIcon3 from '../../assets/images/temp/game3.png';

const GameContentRecommend = (props) => {
    const {
        category,recommendForYou,comingSoon,fromPage,currentGameUrl,gameOnClick,
    } = props;
    const theme = useTheme();

    let recommendArr = [];
    if(currentGameUrl === 'https://www.ttbetgame.com/heads-and-tails/') {
        recommendArr = [
            {
                icon:gameIcon2,
                gameUrl:'https://www.ttbetgame.com/plinko/',
                title:'Plinko',
            },
        ]
    }else if(currentGameUrl === 'https://www.ttbetgame.com/plinko/') {
        recommendArr = [
            // {
            //     icon:gameIcon1,
            //     gameUrl:'https://www.ttbetgame.com/heads-and-tails/',
            //     title:'Heads&Tails',
            // },
        ]
    }else {
        recommendArr = [
            // {
            //     icon:gameIcon1,
            //     gameUrl:'https://www.ttbetgame.com/heads-and-tails/',
            //     title:'Heads&Tails',
            // },
            {
                icon:gameIcon2,
                gameUrl:'https://www.ttbetgame.com/plinko/',
                title:'Plinko',
            }
        ]
    }

    const handleGameOnClick = () => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'smooth'
      });
    };

    return (
        <Box sx={{
            display:recommendArr.length === 0 ? 'none' : 'flex',
            flexDirection:'column',
            justifyContent:'start',
            alignItems:'start',
            mt:'48px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'8px',
                width:'100%',
                bgcolor:'#1D1D28',
                pb:'18px',
            },
        }}>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'24px',
                fontStyle:'normal',
                fontWeight:400,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                    ml:'18px',
                    mt:'18px',
                },
            }}>
                {recommendForYou}
            </Typography>
            <Grid container sx={{
                display:'flex',
                justifyContent:'start',
                alignItems:'start',
                mt:'17px',
                rowGap:'30px',
                columnGap:'22px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    rowGap:'18px',
                    columnGap:'18px',
                    padding:'0px 36px',
                },
            }}>
                {recommendArr.map((item,index) => (
                    <FavoriteContentItem 
                        image={item.icon}
                        title={item.title}
                        gameUrl={item.gameUrl}
                        isCloseFreePlayMode={true}
                        playerNum={888}
                        category={category}
                        comingSoon={comingSoon}
                        fromPage={fromPage}
                        isActive={true}
                        gameOnClick={handleGameOnClick}
                        key={index}
                    />
                ))}
            </Grid>
        </Box>
    );
}

export default GameContentRecommend;
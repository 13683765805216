import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TokenRecordContentHeader from './TokenRecordContentHeader';
import TokenRecordContentHistory from './TokenRecordContentHistory';

const TokenRecordContentMain = (props) => {
    const { token,category } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        recordOf: t("record_of"),
        orderNo: t("order_no"),
        type: t("type"),
        amount: t("amount"),
        status: t("status"),
        time: t("time"),
        all: t("all"),
        deposit: t("deposit"),
        withdraw: t("withdraw"),
        prepare: t("prepare"),
        inProgress: t("in_progress"),
        success: t("success"),
        failed: t("failed"),
        back: t("back"),
        noData: t("no_data"),
    };

    const [ chooseCategory,setChooseCategory ] = useState(category);
    const handleCategoryClick = (index) => {
        setChooseCategory(index);
    };

    return (
        <Grid container sx={{
            padding:'54px 60px 145px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'18px 0px 0px 0px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <TokenRecordContentHeader 
                    recordOf={textData.recordOf}
                    token={token}
                    all={textData.all}
                    deposit={textData.deposit}
                    withdraw={textData.withdraw}
                    back={textData.back}
                    category={category}
                    categoryOnClick={handleCategoryClick}
                />
                <TokenRecordContentHistory 
                    orderNo={textData.orderNo}
                    type={textData.type}
                    amount={textData.amount}
                    status={textData.status}
                    time={textData.time}
                    deposit={textData.deposit}
                    withdraw={textData.withdraw}
                    prepare={textData.prepare}
                    inProgress={textData.inProgress}
                    success={textData.success}
                    failed={`${textData.failed.charAt(0).toUpperCase()}${textData.failed.slice(1)}`}
                    noData={textData.noData}
                    category={chooseCategory}
                />
            </Container>
        </Grid>
    );
}

export default TokenRecordContentMain;
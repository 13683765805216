import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import FavoriteContentItem from '../favorite/FavoriteContentItem';
import gameIcon2 from '../../assets/images/temp/game2.png';
import RoutePath from '../../tools/RoutePath';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#3A3E53',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 8,
      backgroundColor: '#1CA45F',
    },
}));

const HouseGamesContentMain = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        inHouseGames: t("in_house_game"),
        loadMore: t("load_more"),
        noMoreData: t("no_more_data"),
        comingSoon: t("coming_soon"),
    };
    
    const itemArr = [
        // gameIcon1,
        gameIcon2,
    ];
    const titleArr = [
        // 'Heads&Tails',
        'Plinko',
    ];
    const gameUrlArr = [
        // 'https://www.ttbetgame.com/heads-and-tails/',
        'https://www.ttbetgame.com/plinko/',
    ];

    const totalNum = 10;
    const [ progress, setProgress ] = React.useState(itemArr.length / totalNum * 100);

    const handleLoadMoreClick = () => {
        if(itemArr.length >= totalNum) {
            return;
        }
        setProgress((progress >= totalNum) ? 0 : (progress + 10));
    }

    return (
        <Grid container sx={{
            padding:'36px 60px 145px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'18px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                minHeight:'500px',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'28px',
                    fontStyle:'normal',
                    fontWeight:500,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'18px',
                    },
                }}>
                    {textData.inHouseGames}
                </Typography>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'start',
                    mt:'17px',
                    rowGap:'46px',
                    columnGap:'22px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        rowGap:'18px',
                        columnGap:'18px',
                        ml:'18px',
                        mr:'18px',
                    },
                }}>
                    {itemArr.map((item,index) => (
                        <FavoriteContentItem 
                            image={item}
                            title={titleArr[index]}
                            gameUrl={gameUrlArr[index]}
                            isCloseFreePlayMode={true}
                            playerNum={888}
                            category={textData.inHouseGames}
                            comingSoon={textData.comingSoon}
                            fromPage={RoutePath.inHouseGames}
                            key={index}
                        />
                    ))}
                </Grid>
                {/* <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    width:'100%',
                    gap:'54px',
                    mt:'48px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'20px',
                        mt:'20px',
                    },
                }}>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'84px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'28px',
                        },
                    }}>
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:500,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'16px',
                            },
                        }}>
                            {`${itemArr.length}/${totalNum}`}
                        </Typography>
                        <BorderLinearProgress variant="determinate" value={progress} sx={{
                            width:'174px',
                            height:'16px',
                        }}/>
                        <Typography sx={{
                            color:'#FFFFFF',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:500,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'16px',
                            },
                        }}>
                            {`${itemArr.length/totalNum * 100}%`}
                        </Typography>
                    </Box>
                    <Button variant='text' onClick={handleLoadMoreClick} sx={{
                        bgcolor:'#3A3E53',
                        height:'48px',
                        borderRadius:'24px',
                        padding:'0px 30px',
                        justifyContent:"center",
                        alignItems:'center',
                        color:'#FFFFFF',
                        fontSize:'18px',
                        fontStyle:'normal',
                        fontWeight:500,
                        textTransform:'none',
                        '&:hover': {
                            background: '#3A3E53',
                        },
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'16px',
                            mb:'18px',
                        },
                    }}>
                        {progress === 100 ? textData.noMoreData : textData.loadMore}
                    </Button>
                </Box> */}
            </Container>
        </Grid>
    );
}

export default HouseGamesContentMain;
import React from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Collapse from '@mui/material/Collapse';
import tipIcon from '../../assets/images/login-and-register/login-and-register-input-tip.png';

const LoginAndRegisterContentInputTip = (props) => {
    const { open,text, } = props;
    const theme = useTheme();

    return (
        <Collapse 
            in={open}
            timeout={{enter:500,exit:0,}} 
        >
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                mt:'20px',
            }}>
                <Box sx={{
                    bgcolor:'#3A3E53',
                    width:'18px',
                    height:'18px',
                    transform:'rotate(45deg)',
                    ml:'17px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'14px',
                        height:'14px',
                        ml:'19px',
                    },
                }} />
                <Box sx={{
                    bgcolor:'#3A3E53',
                    borderRadius:'8px',
                    display:'flex',
                    justifyContent:'flex-start',
                    justifyContent:'flex-start',
                    gap:'8px',
                    padding:'11px 10px 10px 17px',
                    maxWidth:'360px',
                    mt:'-11px',
                    zIndex:1,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'5px',
                        padding:'10px 8px 8px 13px',
                        maxWidth:'290px',
                        mt:'-10px',
                    },
                }}>
                    <CardMedia
                        component="img"
                        image={tipIcon}
                        sx={{
                            width:'18px',
                            height:'18px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'14px',
                                height:'14px',
                            },
                        }}
                    />
                    <Typography sx={{
                        color:'#C25156',
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:400,
                        mt:'-3px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'13px',
                            mt:'-2px',
                        },
                    }}>
                        {text}
                    </Typography>
                </Box>
            </Box>
        </Collapse>
    );
}

export default LoginAndRegisterContentInputTip;
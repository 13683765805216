import React from 'react';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import footerLogo from '../../../assets/images/footer/footer-logo.png';

const FooterMobileTop = (props) => {
    const { ttbetIsMind } = props;

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'flex-start',
            gap:'18px',
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
            }}>
                <CardMedia
                    component="img"
                    image={ footerLogo }
                    sx={{
                        width:'97px',
                        height:'30px',
                    }}
                />
            </Box>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'10px',
                fontStyle:'normal',
                fontWeight:400,
            }}>
                {ttbetIsMind}
            </Typography>
        </Box>
    );
}

export default FooterMobileTop;
import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import gameIcon2 from '../../assets/images/temp/draw-menu-game2.png';
import icon2 from '../../assets/images/temp/game2.png';
import RoutePath from '../../tools/RoutePath';
import { useNavigate } from 'react-router-dom';
import { gameRefresh } from '../../features/gameSlice';
import { useDispatch } from 'react-redux';

const DrawerMenuItem = (props) => {
    const { selected, btnIconImage, btnIconImageSelected, btnText, btnClick, isInHouseGame,inHouseGame } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const CustomButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        backgroundColor: selected ? 'none' : '#1D1D28',
        background: selected ? 'linear-gradient(90deg, rgba(178, 42, 40, 0.79) 0%, rgba(178, 42, 40, 0.00) 100%)' : 'none',
        color: selected ? '#FFFFFF' : '#ACB3D7',
        borderRadius:'0px',
        display:'flex',
        alignItems:'center',
        height:'51px',
        width:'100%',
        justifyContent:'start',
        padding:'0px 22px',
        gap:'14px',
        textDecoration:'none',
        fontsize:'14px',
        fontStyle:'normal',
        fontWeight:600,
        '&:hover': {
            color:'#FFFFFF',
            background: selected ? 'linear-gradient(90deg, rgba(178, 42, 40, 0.79) 0%, rgba(178, 42, 40, 0.00) 100%)' : 'linear-gradient(90deg, #3A3E53 0%, rgba(58, 62, 83, 0.00) 100%)',
        },
    });

    const itemArr = [
        // gameIcon1,
        gameIcon2,
    ];
    const iconArr = [
        // icon1,
        icon2,
    ];
    const titleArr = [
        // 'Heads&Tails',
        // 'Dice',
        'Plinko',
    ];
    const gameUrlArr = [
        // 'https://www.ttbetgame.com/heads-and-tails/',
        // 'self-dice',
        'https://www.ttbetgame.com/plinko/',
    ];

    const handleGameClick = (index) => {
        // if (index === 0){
        //     navigate(RoutePath.HtmlGames,
        //         { 
        //             state: { 
        //                 fromPage:RoutePath.home,
        //                 gameIcon:iconArr[index],
        //                 gameName:titleArr[index],
        //                 gameUrl:gameUrlArr[index]
        //             }
        //         }
        //         );
        // }else{
            navigate(RoutePath.game,
                { 
                    state: { 
                        category:inHouseGame,
                        fromPage:RoutePath.home,
                        gameIcon:iconArr[index],
                        gameName:titleArr[index],
                        isCloseFreePlayMode:true,
                        gameUrl:gameUrlArr[index]
                    }
                }
            );    
            dispatch(gameRefresh({currentGameUrl:gameUrlArr[index]}));
        // }
    };

    if(!screenMD && isInHouseGame) {
        return (
            <React.Fragment>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    gap:'8px',
                    width:'100%',
                }}>
                    <CustomButton variant='contained' disableRipple onClick={btnClick}>
                        <CardMedia
                            component="img"
                            image={ selected ? btnIconImageSelected : btnIconImage}
                            sx={{
                                width:'20px',
                                height:'20px',
                                objectFit:'contain',
                            }}
                        />
                        {btnText}
                    </CustomButton>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        padding:'0px 22px',
                        rowGap:'16px',
                        columnGap:'10px',
                    }}>
                        {itemArr.map((item,index) => (
                            <IconButton onClick={() => handleGameClick(index)} key={index} sx={{
                                width:'calc((260px - 76px) / 4)',
                                height:'calc((260px - 76px) / 4)',
                                bgcolor:'#3A3E53',
                                borderRadius:'8px',
                                padding:'0px',
                                '&:hover': {
                                    bgcolor:'#3A3E53',
                                },
                            }}>
                                <CardMedia
                                    component="img"
                                    image={item}
                                    sx={{
                                        width:'30px',
                                        height:'30px',
                                        objectFit:'contain',
                                    }}
                                />
                            </IconButton>
                        ))}
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }

    return (
        <CustomButton variant='contained' disableRipple onClick={btnClick}>
            <CardMedia
                component="img"
                image={ selected ? btnIconImageSelected : btnIconImage}
                sx={{
                    width:'20px',
                    height:'20px',
                    objectFit:'contain',
                }}
            />
            {btnText}
        </CustomButton>
    );
}

export default DrawerMenuItem;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import hallModeIcon from '../../assets/images/poker/poker-hall-mode.webp';
import mobileHallModeIcon from '../../assets/images/poker/poker-mobile-hall-mode.webp';
import PokerContentSelected from './PokerContentSelected';

const PokerContentFlagship = (props) => {
    const {
        hallMode,publicHallCasino,inThisImmediately,thisIsWant
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignContent:'center',
            alignItems:'center',
            gap:'32px',
            mt:'50px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                gap:'18px',
                mt:'18px',
            },
        }}>
            {screenMD ? '' : 
                <CardMedia
                    component="img"
                    image={hallModeIcon}
                    sx={{
                        width:'470px',
                        height:'355px',
                        objectFit:'contain',
                    }}
                />
            }
            
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:'490px',
                gap:'26px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'100%',
                    gap:'18px',
                },
            }}>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'48px',
                    fontStyle:'normal',
                    fontWeight:700,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'30px',
                    },
                }}>
                    {hallMode}
                </Typography>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'16px',
                    fontStyle:'normal',
                    fontWeight:400,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                    },
                }}>
                    {publicHallCasino}
                </Typography>
                <PokerContentSelected 
                    title={inThisImmediately}
                />
                <PokerContentSelected 
                    title={thisIsWant}
                />
            </Box>
            {screenMD ? 
                <CardMedia
                    component="img"
                    image={mobileHallModeIcon}
                    sx={{
                        width:'100%',
                        height:'calc((100vw - 36px) * 223 / 335)',
                        objectFit:'cover',
                        borderRadius:'13px',
                    }}
                /> : ''
            }
        </Grid>
    );
}

export default PokerContentFlagship;
import React from 'react';
import RoutePath from '../../tools/RoutePath';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const HeaderUnLogin = (props) => {
    const { login,signUp,fromPage,isHidden } = props;
    const navigate = useNavigate();
    const theme = useTheme();

    const handleLogin = () => {
        navigate(RoutePath.login,{state: {type:'login',fromPage:fromPage}});
    }
    const handleSignUp = () => {
        navigate(RoutePath.register,{state: {type:'register',fromPage:fromPage}});
    }

    return (
        <Box sx={{
            display:isHidden ? 'none' : 'flex',
            justifyContent:'end',
            alignItems:'center',
            gap:'20px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                gap:'8px',
            },
        }}>
            <Button variant="contained" sx={{
                bgcolor:'#3A3E53',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                gap:'0px',
                textTransform: 'none',
                height:'28px',
                borderRadius:'14px',
                padding:'0px 24px',
                color:'#FFFFFF',
                fontSize:'15px',
                fontStyle:'normal',
                fontWeight:500,
                '&:hover': {
                    backgroundColor: '#3A3E53',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                    padding:'0px 12px',
                    minWidth:'55px',
                },
            }} onClick={handleLogin}>
                {login}
            </Button>
            <Button variant="contained" sx={{
                bgcolor:'#1CA45F',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                gap:'0px',
                textTransform: 'none',
                height:'28px',
                borderRadius:'14px',
                padding:'0px 17px',
                color:'#FFFFFF',
                fontSize:'15px',
                fontStyle:'normal',
                fontWeight:500,
                '&:hover': {
                    backgroundColor: '#1CA45F',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                    padding:'0px 12px',
                    minWidth:'55px',
                },
            }} onClick={handleSignUp}>
                {signUp}
            </Button>
        </Box>
    );
}

export default HeaderUnLogin;

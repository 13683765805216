import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import totalBetsIcon from '../../assets/images/profile/profile-total-bets.png';
import totalWageredIcon from '../../assets/images/profile/profile-total-wagered.png';
import profitIcon from '../../assets/images/profile/profile-total-profit.png';
import { useSelector } from 'react-redux';

const ProfileContentStatistics = (props) => {
    const { totalBets,totalWagered,profit,isShowPrivacy } = props;
    const theme = useTheme();

    const totalBetCount = useSelector((state) => state.user.user.total_bet_count);
    const totalBetAmount = useSelector((state) => state.user.user.total_bet_amount);
    const totalWinAmount = useSelector((state) => state.user.user.total_win_amount);

    const totalBetsValue = isShowPrivacy ? totalBetCount.toString() : '****';
    const totalWageredValue = isShowPrivacy ? totalBetAmount.toString() : '*****.*****';
    const profitValue = isShowPrivacy ? totalWinAmount.toString() : '*****.*****';

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            mt:'18px',
            rowGap:'18px',
            columnGap:'18px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                justifyContent:'flex-start',
                mt:'30px',
                rowGap:'12px',
                columnGap:'18px',
                padding:'0px 18px',
            },
        }}>
            <Box sx={{
                bgcolor:'#1D1D28',
                borderRadius:'22px',
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'13px',
                padding:'16px 18px',
                width:'285px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    borderRadius:'8px',
                    gap:'8px',
                    padding:'9px',
                    width:'calc((100vw - 90px)/2)',
                },
            }}>
                <CardMedia
                    component="img"
                    image={totalBetsIcon}
                    sx={{
                        width:'36px',
                        height:'27px',
                        objectFit:'contain',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'28px',
                            height:'21px',
                        },
                    }}
                />
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    gap:'5px',
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'10px',
                        },
                    }}>
                        {totalBets}
                    </Typography>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'14px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'12px',
                        },
                    }}>
                        {totalBetsValue}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                bgcolor:'#1D1D28',
                borderRadius:'22px',
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'13px',
                padding:'16px 18px',
                width:'285px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    borderRadius:'8px',
                    gap:'8px',
                    padding:'9px',
                    width:'calc((100vw - 90px)/2)',
                },
            }}>
                <CardMedia
                    component="img"
                    image={totalWageredIcon}
                    sx={{
                        width:'36px',
                        height:'36px',
                        objectFit:'contain',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'28px',
                            height:'28px',
                        },
                    }}
                />
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    gap:'5px',
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'10px',
                        },
                    }}>
                        {totalWagered}
                    </Typography>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'14px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'12px',
                        },
                    }}>
                        <span style={{color:'#FFD233'}}>{'USDT '}</span>
                        {totalWageredValue.split('.').length > 1 ? totalWageredValue.split('.')[0] : totalWageredValue}
                        {totalWageredValue.split('.').length > 1 ? '.' : ''}
                        <span style={{color:'#6D728D'}}>{totalWageredValue.split('.').length > 1 ? totalWageredValue.split('.')[1] : ''}</span>
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                bgcolor:'#1D1D28',
                borderRadius:'22px',
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'13px',
                padding:'16px 18px',
                width:'285px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    borderRadius:'8px',
                    gap:'8px',
                    padding:'9px',
                    width:'calc((100vw - 90px)/2)',
                },
            }}>
                <CardMedia
                    component="img"
                    image={profitIcon}
                    sx={{
                        width:'36px',
                        height:'36px',
                        objectFit:'contain',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'28px',
                            height:'28px',
                        },
                    }}
                />
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    gap:'5px',
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'10px',
                        },
                    }}>
                        {profit}
                    </Typography>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'14px',
                        fontStyle:'normal',
                        fontWeight:400,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'12px',
                        },
                    }}>
                        <span style={{color:'#FFD233'}}>{'USDT '}</span>
                        {profitValue.split('.').length > 1 ? profitValue.split('.')[0] : profitValue}
                        {profitValue.split('.').length > 1 ? '.' : ''}
                        <span style={{color:'#6D728D'}}>{profitValue.split('.').length > 1 ? profitValue.split('.')[1] : ''}</span>
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );
}

export default ProfileContentStatistics;
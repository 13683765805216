import React from 'react';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import footerLogo from '../../assets/images/footer/footer-logo.png';
import footerCasinos from '../../assets/images/footer/footer-casinos.png';
import footerPrivacySecurity from '../../assets/images/footer/footer-privacy-security.png';
import footerAge18 from '../../assets/images/footer/footer-age-18.png';

const FooterContentLeft = (props) => {
    const { weAtCasinos, privacyPolicy, age18, ttbetIsMind } = props;
    const imageArr = [footerCasinos,footerPrivacySecurity,footerAge18];
    const titleArr1 = [weAtCasinos,privacyPolicy,age18];

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            gap:'16px',
            maxWidth:'508px',
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
            }}>
                <CardMedia
                    component="img"
                    image={ footerLogo }
                    sx={{
                        width:'150px',
                        height:'48px',
                    }}
                />
                <Box sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    gap:'40px',
                }}>
                    {imageArr.map((item,index) => (
                        <Box sx={{
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            gap:'6px',
                        }} key={index}>
                            <CardMedia
                                component="img"
                                image={ item }
                                sx={{
                                    width:'12px',
                                    height:'17px',
                                    objectFit:'contain'
                                }}
                            />
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'12px',
                                fontStyle:'normal',
                                fontWeight:400,
                            }}>
                                {titleArr1[index]}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'12px',
                fontStyle:'normal',
                fontWeight:400,
            }}>
                {ttbetIsMind}
            </Typography>
        </Box>
    );
}

export default FooterContentLeft;
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import { styled, alpha } from '@mui/material/styles';
import Menu, { menuClasses } from '@mui/material/Menu';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { popoverClasses } from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import arrowRightIcon from '../../assets/images/drawer/drawer-arrow_right.png';
import { useDispatch, useSelector } from 'react-redux';
import { slotsRefreshCategory } from '../../features/slotsSlice';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
      padding:'0px 0px 18px 0px',
      marginTop: 10,
      bgcolor:'#2A2A39',
      borderRadius: 18,
      minWidth:'136px',
      color: '#FFFFFF',
      [`& .${menuClasses.list}`]: {
        padding: '0px',
      },
      [`& .${menuItemClasses.root}`]: {
        '&:active': {
          backgroundColor: alpha(
            '#000000',
            0.8,
          ),
        },
      },
    },
    [`& .${popoverClasses.paper}`]: {
        backgroundColor: '#2A2A39',
    }
}));


const SlotsContentMainTitle = (props) => {
    const {
        menuOnClick,
    } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        slots: t("slots"),
        all: t("all"),
    };

    const chooseCategory = useSelector((state) => state.slots.currentSlotsCategory);

    const menuTitleArr = [textData.all,'PragmaticPlay','PGSoft',];
    const [ currentCategory,setCurrentCategory ] = useState(menuTitleArr[chooseCategory]);

    const handleCategoryClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // menu
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (index) => {
        handleClose();
        setCurrentCategory(menuTitleArr[index]);
        dispatch(slotsRefreshCategory({currentSlotsCategory:index}))
        menuOnClick && menuOnClick(index === 0 ? '' : menuTitleArr[index]);
    }

    return (
        <React.Fragment>
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                gap:'18px',
            }}>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'28px',
                    fontStyle:'normal',
                    fontWeight:500,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'18px',
                    },
                }}>
                    {textData.slots}
                </Typography>
                <Button variant='contained' sx={{
                    bgcolor:'#2A2A39',
                    borderRadius:'18px',
                    height:'46px',
                    color:'#ACB3D7',
                    fontSize:'18px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textTransform:'none',
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    gap:'13px',
                    minWidth:'104px',
                    padding:'0px 18px',
                    '&:hover': {
                        bgcolor:'#2A2A39',
                    }
                }} onClick={handleCategoryClick}>
                    {currentCategory}
                    <CardMedia
                        component="img"
                        image={arrowRightIcon}
                        sx={{
                            width:'8px',
                            height:'18px',
                            objectFit:'contain',
                        }}
                    />
                </Button>
            </Grid>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuTitleArr.map((item,index) => (
                    <MenuItem onClick={() => handleMenuClick(index)} disableRipple key={index} sx={{
                        display:'flex',
                        justifyContent:'start',
                        alignItems:'center',
                        mt:'18px',
                        pl:'18px',
                        pr:'18px',
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400
                    }}>
                        {item}
                    </MenuItem>
                ))}
            </StyledMenu>
        </React.Fragment>
    );
}

export default SlotsContentMainTitle;
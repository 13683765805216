import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    referralInfo: {
        total_commission:[],
        yesterday_commission:[],
        last_7_day_commission:[],
        last_30_day_commission:[],
        subordinate_cnt:0,
        yesterday_active:'0.00',
        last_7_day_active_avg:'0.00',
    },
}

export const referralSlice = createSlice({
    name: 'referral',
    initialState,
    reducers: {
        userReferralInfoRefresh(state,action) {
            state.referralInfo = action.payload.referralInfo;
            console.log('userReferralInfoRefresh state:',JSON.stringify(state));
        },
    }
});

export const { userReferralInfoRefresh } = referralSlice.actions;

export default referralSlice.reducer;
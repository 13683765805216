import React, { useEffect, useRef, useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Cookies from 'js-cookie';
import RoutePath from "../../tools/RoutePath";
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Button, { buttonClasses } from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import googleIcon from '../../assets/images/login-and-register/login-and-register-google.png';
import telegramIcon from '../../assets/images/login-and-register/login-and-register-telegram.png';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import LoginAndRegisterContentRightHeader from "./LoginAndRegisterContentRightHeader";
import LoginAndRegisterContentEmailInput from "./LoginAndRegisterContentEmailInput";
import LoginAndRegisterContentPasswordInput from "./LoginAndRegisterContentPasswordInput";
import LoginAndRegisterContentReferralCodeInput from "./LoginAndRegisterContentReferralCodeInput";
import LoginAndRegisterContentPrivacyPolicy from "./LoginAndRegisterContentPrivacyPolicy";
import LoginAndRegisterContentRightForget from "./LoginAndRegisterContentRightForget";
import LoginAndRegisterContentRightForgetAlert from "./LoginAndRegisterContentRightForgetAlert";
import LoginAndRegisterContentVerifyCodeInput from "./LoginAndRegisterContentVerifyCodeInput";
import TTAlert from '../common/TTAlert';
import TTLoading from "../common/TTLoading";
import { useDispatch, useSelector } from "react-redux";
import { userLogin, userLogout } from "../../features/userSlice";
import CryptoJS from 'crypto-js';
import { loginService, registerEmailSendCodeService, registerService, registerForceInputInviteCodeService, loginWithTelegramService } from "../../network/service/user";
import { getCookieEmail, setCookieAccessToken, setCookieEmail, setCookieUserId, setCookieUsername } from "../../tools/CommonTool";
import { fpSignUpStartEvent, fpSignUpSuccessEvent, fpTelegramSignUpSuccessEvent } from "../../tools/FacebookPixelEvent";
import serverConfig from "../../network/serverConfig";

const LoginAndRegisterContentRight = (props) => {
    const { 
        login,signUp,exampleEmail,password,referralCode,optional,iamThe,privacyPolicy,and,
        termsOfService,letUsGo,joinUs,or,loginWithGmail,loginWithTelegram,emailIsRequired,passwordIsRequired,forgetPassword,
        verification,verificationCodeLater,sendCode,sentSuccessfully,pleaseInputCode,referralIsRequired,failed,emailFormatIncorrect,charactersInNumbers,theCurrentDevices,
        type,fromPage,isLogout,
    } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loggedEmail = getCookieEmail();
    const [ currentMode,setCurrentMode ] = useState(type);
    const [ forceInputInviteCode,setForceInputInviteCode ] = useState(false);
    const [ inputEmail,setInputEmail ] = useState({loginEmail:loggedEmail.length > 0 ? loggedEmail : '',registerEmail:''});
    const [ inputPassword,setInputPassword ] = useState({loginPassword:'',registerPassword:''});
    const [ inputVerifyCode,setInputVerifyCode ] = useState('');
    const [ inputReferralCode,setInputReferralCode ] = useState('');
    const [ agree, setAgree ] = useState(true);
    const [ alertType,setAlertType ] = useState('info');
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const [ autoHideAlert,setAutoHideAlert ] = useState(true);
    const [ forgetOpen, setForgetOpen ] = useState(false);
    const [ openLoading,setOpenLoading ] = useState(false);

    const isForceLogin = useSelector((state) => state.user.forceLogin);
    useEffect(() => {
        if(isForceLogin) {
            setAlertType('error');
            setAlertMessage(theCurrentDevices);
            setAutoHideAlert(false);
            setOpenAlert(true);
        }
    },[]);

    // 输入提示
    const [ openInputTip, setOpenInputTip ] = useState(false);
    const [ inputTip,setInputTip ] = useState(
        {
            loginEmailOpen:false,
            loginEmailTip:'',
            loginPWDOpen:false,
            loginPWDTip:'',
            registerEmailOpen:false,
            registerEmailTip:'',
            registerPWDOpen:false,
            registerPWDTip:'',
            registerVerifyCodeOpen:false,
            registerVerifyCodeTip:'',
            registerReferralCodeOpen:false,
            registerReferralCodeTip:'',
        }
    );

    const handleLoginClick = () => {
        setCurrentMode('login');
    };
    const handleRegisterClick = () => {
        setCurrentMode('register');
    };
    const handleEmailInputChanged = (e) => {
        if(currentMode === 'login') {
            setInputEmail({
                ...inputEmail,
                loginEmail: e.target.value,
            });
            setInputTip({
                ...inputTip,
                loginEmailOpen:false,
                loginEmailTip:''
            })
        }else {
            setInputEmail({
                ...inputEmail,
                registerEmail: e.target.value,
            });
            setInputTip({
                ...inputTip,
                registerEmailOpen:false,
                registerEmailTip:''
            })
        }
     };
    const handlePasswordInputChanged = (e) => {
        if(currentMode === 'login') {
            setInputPassword({
                ...inputPassword,
                loginPassword: e.target.value,
            });
            setInputTip({
                ...inputTip,
                loginPWDOpen:false,
                loginPWDTip:''
            })
        }else {
            setInputPassword({
                ...inputPassword,
                registerPassword: e.target.value,
            });
            setInputTip({
                ...inputTip,
                registerPWDOpen:false,
                registerPWDTip:''
            })
        }
    };
    const handleVerifyCodeInputChanged = (e) => {
        setInputVerifyCode(e.target.value);
        setInputTip({
            ...inputTip,
            registerVerifyCodeOpen:false,
            registerVerifyCodeTip:''
        })
    };
    const handleReferralCodeInputChanged = (e) => {
        setInputReferralCode(e.target.value);
        setInputTip({
            ...inputTip,
            registerReferralCodeOpen:false,
            registerReferralCodeTip:''
        })
    };
    const handleForgetPassword = () => {
        setForgetOpen(true);
    };
    const handleAgreeProtocol = () => {
        setAgree(!agree);
    };

    const handlePasswordIsVerify = (password) => {
        return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,12}$/.test(password);
    };

    const handleCommit = async () => {
        if(currentMode === 'login') {    
            let isVerified = true;
            let tip = {};

            if(inputEmail.loginEmail === undefined || 
                inputEmail.loginEmail.trim() === '') {
                // setAlertType('info');
                // setAlertMessage(emailIsRequired);
                // setOpenAlert(true);
                tip.loginEmailOpen = true;
                tip.loginEmailTip = emailIsRequired;
                isVerified = false;
            }
            if(!inputEmail.loginEmail.includes('@')) {
                tip.loginEmailOpen = true;
                tip.loginEmailTip = emailFormatIncorrect;
                isVerified = false;
            }
            if(inputPassword.loginPassword  === undefined || 
                inputPassword.loginPassword.trim() === '') {
                // setAlertType('info');
                // setAlertMessage(passwordIsRequired);
                // setOpenAlert(true);
                tip.loginPWDOpen = true;
                tip.loginPWDTip = passwordIsRequired;
                isVerified = false;
            }
            if(!handlePasswordIsVerify(inputPassword.loginPassword)) {
                tip.loginPWDOpen = true;
                tip.loginPWDTip = charactersInNumbers;
                isVerified = false;
            }

            if(!isVerified) {
                setInputTip({
                    ...inputTip,
                    loginEmailOpen:tip.loginEmailOpen,
                    loginEmailTip:tip.loginEmailTip,
                    loginPWDOpen:tip.loginPWDOpen,
                    loginPWDTip:tip.loginPWDTip
                });
                return;
            }
            
            const saltPWD = CryptoJS.MD5(inputPassword.loginPassword + '{PWD_Salt_CC_Poker}').toString();
            setOpenLoading(true);
            try {
                let params = {
                    email:inputEmail.loginEmail,
                    password:saltPWD
                };
                const response = await loginService(params);
                setOpenLoading(false);
                setCookieAccessToken(response.data.access_token);
                setCookieUserId(response.data.user.pk);
                setCookieUsername(response.data.user.username);
                setCookieEmail(inputEmail.loginEmail);
                localStorage.removeItem('TTInviteCode');
                dispatch(userLogin(response.data));
                navigate(RoutePath.home);
            } catch (error) {
                setOpenLoading(false);
                const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
                let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
                setAlertType('error');
                setAlertMessage(errorMsg);
                setOpenAlert(true);
            }
        }else {
            let isVerified = true;
            let tip = {};

            if(inputEmail.registerEmail === undefined || 
                inputEmail.registerEmail.trim() === '') {
                // setAlertType('info');
                // setAlertMessage(emailIsRequired);
                // setOpenAlert(true);
                tip.registerEmailOpen = true;
                tip.registerEmailTip = emailIsRequired;
                isVerified = false;
            }
            if(!inputEmail.registerEmail.includes('@')) {
                tip.registerEmailOpen = true;
                tip.registerEmailTip = emailFormatIncorrect;
                isVerified = false;
            }
            if(inputPassword.registerPassword  === undefined || 
                inputPassword.registerPassword.trim() === '') {
                // setAlertType('info');
                // setAlertMessage(passwordIsRequired);
                // setOpenAlert(true);
                tip.registerPWDOpen = true;
                tip.registerPWDTip = passwordIsRequired;
                isVerified = false;
            }
            if(!handlePasswordIsVerify(inputPassword.registerPassword)) {
                tip.registerPWDOpen = true;
                tip.registerPWDTip = charactersInNumbers;
                isVerified = false;
            }
            if(inputVerifyCode  === undefined || 
                inputVerifyCode.trim() === '') {
                // setAlertType('info');
                // setAlertMessage(pleaseInputCode);
                // setOpenAlert(true);
                tip.registerVerifyCodeOpen = true;
                tip.registerVerifyCodeTip = pleaseInputCode;
                isVerified = false;
            }
            if(forceInputInviteCode && inputReferralCode.length <= 0) {
                // setAlertType('info');
                // setAlertMessage(referralIsRequired);
                // setOpenAlert(true);
                tip.registerReferralCodeOpen = true;
                tip.registerReferralCodeTip = referralIsRequired;
                isVerified = false;
            }

            if(!isVerified) {
                setInputTip({
                    ...inputTip,
                    registerEmailOpen:tip.registerEmailOpen,
                    registerEmailTip:tip.registerEmailTip,
                    registerPWDOpen:tip.registerPWDOpen,
                    registerPWDTip:tip.registerPWDTip,
                    registerVerifyCodeOpen:tip.registerVerifyCodeOpen,
                    registerVerifyCodeTip:tip.registerVerifyCodeTip,
                    registerReferralCodeOpen:tip.registerReferralCodeOpen,
                    registerReferralCodeTip:tip.registerReferralCodeTip
                });
                return;
            }

            const saltPWD = CryptoJS.MD5(inputPassword.registerPassword + '{PWD_Salt_CC_Poker}').toString();
            setOpenLoading(true);
            // 记录facebook pixel event
            fpSignUpStartEvent();
            try {
                let params = {
                    email:inputEmail.registerEmail,
                    password1:saltPWD,
                    password2:saltPWD,
                    key:inputVerifyCode,
                    from_website:true,
                };
                if(inputReferralCode.length > 0) {
                    params.invite_code = inputReferralCode;
                }
                const response = await registerService(params);
                setOpenLoading(false);
                // 记录facebook pixel event
                fpSignUpSuccessEvent({userId:response.data.user.pk,nickname:response.data.user.username});
                setCookieAccessToken(response.data.access_token);
                setCookieUserId(response.data.user.pk);
                setCookieUsername(response.data.user.username);
                setCookieEmail(inputEmail.registerEmail);
                localStorage.removeItem('TTInviteCode');
                dispatch(userLogin(response.data));
                navigate(RoutePath.home);
            } catch (error) {
                setOpenLoading(false);
                console.log(error);
                const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
                let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
                setAlertType('error');
                setAlertMessage(errorMsg);
                setOpenAlert(true);
            }
        }
    };
    const handleGoogleLogin = () => {
        console.log('google login');
    };
    const handleTelegramLogin = () => {
        // if(window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
        //     // 从查询字符串中获取用户部分
        //     const userQueryString = new URLSearchParams(window.Telegram.WebApp.initData).get('user');
        //     const queryId = new URLSearchParams(window.Telegram.WebApp.initData).get('query_id');
        //     const authDate = new URLSearchParams(window.Telegram.WebApp.initData).get('auth_date');
        //     const hash = new URLSearchParams(window.Telegram.WebApp.initData).get('hash');
        //     // 解码用户部分
        //     const decodedUserString = decodeURIComponent(userQueryString);
        //     // 解析为对象
        //     const userObject = JSON.parse(decodedUserString);

        //     const params = {
        //         query_id: queryId,
        //         data: {
        //             id: userObject.id,
        //             first_name: userObject.first_name,
        //             last_name: userObject.last_name,
        //             username: userObject.username,
        //             language_code: userObject.language_code,
        //             photo_url: '',
        //             auth_date: authDate,
        //             hash: hash,
        //         },
        //     }
        //     handleTelegramService(params);
        //     return;
        // }

        window.Telegram.Login.auth(
            { bot_id: serverConfig.botId, request_access: "write", embed: 1 },
            (data) => {
                if(!data) {
                    setAlertType('error');
                    setAlertMessage(failed);
                    setOpenAlert(true);
                }else {
                    let params = {
                        data:data
                    }
                    if(inputReferralCode.length > 0) {
                        params.invite_code = inputReferralCode;
                    }
                    handleTelegramService(params);
                }
        });
    };
    const handleTelegramService = async (param) => {
        setOpenLoading(true);
        try {
            const response = await loginWithTelegramService(param);
            setOpenLoading(false);
            setCookieAccessToken(response.data.access_token);
            setCookieUserId(response.data.user.pk);
            setCookieUsername(response.data.user.username);
            localStorage.removeItem('TTInviteCode');
            dispatch(userLogin(response.data));
            navigate(RoutePath.home);
        } catch (error) {
            setOpenLoading(false);
            console.log(error);
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setAlertType('error');
            setAlertMessage(errorMsg);
            setOpenAlert(true);
        }
    };
    const handleClose = () => {
        if(isLogout) {
            dispatch(userLogout());
        }
        navigate(isLogout ? RoutePath.home : fromPage);
    }
    const handleAlertClose = (event, reason) => {
        setAutoHideAlert(true);
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };
    // forget alert
    const handleForgetAlertClose = () => {
        setForgetOpen(false);
    };

    // send code
    const timeValue = 60;
    const [ countdownTime,setCountdownTime ] = useState(timeValue);
    const intervalId = useRef();
    const handleSendCodeClick = () => {
        let isVerified = true;
        let tip = {};

        if(inputEmail.registerEmail === undefined || 
            inputEmail.registerEmail.trim() === '') {
            // setAlertType('info');
            // setAlertMessage(emailIsRequired);
            // setOpenAlert(true);
            tip.registerEmailOpen = true;
            tip.registerEmailTip = emailIsRequired;
            isVerified = false;
        }else if(!inputEmail.registerEmail.includes('@')) {
            tip.registerEmailOpen = true;
            tip.registerEmailTip = emailFormatIncorrect;
            isVerified = false;
        }
        if(inputPassword.registerPassword  === undefined || 
            inputPassword.registerPassword.trim() === '') {
            // setAlertType('info');
            // setAlertMessage(passwordIsRequired);
            // setOpenAlert(true);
            tip.registerPWDOpen = true;
            tip.registerPWDTip = passwordIsRequired;
            isVerified = false;
        }
        if(!handlePasswordIsVerify(inputPassword.registerPassword)) {
            tip.registerPWDOpen = true;
            tip.registerPWDTip = charactersInNumbers;
            isVerified = false;
        }

        if(!isVerified) {
            setInputTip({
                ...inputTip,
                registerEmailOpen:tip.registerEmailOpen,
                registerEmailTip:tip.registerEmailTip,
                registerPWDOpen:tip.registerPWDOpen,
                registerPWDTip:tip.registerPWDTip,
            });
            return;
        }

        if(countdownTime < timeValue) {
            setAlertType('info');
            setAlertMessage(verificationCodeLater);
            setOpenAlert(true);
            return;
        }

        setCountdownTime(59);

        handleSendEmailVerifyCode();
    };
    const handleSendEmailVerifyCode = async () => {
        try {
            let params = {
                email:inputEmail.registerEmail,
            };
            await registerEmailSendCodeService(params);
            setAlertType('success');
            setAlertMessage(sentSuccessfully);
            setOpenAlert(true);

            intervalId.current = setInterval(() => {
                setCountdownTime((prevCounter) => prevCounter - 1);
            }, 1000);
        } catch (error) {
            setCountdownTime(timeValue);

            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setAlertType('error');
            setAlertMessage(errorMsg);
            setOpenAlert(true);
        }
    };
    useEffect(() => {
        if(countdownTime === 0) {
            clearInterval(intervalId.current);
            setCountdownTime(timeValue);
        }
    }, [countdownTime]);
    useEffect(() => {
        // 暂时去掉
        // handleRegisterForceInputInviteCodeService();
        const inviteCode = localStorage.getItem('TTInviteCode');
        if(inviteCode !== undefined && inviteCode !== null && inviteCode.length > 0) {
            setInputReferralCode(inviteCode);
        }

        return () => {
            clearInterval(intervalId.current);
        };
    }, []);
    const handleRegisterForceInputInviteCodeService = async () => {
        try {
            const response = await registerForceInputInviteCodeService();
            setForceInputInviteCode(response.data.data.force_invite_code);
        } catch (error) {
            console.log(error.response);
        }
    };

    const handleButtonDisableStatus = () => {
        if(currentMode === 'login') {
            if((inputEmail.loginEmail  !== undefined && 
                inputEmail.loginEmail.trim() !== '') &&
                (inputPassword.loginPassword  !== undefined && 
                inputPassword.loginPassword.trim() !== '')) {
                return false;
            }else {
                return true;
            }
        }else {
            if(agree && 
                (inputEmail.registerEmail  !== undefined && 
                inputEmail.registerEmail.trim() !== '') &&
                (inputPassword.registerPassword  !== undefined && 
                inputPassword.registerPassword.trim() !== '') &&
                (inputVerifyCode  !== undefined && 
                inputVerifyCode.trim() !== '')) {
                if(forceInputInviteCode && inputReferralCode.length <= 0) {
                    return true;
                }else {
                    return false
                }
            }else {
                return true;
            }
        }
    };

    return (
        <Box sx={{
            bgcolor:'#1D1D28',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            position:'relative',
            overflow:'hidden',
            width:'603px',
            height:'100%',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                bgcolor:'transparent',
                width:'100%',
                overflow:'auto',
            },
        }}>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <LoginAndRegisterContentRightForgetAlert 
                isOpen={forgetOpen}
                email={inputEmail.loginEmail}
                closeCallback={handleForgetAlertClose}
            />
            <TTAlert 
                type={alertType}
                noAutoHide={!autoHideAlert}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
                mt='70px'
            />
            <Button variant='contained' sx={{
                position:'absolute',
                minWidth:'40px',
                minHeight:'40px',
                width:'40px',
                height:'40px',
                right:'24px',
                top:'32px',
                bgcolor:'#323043',
                borderRadius:'8px',
                display:'flex',
                justifyContent:'center',
                padding:'0px',
                alignItems:'center',
                '&:hover': {
                    backgroundColor: '#3A3E53',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    top:'10px',
                    right:'18px',
                },
            }} onClick={handleClose}>
                <CardMedia
                    component='img'
                    image={closeIcon}
                    sx={{
                        objectFit:'contain',
                        width:'24px',
                        height:'24px',
                    }}
                />
            </Button>
            <LoginAndRegisterContentRightHeader 
                login={login}
                signUp={signUp}
                currentMode={currentMode}
                loginOnClick={handleLoginClick}
                registerOnClick={handleRegisterClick}
            />
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                width:'429px',
                position:'absolute',
                top:'150px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    top:'116px',
                    padding:'0px 18px',
                    width:'calc(100% - 36px)',
                    justifyContent:'flex-start',
                },
            }} component="form">
                <Box sx={{
                    mt:'36px',
                    width:'100%',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'0px',
                    },
                }}>
                    <LoginAndRegisterContentEmailInput 
                        placeholder={exampleEmail}
                        defaultText={currentMode === 'login' ? inputEmail.loginEmail : inputEmail.registerEmail}
                        isAutoFocus={true}
                        isInputTip={
                            currentMode === 'login' ? inputTip.loginEmailOpen : inputTip.registerEmailOpen
                        }
                        tipText={
                            currentMode === 'login' ? inputTip.loginEmailTip : inputTip.registerEmailTip
                        }
                        handleInputChanged={handleEmailInputChanged}
                    />
                </Box>
                <Box sx={{
                    mt:'18px',
                    width:'100%',
                }}>
                    <LoginAndRegisterContentPasswordInput 
                        placeholder={password}
                        defaultText={currentMode === 'login' ? inputPassword.loginPassword : inputPassword.registerPassword}
                        isInputTip={
                            currentMode === 'login' ? inputTip.loginPWDOpen : inputTip.registerPWDOpen
                        }
                        tipText={
                            currentMode === 'login' ? inputTip.loginPWDTip : inputTip.registerPWDTip
                        }
                        handleInputChanged={handlePasswordInputChanged}
                    />
                </Box>
                <LoginAndRegisterContentRightForget 
                    forgetPassword={forgetPassword}
                    isHidden={currentMode === 'login' ? false : true}
                    forgetOnClick={handleForgetPassword}
                />
                <Box sx={{
                    mt:'18px',
                    width:'100%',
                    display:currentMode === 'login' ? 'none' : 'block',
                }}>
                    <LoginAndRegisterContentVerifyCodeInput 
                        placeholder={verification}
                        isRegister={true}
                        isInputTip={
                            inputTip.registerVerifyCodeOpen
                        }
                        tipText={
                            inputTip.registerVerifyCodeTip
                        }
                        handleInputChanged={handleVerifyCodeInputChanged}
                    />
                    <Button variant='text' sx={{
                        bgcolor:'#1CA45F',
                        borderRadius:'19px',
                        height:'38px',
                        padding:'0px 20px',
                        textTransform:'none',
                        color:'#FFFFFF',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                        whiteSpace: 'nowrap',
                        float:'right',
                        bottom:'46px',
                        right:'10px',
                        '&:hover': {
                            bgcolor:'#1CA45F',
                        },
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            height:'36px',
                            borderRadius:'18px',
                            fontSize:'12px',
                        },
                    }} onClick={handleSendCodeClick}>
                        {countdownTime < timeValue ? countdownTime : sendCode}
                    </Button>
                </Box>
                <FormControl sx={{
                    mt:'-20px',
                    display:currentMode === 'login' ? 'none' : 'block',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                        mt:'-18px',
                    },
                }}>
                    <LoginAndRegisterContentReferralCodeInput 
                        placeholder={referralCode}
                        defaultText={inputReferralCode}
                        optional={`(${optional})`}
                        forceInputInviteCode={forceInputInviteCode}
                        isInputTip={
                            inputTip.registerReferralCodeOpen
                        }
                        tipText={
                            inputTip.registerReferralCodeTip
                        }
                        handleInputChanged={handleReferralCodeInputChanged}
                    />
                </FormControl>
                <LoginAndRegisterContentPrivacyPolicy 
                    iamThe={iamThe}
                    privacyPolicy={privacyPolicy}
                    and={and}
                    termsOfService={termsOfService}
                    isAgree={agree}
                    isShow={currentMode === 'login' ? false : true}
                    agreeOnClick={handleAgreeProtocol}
                />
                <Button variant='contained' sx={{
                    bgcolor:'#1CA45F',
                    borderRadius:'25px',
                    width:'100%',
                    height:'50px',
                    color:'#FFFFFF',
                    fontSize:'17px',
                    fontStyle:'normal',
                    fontWeight:500,
                    textTransform: 'none',
                    mt:'18px',
                    '&:hover': {
                        backgroundColor: '#1CA45F',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#2A2A39',
                        color:'#6D728D',
                    }
                }} onClick={handleCommit} disabled={handleButtonDisableStatus()}>
                    {currentMode === 'login' ? letUsGo : joinUs}
                </Button>
                <Box sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'50px',
                    mt:'18px',
                }}>
                    <Box sx={{
                        bgcolor:'#3A3E53',
                        width:'140px',
                        height:'1.5px',
                    }} />
                    <Typography sx={{
                        color:'#6D728D',
                        fontSize:'14px',
                        fontStyle:'normal',
                        fontWeight:400,
                        textAlign:'center',
                    }}>
                        {or}
                    </Typography>
                    <Box sx={{
                        bgcolor:'#3A3E53',
                        width:'140px',
                        height:'1.5px',
                    }} />
                </Box>
                {/* <Button variant='contained' sx={{
                    bgcolor:'#3A3E53',
                    borderRadius:'25px',
                    width:'100%',
                    height:'50px',
                    color:'#FFFFFF',
                    fontSize:'17px',
                    fontStyle:'normal',
                    fontWeight:500,
                    textTransform: 'none',
                    mt:'18px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'7px',
                    '&:hover': {
                        backgroundColor: '#3A3E53',
                    },
                }} onClick={handleGoogleLogin}>
                    <CardMedia
                        component='img'
                        image={googleIcon}
                        sx={{
                            width:'17px',
                            height:'17px',
                        }}
                    />
                    {loginWithGmail}
                </Button> */}
                <Button variant='contained' sx={{
                    bgcolor:'#3A3E53',
                    borderRadius:'25px',
                    width:'100%',
                    height:'50px',
                    color:'#FFFFFF',
                    fontSize:'17px',
                    fontStyle:'normal',
                    fontWeight:500,
                    textTransform: 'none',
                    mt:'18px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'7px',
                    '&:hover': {
                        backgroundColor: '#3A3E53',
                    },
                }} onClick={handleTelegramLogin}>
                    <CardMedia
                        component='img'
                        image={telegramIcon}
                        sx={{
                            width:'17px',
                            height:'17px',
                        }}
                    />
                    {loginWithTelegram}
                </Button>
            </Box>
        </Box>
    );
}

export default LoginAndRegisterContentRight;
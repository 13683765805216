import axios from "axios";
import serverConfig from './serverConfig';
import i18next from "i18next";
import { getCookieAccessToken } from "../tools/CommonTool";
import { getFingerPrintInfo } from "../tools/CommonTool";

const httpManager = axios.create({
    baseURL: serverConfig.baseURL,
    timeout: 10000,
    headers: {'Content-Type': 'application/json; charset=UTF-8'},
    withCredentials: serverConfig.withCredentials,
});

// 创建请求拦截
httpManager.interceptors.request.use(
    (config) => {
        const accessToken = getCookieAccessToken();
        if (serverConfig.useTokenAuthorization && (accessToken !== undefined && accessToken.length > 0)) {
            config.headers["Authorization"] = "Bearer " + accessToken; // 请求头携带 token
        }
        const fingerPrintInfo = getFingerPrintInfo();
        if(fingerPrintInfo !== undefined && fingerPrintInfo !== null) {
            config.headers["UUID"] = fingerPrintInfo; 
        }
        let language = 'EN';
        if(i18next.language.toLowerCase().includes("ko")) {
            language = 'KO';
        }else if(i18next.language.toLowerCase().includes("zh")) {
            language = 'ZH';
        }else if(i18next.language.toLowerCase().includes("pt")) {
            language = 'PT';
        }else if(i18next.language.toLowerCase().includes("es")) {
            language = 'ES';
        }
        config.headers["Accept-Language"] = language;
        return config;
    },
    (error) => {
      Promise.reject(error);
    }
);
// 创建响应拦截
httpManager.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        let message = '';
        if (error && error.response) {
            if(error.response.status === 401) {
                // ForceLogin();
            }
            // switch (error.response.status) {
            //   case 302:
            //     message = "接口重定向了！";
            //     break;
            //   case 400:
            //     message = "参数不正确！";
            //     break;
            //   case 401:
            //     message = "您未登录，或者登录已经超时，请先登录！";
            //     break;
            //   case 403:
            //     message = "您没有权限操作！";
            //     break;
            //   case 404:
            //     message = `请求地址出错: ${error.response.config.url}`;
            //     break;
            //   case 408:
            //     message = "请求超时！";
            //     break;
            //   case 409:
            //     message = "系统已存在相同数据！";
            //     break;
            //   case 500:
            //     message = "服务器内部错误！";
            //     break;
            //   case 501:
            //     message = "服务未实现！";
            //     break;
            //   case 502:
            //     message = "网关错误！";
            //     break;
            //   case 503:
            //     message = "服务不可用！";
            //     break;
            //   case 504:
            //     message = "服务暂时无法访问，请稍后再试！";
            //     break;
            //   case 505:
            //     message = "HTTP 版本不受支持！";
            //     break;
            //   default:
            //     message = "异常问题，请联系管理员！";
            //     break;
            // }
          }
        return Promise.reject(error);
    }
);

export default httpManager;
import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import WalletContentMainLeftBalance from '../WalletContentMainLeftBalance';
import WalletContentMainLeftWithdrawNetwork from './WalletContentMainLeftWithdrawNetwork';
import WalletContentMainLeftWithdrawAddress from './WalletContentMainLeftWithdrawAddress';
import WalletContentMainLeftRecord from '../WalletContentMainLeftRecord';
import WalletContentMainFAQ from "../WalletContentMainFAQ";

const WalletContentMainLeftWithdraw = (props) => {
    const {
        yourBalance,transaction,network,next,recordOf,orderNo,type,amount,status,time,withdraw,prepare,
        inProgress,success,failed,noData,withdrawAddress,withdrawAmount,withdrawableAmount,amountReceived,minimumAmount,
        fee,withdrawalAmountsFrom,insufficientBalance,pleaseEnterAddress,
        isShow
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const [ chooseChain,setChooseChain ] = useState({
        platform_name:"",
        platform:"",
        withdraw_fee:""
    });

    const chainChanged = (chain) => {
        setChooseChain(chain);
    };

    return (
        <Grid container sx={{
            display:isShow ? 'flex' : 'none',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            gap:'48px',
            width:'100%',
            mt:'48px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'18px',
                gap:'8px',
            },
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'18px',
                width:'100%',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    gap:'8px',
                },
            }}>
                <WalletContentMainLeftBalance 
                    yourBalance={yourBalance}
                    transaction={transaction}
                    type={1}
                />
                <WalletContentMainLeftWithdrawNetwork 
                    network={network}
                    chooseCallback={chainChanged}
                />
                <WalletContentMainLeftWithdrawAddress 
                    withdrawAddress={withdrawAddress}
                    withdrawAmount={withdrawAmount}
                    withdrawableAmount={withdrawableAmount}
                    amountReceived={amountReceived}
                    minimumAmount={minimumAmount}
                    fee={fee}
                    next={next}
                    chain={chooseChain}
                    token={'USDT'}
                    withdrawalAmountsFrom={withdrawalAmountsFrom}
                    insufficientBalance={insufficientBalance}
                    pleaseEnterAddress={pleaseEnterAddress}
                />
                {screenMD ? 
                    <WalletContentMainFAQ 
                        faq={'FAQ'}
                    /> : ''
                }
                <WalletContentMainLeftRecord 
                    recordOf={recordOf+' USDT'}
                    orderNo={orderNo}
                    type={type}
                    amount={amount}
                    status={status}
                    time={time}
                    withdraw={withdraw}
                    prepare={prepare}
                    inProgress={inProgress}
                    success={success}
                    failed={failed}
                    noData={noData}
                    recordType={isShow ? 'withdraw' : ''}
                />
            </Box>
        </Grid>
    );
}

export default WalletContentMainLeftWithdraw;
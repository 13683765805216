import React from 'react';
import { BrowserRouter,Route, Routes } from "react-router-dom";
import RoutePath from '../tools/RoutePath';
import Home from "../pages/Home";
import Favorite from '../pages/Favorite';
import Recent from '../pages/Recent';
import LoginAndRegister from '../pages/LoginAndRegister';
import Wallet from '../pages/Wallet'; 
import Deposit from '../pages/Deposit';
import Withdraw from '../pages/Withdraw';
import Game from '../pages/Game';
import GameMobile from '../pages/GameMobile';
import Profile from '../pages/Profile';
import Poker from '../pages/Poker';
import TokenRecord from '../pages/TokenRecord';
import FAQ from '../pages/FAQ';
import Slots from '../pages/Slots';
import HouseGames from '../pages/HouseGames';
import HelpCenter from '../pages/HelpCenter';
import Referral from '../pages/Referral';
import HelpCenterDesc from '../pages/HelpCenterDesc';
import LiveCasino from '../pages/LiveCasino';
// import SDGameDice from '../pages/SDGameDice';
import CasinoList from '../pages/CasinoList';
import Fishing from '../pages/Fishing';
// import SDGameLimbo from '../pages/SDGameLimbo';
import Search from '../pages/Search';
import Sports from '../pages/Sports';

const MainRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={RoutePath.home} element={<Home/>}/>
                <Route path={RoutePath.poker}  element={<Poker/>}/>
                <Route path={RoutePath.favorite} element={<Favorite/>}/>
                <Route path={RoutePath.recent} element={<Recent/>}/>
                <Route path={RoutePath.login} element={<LoginAndRegister/>}/>
                <Route path={RoutePath.register} element={<LoginAndRegister/>}/>
                <Route path={RoutePath.wallet} element={<Wallet/>}/>
                <Route path={RoutePath.deposit} element={<Deposit/>}/>
                <Route path={RoutePath.withdraw} element={<Withdraw/>}/>
                <Route path={RoutePath.game} element={<Game/>}/>
                <Route path={RoutePath.gameMobile} element={<GameMobile/>}/>
                <Route path={RoutePath.profile} element={<Profile/>}/>
                <Route path={RoutePath.record} element={<TokenRecord/>}/>
                <Route path={RoutePath.faq} element={<FAQ/>}/>
                <Route path={RoutePath.slots} element={<Slots/>}/>
                <Route path={RoutePath.liveCasino} element={<LiveCasino/>}/>
                <Route path={RoutePath.inHouseGames} element={<HouseGames/>}/>
                <Route path={RoutePath.helpCenter} element={<HelpCenter/>}/>
                <Route path={RoutePath.referral} element={<Referral/>}/>
                <Route path={RoutePath.helpCenterDesc} element={<HelpCenterDesc/>}/>
                {/* <Route path={RoutePath.diceGame} element={<SDGameDice/>}/> */}
                <Route path={RoutePath.popularSlots} element={<CasinoList/>}/>
                <Route path={RoutePath.newSlots} element={<CasinoList/>}/>
                <Route path={RoutePath.hotSlots} element={<CasinoList/>}/>
                <Route path={RoutePath.fishing} element={<Fishing/>}/>
                {/* <Route path={RoutePath.limboGame} element={<SDGameLimbo/>}/> */}
                <Route path={RoutePath.search} element={<Search/>}/>
                <Route path={RoutePath.sports} element={<Sports/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default MainRoutes;
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import HomeContentMain from './HomeContentMain';
import Footer from '../footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { popupConfigService } from '../../network/service/home';
import { popupShowRefresh } from '../../features/appSlice';
import TTTosPopup from '../common/TTTosPopup';

const HomeContent = (props) => {
    const theme = useTheme();
    const params = new URLSearchParams(window.location.search);
    const inviteCode = params.get('SpreadCode');
    if(inviteCode !== undefined && inviteCode !== null && inviteCode.length > 0) {
        localStorage.setItem('TTInviteCode',inviteCode);
    }

    const popupIsShowed = useSelector((state) => state.app.popupIsShowed);
    const [ showPopup,setShowPopup ] = useState(false);
    const [ popupConfig,setPopupConfig ] = useState(null);
    const dispatch = useDispatch();

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
        });

        handlePopupConfig();
    },[]);

    const handlePopupConfig = async () => {
        try {
            const response = await popupConfigService();
            if(!popupIsShowed && response.data.popup_config.is_online) {
                setPopupConfig(response.data.popup_config);
                setShowPopup(true);
                dispatch(popupShowRefresh({popupIsShowed:true}));
            }
        } catch (error) {
            console.log('handlePopupConfig error:'+error);
        }
    };
    const handlePopupCloseClick = () => {
        setShowPopup(false);
    };

    return (
        <React.Fragment>
            <TTTosPopup 
                isOpen={showPopup}
                closeCallback={handlePopupCloseClick}
                title={popupConfig !== null ? popupConfig.title : ''}
                content={popupConfig !== null ? popupConfig.content : ''}
                imageUrl={popupConfig !== null ? popupConfig.image_url : ''}
            />

            <Grid sx={{
                pl:'260px',
                pt:'64px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    pl:'0px',
                    pt:'114px',
                },
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'space-between',
                }}>
                    <HomeContentMain />
                    <Footer />
                </Box>
            </Grid>
        </React.Fragment>
    );
}

export default HomeContent;
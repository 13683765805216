export const fpSignUpStartEvent = () => {
    if(window.fbq !== undefined && window.fbq !== null) {
        window.fbq('trackCustom','SignUp-Start');
    }else {
        console.log('fbq null');
    }
}

export const fpSignUpSuccessEvent = (param) => {
    if(window.fbq !== undefined && window.fbq !== null) {
        window.fbq('trackCustom','SignUp-Success',param);
    }else {
        console.log('fbq null');
    }
}

export const fpTelegramSignUpSuccessEvent = (param) => {
    if(window.fbq !== undefined && window.fbq !== null) {
        window.fbq('trackCustom','SignUp-Telegram-Success',param);
    }else {
        console.log('fbq null');
    }
}

export const fpDepositEvent = (param) => {
    if(window.fbq !== undefined && window.fbq !== null) {
        window.fbq('trackCustom','Deposit',param);
    }else {
        console.log('fbq null');
    }
}
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import peopleIcon from '../../assets/images/referral/referral-people.png';

const ReferralContentMainContentAgentItem = (props) => {
    const {
        title,amount,
    } = props;

    return (
        <Grid container sx={{
            bgcolor:'#2A2A39',
            borderRadius:'18px',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            padding:'18px 48px',
            gap:'20px',
        }}>
            <Typography sx={{
                color:'#6D728D',
                fontSize:'18px',
                fontStyle:'normal',
                fontWeight:400,
            }}>
                {title}
            </Typography>
            <Box sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'15px',
            }}>
                <CardMedia
                    component="img"
                    image={peopleIcon}
                    sx={{
                        width:'30px',
                        height:'30px',
                        objectFit:'contain',
                    }}
                />
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'24px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textAlign:'right',
                }}>
                    {amount}
                </Typography>
            </Box>
        </Grid>
    );
}

const ReferralContentMainContentAgentItemMobile = (props) => {
    const {
        title1,amount1,title2,amount2,
    } = props;

    return (
        <Box sx={{
            bgcolor:'#2A2A39',
            borderRadius:'8px',
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            ml:'18px',
            mr:'18px',
            width:'calc(100% - 36px)',
            padding:'18px 0px',
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'22px',
                width:'calc((100% - 1px) / 2)',
            }}>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:400,
                    ml:'18px',
                    mr:'18px',
                    width:'calc(100% - 36px)',
                }}>
                    {title1}
                </Typography>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-end',
                    alignItems:'center',
                    gap:'8px',
                    mr:'33px',
                    width:'calc(100% - 33px)',
                }}>
                    <CardMedia
                        component="img"
                        image={peopleIcon}
                        sx={{
                            width:'24px',
                            height:'24px',
                            objectFit:'contain',
                        }}
                    />
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'21px',
                        fontStyle:'normal',
                        fontWeight:500,
                        textAlign:'right',
                    }}>
                        {amount1}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                bgcolor:'#3A3E53',
                width:'1px',
                height:'100%',
            }} />
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'22px',
                width:'calc((100% - 1px) / 2)',
            }}>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:400,
                    ml:'18px',
                    mr:'18px',
                    width:'calc(100% - 36px)',
                }}>
                    {title2}
                </Typography>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-end',
                    alignItems:'center',
                    gap:'8px',
                    mr:'33px',
                    width:'calc(100% - 33px)',
                }}>
                    <CardMedia
                        component="img"
                        image={peopleIcon}
                        sx={{
                            width:'24px',
                            height:'24px',
                            objectFit:'contain',
                        }}
                    />
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'21px',
                        fontStyle:'normal',
                        fontWeight:500,
                        textAlign:'right',
                    }}>
                        {amount2}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

const ReferralContentMainContentAgent = (props) => {
    const {
        amount1,amount2,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        activeSubAgents: t("active_sub_agents"),
        yesterday: t("yesterday"),
        sevenDayAverage: t("seven_day_average"),
    };

    return (
        <Box sx={{
            bgcolor:'#1D1D28',
            borderRadius:'18px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            padding:'28px 18px 60px 18px',
            gap:'18px',
            // width:'calc(100% - 36px)',
            width:'460px',
            // height:'calc(100% - 56px)',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                borderRadius:'0px',
                width:'100%',
                padding:'28px 0px',
                gap:'18px',
                height:'auto',
            },
        }}>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'24px',
                fontStyle:'normal',
                fontWeight:500,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                    ml:'18px',
                },
            }}>
                {textData.activeSubAgents}
            </Typography>
            {!screenMD ?
                <React.Fragment>
                    <ReferralContentMainContentAgentItem 
                        title={textData.yesterday}
                        amount={amount1}
                    />
                    <ReferralContentMainContentAgentItem 
                        title={textData.sevenDayAverage}
                        amount={amount2}
                    />
                </React.Fragment> :
                <ReferralContentMainContentAgentItemMobile 
                    title1={textData.yesterday}
                    amount1={amount1}
                    title2={textData.sevenDayAverage}
                    amount2={amount2}
                />
            }
            
        </Box>
    );
}

export default ReferralContentMainContentAgent;
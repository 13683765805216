import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HomeContentMainHistoryBlockTitle from "../HomeContentMainHistoryBlockTitle";
import HomeContentMainHistoryBlockItem from "../HomeContentMainHistoryBlockItem";
import List from "@mui/material/List";
import Grow from '@mui/material/Grow';

const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className={`${tabsClasses.indicatorSpan}`} /> }}
    />
  ))(({ theme }) => ({
    backgroundColor:'#171721',
    borderRadius:'32px',
    display:'flex',
    justifyContent:'space-between',
    alignContent:'center',
    alignItems:'center',
    width:'100%',
    minHeight:'32px',
    [`& .${tabsClasses.indicator}`]: {
      borderRadius:'16px',
      height:'100%',
      display: "flex",
      justifyContent: "center",
      alignItems:'center',
      backgroundColor: "#3A3E53",
      zIndex: theme.zIndex.Tab - 1,
    },
    [`& .${tabsClasses.indicatorSpan}`]: {
      maxWidth: 40,
      height: "1px",
      width: "100%",
      backgroundColor: "transparent"
    }
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center',
    gap:'8px',
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    color: '#ACB3D7',
    zIndex: 2,
    width:'calc(100% / 2)',
    height:'32px',
    minHeight:'32px',
    borderRadius:'16px',
    "&.Mui-selected": {
      color: "#ACB3D7"
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)"
    }
  })
);


const HomeContentMobileHistoryBlock = (props) => {
    const { 
        biggestToday, biggest, latest, rank,player,profit,game,amount,
        biggestHistoryArr,latestHistoryArr 
    } = props;

    const [ type,setType ] = useState(0);

    const maxAmountLength = (type === 0 ? biggestHistoryArr : latestHistoryArr).reduce((maxLen, item) => {
        const profitLength = (type === 0 ? item.profit : item.amount).toString().length;
        return profitLength > maxLen ? profitLength : maxLen;
    }, 0);

    const [resultMaxAmountLength, setResultMaxAmountLength] = useState(maxAmountLength);

    const handleAddResultMaxAmountLength = (addNum) => {
        setResultMaxAmountLength(maxAmountLength + addNum);
    };
    useEffect(() => {
        setResultMaxAmountLength(maxAmountLength);
    }, [(type === 0 ? biggestHistoryArr : latestHistoryArr)]);

    const handleMenuClick = (event, newValue) => {
        if(newValue !== type) {
            setType(newValue);
        }
    };

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'start',
            alignItems:'start',
            gap:'18px',
            width:'100%',
            mt:'20px',
        }}>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'15px',
                fontStyle:'normal',
                fontWeight:400,
                width:'100%',
            }}>
                {biggestToday}
            </Typography>
            <Box sx={{
                bgcolor:'#1D1D28',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                padding:'18px 8px',
                gap:'18px',
                width:'calc(100% - 16px)',
            }}>
                {/* <Box sx={{
                    bgcolor:'#171721',
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    width:'100%',
                    height:'32px',
                    borderRadius:'16px',
                }}>
                    <Button disableRipple={type === 0 ? false : true} sx={{
                        bgcolor:type === 0 ? '#3A3E53' : 'transparent',
                        width:'calc((100vw - 52px) / 2)',
                        height:'32px',
                        borderRadius:'16px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'8px',
                        color:'#ACB3D7',
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:400,
                        textTransform:'none',
                        '&:hover': {
                            bgcolor:type === 0 ? '#3A3E53' : 'transparent',
                        }
                    }} onClick={() => handleTypeClick(0)}>
                        <Box sx={{
                            display:type === 0 ? 'block' : 'none',
                            bgcolor:'#1CA45F',
                            width:'12px',
                            height:'12px',
                            borderRadius:'6px',
                        }} />
                        {biggest}
                    </Button>
                    <Button disableRipple={type === 1 ? false : true} sx={{
                        bgcolor:type === 1 ? '#3A3E53' : 'transparent',
                        width:'calc((100vw - 52px) / 2)',
                        height:'32px',
                        borderRadius:'16px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'8px',
                        color:'#ACB3D7',
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:400,
                        textTransform:'none',
                        '&:hover': {
                            bgcolor:type === 1 ? '#3A3E53' : 'transparent',
                        }
                    }} onClick={() => handleTypeClick(1)}>
                        <Box sx={{
                            display:type === 1 ? 'block' : 'none',
                            bgcolor:'#1CA45F',
                            width:'12px',
                            height:'12px',
                            borderRadius:'6px',
                        }} />
                        {latest}
                    </Button>
                </Box> */}

                <StyledTabs
                    value={type}
                    onChange={handleMenuClick}
                    aria-label="styled tabs example"
                >
                    <StyledTab 
                    icon={
                        <Box sx={{
                            // display:type === 0 ? 'block' : 'none',
                            bgcolor:'#1CA45F',
                            width:'12px',
                            height:'12px',
                            borderRadius:'6px',
                            mt:'5px',
                        }} />
                    } 
                    label={biggest} />
                    <StyledTab 
                    // icon={
                    //     <Box sx={{
                    //         display:type === 1 ? 'block' : 'none',
                    //         bgcolor:'#1CA45F',
                    //         width:'12px',
                    //         height:'12px',
                    //         borderRadius:'6px',
                    //         mt:'5px',
                    //     }} />
                    // } 
                    label={latest} />
                </StyledTabs>

                <HomeContentMainHistoryBlockTitle 
                    leftTitle={type === 0 ? rank : game}
                    middleTitle={player}
                    rightTitle={type === 0 ? profit : amount}
                    isToday={type === 0 ? true : false}
                />
                {/* {(type === 0 ? biggestHistoryArr : latestHistoryArr).map((item,index) => (
                    <HomeContentMainHistoryBlockItem 
                        item={item}
                        isToday={type === 0 ? true : false}
                        maxAmountLength={maxAmountLength}
                        index={index+1}
                        key={index}
                    />
                ))} */}

                {/* <List sx={{
                    height:'330px',
                    width:'100%',
                }}>
                    <TransitionGroup>
                    {(type === 0 ? biggestHistoryArr : latestHistoryArr).map((item,index) => (
                        <Collapse style={{ timeout: '300' }} key={
                            (type === 0 ? item.rank : item.game) + item.user+(type === 0 ? item.profit : item.amount)
                        }>
                            <Box sx={{
                                mt:index === 0 ? '0px' : '18px',
                            }}>
                                <HomeContentMainHistoryBlockItem 
                                    item={item}
                                    isToday={type === 0 ? true : false}
                                    maxAmountLength={maxAmountLength}
                                    index={index+1}
                                />
                            </Box>
                        </Collapse>
                    ))}
                    </TransitionGroup>
                </List> */}

                <List sx={{
                    height:'330px',
                    width:'100%',
                }}>
                    {(type === 0 ? biggestHistoryArr : latestHistoryArr).map((item,index) => (
                        <Grow in={true} key={
                            (type === 0 ? item.rank : item.game) + item.user+(type === 0 ? item.profit : item.amount)
                        } 
                        style={{ 
                            transformOrigin: '0 0 0',
                            transitionDelay: `${index * 80}ms`
                        }}
                        timeout={{enter:((type === 0 ? biggestHistoryArr : latestHistoryArr).length - 1) * 180,exit:0,}}
                        >
                            <Box sx={{
                                mt:index === 0 ? '0px' : '18px',
                            }}>
                                <HomeContentMainHistoryBlockItem 
                                    item={item}
                                    isToday={type === 0 ? true : false}
                                    maxAmountLength={resultMaxAmountLength}
                                    handleAddResultMaxAmountLength={handleAddResultMaxAmountLength}
                                    index={index+1}
                                />
                            </Box>
                        </Grow>
                    ))}
                </List>
            </Box>
        </Box>
    );
}

export default HomeContentMobileHistoryBlock;
import React, { useEffect, useRef } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import LoginAndRegisterContentLeft from "./LoginAndRegisterContentLeft";
import LoginAndRegisterContentRight from "./LoginAndRegisterContentRight";
import mobileTopRightIcon from '../../assets/images/login-and-register/login-and-register-mobile-top-right.png';
import mobileBottomLeftIcon from '../../assets/images/login-and-register/login-and-register-mobile-bottom-left.png';

const LoginAndRegisterContent = (props) => {
    const { type,fromPage,isLogout } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        welcomeTo: t("welcome_to"),
        theParadiseOf: t("the_paradise_of"),
        iGaming: t("i_gaming"),
        startYourNow: t("start_your___now"),
        privacySecurity: t("privacy_security"),
        endToCompany: t("end_to___company"),
        fairness: t("fairness"),
        toArchiveWith: t("to_archive___with"),
        funnyGames: t("funny_games"),
        overHundredsWant: t("over_hundreds___want"),
        instantCashOut: t("instant_cash_out"),
        login: t("login"),
        signUp: t("sign_up"),
        password: t("password"),
        referralCode: t("referral_code"),
        optional: t("optional"),
        iamThe: t("i_am___the"),
        privacyPolicy: t("privacy_policy"),
        and: t("and"),
        termsOfService: t("terms_of_service"),
        letUsGo: t("let_us_go"),
        joinUs: t("join_us"),
        or: t("or"),
        loginWithGmail: t("login_with_gmail"),
        loginWithTelegram: t("login_with_telegram"),
        emailIsRequired: t("email_is_required"),
        passwordIsRequired: t("password_is_required"),
        forgetPassword: t("forget_password"),
        loginSuccessfully: t("login_successfully"),
        verification: t("verification"),
        verificationCodeLater: t("verification_code___later"),
        sendCode: t("send_code"),
        sentSuccessfully: t("sent_successfully"),
        pleaseInputCode: t("please_input___code"),
        referralIsRequired: t("referral_code_is_required"),
        failed: t("failed"),
        emailFormatIncorrect: t("email_format___incorrect"),
        charactersInNumbers: t("characters_in___numbers"),
        baoJia: t("bao_jia"),
        the_current__devices: t("the_current__devices"),
    };

    const ref = useRef();
    useEffect(() => {
        if(screenMD) {
            const handleResize = () => {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            };
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    },[]);

    if(screenMD) {
        return (
            <Box sx={{
                background:'linear-gradient(0deg, rgba(22, 16, 22, 1) 0%, rgba(17, 16, 23, 1) 100%)',
                width:'100vw',
                height:'100vh',
                height: 'calc(var(--vh, 1vh) * 100)',
            }} ref={ref}>
                <CardMedia
                    component="img"
                    image={mobileTopRightIcon}
                    sx={{
                        width:'285px',
                        height:'134px',
                        objectFit:'contain',
                        position:'absolute',
                        float:'right',
                        top:'0px',
                        right:'0px',
                        overflow:'hidden',
                    }}
                />
                <CardMedia
                    component="img"
                    image={mobileBottomLeftIcon}
                    sx={{
                        width:'285px',
                        height:'218px',
                        objectFit:'contain',
                        position:'absolute',
                        float:'left',
                        left:'0px',
                        bottom:'0px',
                        overflow:'hidden',
                    }}
                />
                <LoginAndRegisterContentRight 
                    login={textData.login}
                    signUp={textData.signUp}
                    exampleEmail={'Example@ttbet.com'}
                    password={textData.password}
                    referralCode={textData.referralCode}
                    optional={textData.optional}
                    iamThe={textData.iamThe}
                    privacyPolicy={textData.privacyPolicy.toLowerCase()}
                    and={textData.and}
                    termsOfService={textData.termsOfService}
                    letUsGo={textData.letUsGo}
                    joinUs={textData.joinUs}
                    or={textData.or}
                    loginWithGmail={textData.loginWithGmail}
                    loginWithTelegram={textData.loginWithTelegram}
                    emailIsRequired={textData.emailIsRequired}
                    passwordIsRequired={textData.passwordIsRequired}
                    forgetPassword={textData.forgetPassword}
                    verification={textData.verification}
                    verificationCodeLater={textData.verificationCodeLater}
                    sendCode={textData.sendCode}
                    sentSuccessfully={textData.sentSuccessfully}
                    pleaseInputCode={textData.pleaseInputCode}
                    referralIsRequired={textData.referralIsRequired}
                    failed={textData.failed}
                    emailFormatIncorrect={textData.emailFormatIncorrect}
                    charactersInNumbers={textData.charactersInNumbers}
                    theCurrentDevices={textData.the_current__devices}
                    type={type}
                    fromPage={fromPage}
                    isLogout={isLogout}
                />
            </Box>
        );
    }

    return (
        <Grid container sx={{
            bgcolor:'#000000',
            display:'flex',
            position:'relative',
            justifyContent:'space-between',
            width:'100vw',
            height:'100vh',
        }}>
            <LoginAndRegisterContentLeft 
                welcomeTo={textData.welcomeTo}
                baoJia={textData.baoJia}
                theParadiseOf={textData.theParadiseOf}
                iGaming={textData.iGaming}
                startYourNow={textData.startYourNow}
                privacySecurity={textData.privacySecurity}
                endToCompany={textData.endToCompany}
                fairness={textData.fairness}
                toArchiveWith={textData.toArchiveWith}
                funnyGames={textData.funnyGames}
                overHundredsWant={textData.overHundredsWant}
                instantCashOut={textData.instantCashOut}
                weAreMinutes={textData.weAreMinutes}
            />
            <LoginAndRegisterContentRight 
                login={textData.login}
                signUp={textData.signUp}
                exampleEmail={'Example@ttbet.com'}
                password={textData.password}
                referralCode={textData.referralCode}
                optional={textData.optional}
                iamThe={textData.iamThe}
                privacyPolicy={textData.privacyPolicy.toLowerCase()}
                and={textData.and}
                termsOfService={textData.termsOfService}
                letUsGo={textData.letUsGo}
                joinUs={textData.joinUs}
                or={textData.or}
                loginWithGmail={textData.loginWithGmail}
                loginWithTelegram={textData.loginWithTelegram}
                emailIsRequired={textData.emailIsRequired}
                passwordIsRequired={textData.passwordIsRequired}
                forgetPassword={textData.forgetPassword}
                youAreContinue={textData.youAreContinue}
                pleaseInputEmail={textData.pleaseInputEmail}
                reset={textData.reset}
                verification={textData.verification}
                verificationCodeLater={textData.verificationCodeLater}
                sendCode={textData.sendCode}
                sentSuccessfully={textData.sentSuccessfully}
                pleaseInputCode={textData.pleaseInputCode}
                referralIsRequired={textData.referralIsRequired}
                failed={textData.failed}
                emailFormatIncorrect={textData.emailFormatIncorrect}
                charactersInNumbers={textData.charactersInNumbers}
                theCurrentDevices={textData.the_current__devices}
                type={type}
                fromPage={fromPage}
                isLogout={isLogout}
            />
        </Grid>
    );
}

export default LoginAndRegisterContent;
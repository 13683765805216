import React from 'react';
import Customer from '../component/common/Customer';
import { useLocation, useNavigate } from 'react-router-dom';
import TTAppBar from '../component/common/TTAppBar';
import { useTranslation } from "react-i18next";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const HelpCenterDesc = () => {
    const location = useLocation();
    const descArr = location.state && location.state.descArr;

    const { t } = useTranslation();
    const textData = {
        helpCenter: t("help_center"),
    };

    const navigate = useNavigate();

    const handleCloseClick = () => {
        navigate(-1);
    }

    return (
        <React.Fragment>
            <Customer />
            <TTAppBar  
                style={4} 
                title={textData.helpCenter}
                rightOnClick={handleCloseClick}
            />
            <Grid sx={{
                pl:'0px',
                pt:'64px',
            }}>
                <Grid container sx={{
                    padding:'18px 0px 0px 0px',
                }}>
                    <Container sx={{
                        // bgcolor:'#bbbbbb',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        gap:'18px',
                        '@media': {
                            pl:'0px',
                            pr:'0px',
                            maxWidth:'1000px',
                        }
                    }}>
                        <Grid sx={{
                            bgcolor:'#1D1D28',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            gap:'28px',
                            padding:'18px',
                        }}>
                            {descArr.map((item,index) => (
                                <Box sx={{
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'flex-start',
                                    alignItems:'flex-start',
                                    gap:'8px',
                                }} key={index}>
                                    <Typography sx={{
                                        color:'#1CA45F',
                                        textDecoration:'underline',
                                        fontSize:'15px',
                                        fontStyle:'normal',
                                        fontWeight:400,
                                    }}>
                                        {item.title}
                                    </Typography>
                                    <Typography sx={{
                                        color:'#FFFFFF',
                                        fontSize:'12px',
                                        fontStyle:'normal',
                                        fontWeight:400,
                                        whiteSpace:'pre-line',
                                    }} style={{ whiteSpace: 'pre-line' }}>
                                        {item.desc}
                                    </Typography>
                                </Box>
                            ))}
                        </Grid>
                    </Container> 
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default HelpCenterDesc;
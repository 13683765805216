import React, { useEffect } from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import ReferralContent from '../component/referral/ReferralContent';
import { useDispatch, useSelector } from 'react-redux';
// import { switchAppCategory } from '../features/appSlice';

const Referral = () => {
    const dispatch = useDispatch();
    const appCategory = useSelector((state) => state.app.appCategory);
    useEffect(() => {
        if(appCategory !== 'casino') {
            dispatch(switchAppCategory({appCategory:'casino'}));
        }
        // dispatch(switchReferralCategory({ referralCategory: 'normal' }))
    }, []);

    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.referral} drawerIndex={9} />
            <ReferralContent />
        </React.Fragment>
    )
}

export default Referral;
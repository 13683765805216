import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import sportIcon from '../../assets/images/home/home-category-sport.png';
import casinoIcon from '../../assets/images/home/home-category-casino.png';
import pokerIcon from '../../assets/images/home/home-category-poker.png';
import lotteryIcon from '../../assets/images/home/home-category-lottery.png';
import sportBg from '../../assets/images/home/home-category-bg-sport.webp';
import casinoBg from '../../assets/images/home/home-category-bg-casino.webp';
import pokerBg from '../../assets/images/home/home-category-bg-poker.webp';
import lotteryBg from '../../assets/images/home/home-category-bg-lottery.webp';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { switchAppCategory } from '../../features/appSlice';
import RoutePath from '../../tools/RoutePath';
import TTAlert from '../common/TTAlert';

const HomeContentMainCategory = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        many_competitions__sports: t("many_competitions__sports"),
        enter_our__casino: t("enter_our__casino"),
        play_against__world: t("play_against__world"),
        if_you__you: t("if_you__you"),
        sports: t("sports"),
        casino: t("casino"),
        texas_hold_em: t("texas_hold_em"),
        lottery: t("lottery"),
        you_do__service: t("you_do__service"),
    };

    const token = useSelector((state) => state.user.access_token);
    const allowSport = useSelector((state) => state.user.user.allow_sport);

    const itemArr = [
        { title: textData.sports, desc: textData.many_competitions__sports, icon: sportIcon, bg: sportBg },
        { title: textData.casino, desc: textData.enter_our__casino, icon: casinoIcon, bg: casinoBg },
        { title: textData.texas_hold_em, desc: textData.play_against__world, icon: pokerIcon, bg: pokerBg },
        { title: textData.lottery, desc: textData.if_you__you, icon: lotteryIcon, bg: lotteryBg }
    ];

    const [hovered, setHovered] = useState(new Array(itemArr.length).fill(false));

    const handleItemClick = (index) => {
        if(index === 0) {
            if(token !== undefined && token !== null && token.length > 0) {
                if(allowSport) {
                    dispatch(switchAppCategory({appCategory:'sports'}));
                    navigate(RoutePath.sports);
                }else {
                    setAlertMessage(textData.you_do__service);
                    setAlertType('info');
                    setOpenAlert(true);
                }
            }else {
                navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.home}});
            }
        }else if(index === 1) {
            dispatch(switchAppCategory({appCategory:'casino'}));
            navigate(RoutePath.newSlots, { state: { category:2,fromPage:RoutePath.newSlots } } );
        }else if(index === 2) {
            dispatch(switchAppCategory({appCategory:'casino'}));
            navigate(RoutePath.poker);
        }else if(index === 3) {
            window.location.href = 'https://t.me/jzgj28'
        }
    };
    const onMouseEnter = (index) => {
        const newHovered = new Array(itemArr.length).fill(false);
        newHovered[index] = true;
        setHovered(newHovered);
    };
    const onMouseLeave = (index) => {
        const newHovered = new Array(itemArr.length).fill(false);
        newHovered[index] = false;
        setHovered(newHovered);
    };

    // alert
    const [ alertType,setAlertType ] = useState('info');
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    return (
        <React.Fragment>
            <TTAlert
                type={alertType}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />
            
            <Box sx={{
                display:'grid',
                gridTemplateColumns:'repeat(4, 1fr)',
                gap:'18px',
                height:'220px',
                mt:'30px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'100%',
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    height:'calc((100vw - 36px) * 155 / 339)',
                    mt:'18px',
                    gap:'13px',
                    overflow:'auto',
                    scrollSnapType:'x mandatory',
                    scrollbarWidth: 'none', // Firefox
                    msOverflowStyle: 'none', // Internet Explorer
                    '&::-webkit-scrollbar': {
                        display: 'none', // Chrome, Safari, Edge
                    },
                    '& > *': {
                        scrollSnapAlign: 'start',
                        flexShrink: 0,
                        width: 'calc((100vw - 36px) / 4)', // Adjust width as necessary
                    },
                },
            }}>
                {itemArr.map((item,index) => (
                    <Box sx={{
                        position:'relative',
                        width:'100%',
                        height:'100%',
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'flex-end',
                        borderRadius:'8px',
                        overflow:'hidden',
                        cursor:'pointer',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'calc((100vw - 36px - 13px) / 1.8)',
                            minWidth:'calc((100vw - 36px - 13px) / 1.8)',
                        }
                    }} key={index} onClick={() => handleItemClick(index)} onMouseEnter={() => onMouseEnter(index)} onMouseLeave={() => onMouseLeave(index)}>
                        <CardMedia
                            component="img"
                            image={item.bg}
                            sx={{
                                position:'absolute',
                                top:'0px',
                                left:'0px',
                                width:'100%',
                                height:'100%',
                                objectFit:'cover',
                                WebkitTransition:'all cubic-bezier(.42,0,.03,1) .5s',
                                transition:'all cubic-bezier(.42,0,.03,1) .5s',
                                ...(hovered[index] && {
                                    transform: 'scale3d(1.1,1.1,1)',
                                    WebkitTransform: 'scale3d(1.1,1.1,1)',
                                    msTransform: 'scale3d(1.1,1.1,1)',
                                  }),
                            }}
                        />
                        <Box sx={{
                            position:'absolute',
                            top:'0px',
                            left:'0px',
                            width:'100%',
                            height:'100%',
                            zIndex:1,
                            background:'linear-gradient(180deg,rgba(0,0,0,.2) 0%,rgba(0,0,0,0) 100%)',
                            opacity:0,
                            WebkitTransition:'all cubic-bezier(.42,0,.03,1) .5s',
                            transition:'all cubic-bezier(.42,0,.03,1) .5s',
                            ...(hovered[index] && { opacity: 1 }),
                        }}/> 
                        <Box sx={{
                            bgcolor:'rgba(0, 0, 0, 0.40)',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'flex-start',
                            alignItems:'flex-start',
                            padding:'8px 14px',
                            gap:'8px',
                            width:'100%',
                            minHeight:'76px',
                            zIndex:2,
                            // WebkitBackdropFilter:'blur(1.5625rem)',
                            // backdropFilter:'blur(1.5625rem)',
                            WebkitTransition:'all cubic-bezier(.42,0,.03,1) .5s',
                            transition:'all cubic-bezier(.42,0,.03,1) .5s',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                minHeight:'57px',
                                padding:'6px 10px',
                            }
                        }}>
                            <Box sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'center',
                                gap:'4px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    gap:'3px',
                                }
                            }}>
                                <CardMedia
                                    component="img"
                                    image={item.icon}
                                    sx={{
                                        width:'18px',
                                        height:'18px',
                                        objectFit:'contain',
                                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                            width:'14px',
                                            height:'14px',
                                        },
                                    }}
                                />
                                <Typography sx={{
                                    color:'#FFFFFF',
                                    fontSize:'18px',
                                    fontWeight:700,
                                    lineHeight:'24px',
                                    textTransform:'uppercase',
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        fontSize:'14px',
                                        lineHeight:'18px',
                                    }
                                }}>
                                    {item.title}
                                </Typography>
                            </Box>
                            <Typography sx={{
                                color:'#FFFFFF',
                                fontSize:'10px',
                                fontWeight:400,
                                lineHeight:'17px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    fontSize:'8px',
                                    lineHeight:'13px',
                                }
                            }}>
                                {item.desc}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </React.Fragment>
    );
}

export default HomeContentMainCategory;
import React, { useEffect } from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import RecentContent from "../component/recent/RecentContent";
import { useDispatch, useSelector } from 'react-redux';
import { switchAppCategory } from '../features/appSlice';

const Recent = () => {
    const dispatch = useDispatch();
    const appCategory = useSelector((state) => state.app.appCategory);
    useEffect(() => {
        if(appCategory !== 'casino') {
            dispatch(switchAppCategory({appCategory:'casino'}));
        }
    }, []);

    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.recent} drawerIndex={8}/>
            <RecentContent />
        </React.Fragment>
    )
}

export default Recent;
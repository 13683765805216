import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import footerCasinos from '../../../assets/images/footer/footer-casinos.png';
import footerPrivacySecurity from '../../../assets/images/footer/footer-privacy-security.png';
import footerAge18 from '../../../assets/images/footer/footer-age-18.png';

const FooterMobileCenter = (props) => {
    const { weAtCasinos, privacyPolicy, age18 } = props;
    const imageArr = [footerCasinos,footerPrivacySecurity,footerAge18];
    const titleArr1 = [weAtCasinos,privacyPolicy,age18];

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            gap:'28px',
        }}>
            {imageArr.map((item,index) => (
                <Box sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    gap:'6px',
                }} key={index}>
                    <CardMedia
                        component="img"
                        image={ item }
                        sx={{
                            width:'12px',
                            height:'17px',
                            objectFit:'contain'
                        }}
                    />
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400,
                    }}>
                        {titleArr1[index]}
                    </Typography>
                </Box>
            ))}
        </Grid>
    );
}

export default FooterMobileCenter;


import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const HomeContentMainGameProvidersTitle = (props) => {
    const { title,prevClick,nextClick } = props;
    const theme = useTheme();

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
        }}>
            <Typography sx={{
                color:'#FFFFFF',
                fontSize:'28px',
                fontStyle:'normal',
                fontWeight:500,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'20px',
                },
            }}>
                {title}
            </Typography>
            <Box sx={{
                display:'flex',
                justifyContent:'end',
                alignItems:'center',
                gap:'8px',
                [theme.breakpoints.up(MobileBreakpoint.match)]: {
                    height:'63px',
                },
            }}>
                <Box sx={{
                    width:'28px',
                    height:'28px',
                    cursor:'pointer',
                }} onClick={prevClick}>
                    {/* <CardMedia
                        component="img"
                        image={ homePrevIcon }
                    /> */}
                </Box>
                <Box sx={{
                    width:'28px',
                    height:'28px',
                    cursor:'pointer',
                }} onClick={nextClick}>
                    {/* <CardMedia
                        component="img"
                        image={ homeNextIcon }
                    /> */}
                </Box>
            </Box>
        </Grid>
    );
}

export default HomeContentMainGameProvidersTitle;
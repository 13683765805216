import React, { useEffect } from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import PokerContent from '../component/poker/PokerContent';
import { useDispatch, useSelector } from 'react-redux';
import { switchAppCategory } from '../features/appSlice';

const Poker = () => {
    const dispatch = useDispatch();
    const appCategory = useSelector((state) => state.app.appCategory);
    useEffect(() => {
        if(appCategory !== 'casino') {
            dispatch(switchAppCategory({appCategory:'casino'}));
        }
    }, []);

    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.poker} drawerIndex={6} />
            <PokerContent />
        </React.Fragment>
    )
}

export default Poker;
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import RoutePath from '../../tools/RoutePath';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import homeCassinoIcon from '../../assets/images/home/home-cassino.png';
import { useNavigate } from 'react-router-dom';
import TTAlert from '../common/TTAlert';
import TTGameIcon from '../common/TTGameIcon';

const HomeContentMainInHouseGameItem = (props) => {
    const { image, title, gameUrl, playerNum, isActive, comingSoon, inHouseGame } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();

    const handleItemClick = () => {
        if(gameUrl.length <= 0) {
            setAlertType('info');
            setAlertMessage(comingSoon);
            setOpenAlert(true);
            return;
        }
        navigate(RoutePath.game,
            { 
                state: { 
                    category:inHouseGame,
                    fromPage:RoutePath.home,
                    gameIcon:image,
                    gameName:title,
                    isCloseFreePlayMode:false,
                    gameUrl:gameUrl,
                }
            }
        );
    }

    // alert
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertType, setAlertType ] = useState('info');
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    if(!isActive) {
        return (
            <React.Fragment>
                <TTAlert 
                    type={alertType}
                    isOpen={openAlert}
                    message={alertMessage}
                    closeCallback={handleAlertClose}
                />

                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    width:'148px',
                    gap:'18px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'calc((100vw - 76px) / 3)',
                        gap:'5px',
                    },
                }}>
                    <CardMedia
                        component="img"
                        image={ homeCassinoIcon }
                        sx={{
                            objectFit:'cover',
                            width:'148px',
                            height:'148px',
                            borderRadius:'24px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'calc((100vw - 76px) / 3)',
                                height:'calc((100vw - 76px) / 3)',
                                borderRadius:'16px',
                            },
                        }}
                    />
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'16px',
                        fontStyle:'normal',
                        fontWeight:700,
                        textAlign:'center',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'12px',
                        },
                    }}>
                        {comingSoon}
                    </Typography>
                </Box>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <TTAlert 
                type={alertType}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                width:'148px',
                gap:'18px',
                cursor:'pointer',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'calc((100vw - 76px) / 3)',
                    gap:'5px',
                },
            }} onClick={handleItemClick}>
                <TTGameIcon 
                    width='148px'
                    height='148px'
                    mobileSpace='76px'
                    column={3}
                    image={image}
                />
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'flex-start',
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'16px',
                        fontStyle:'normal',
                        fontWeight:700,
                        // maxWidth:'100px',
                        width:'100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'12px',
                            // maxWidth:'80px',
                        },
                    }}>
                        {title}
                    </Typography>
                    {/* <Box sx={{
                        display:'flex',
                        justifyContent:'end',
                        alignItems:'center',
                        gap:'5px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'1.5px',
                        },
                    }}>
                        <CardMedia
                            component="img"
                            image={ homePeopleIcon }
                            sx={{
                                objectFit:'cover',
                                width:'14px',
                                height:'12px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'10px',
                                    height:'8px',
                                },
                            }}
                        />
                        <Typography sx={{
                            color:'#6D728D',
                            fontSize:'12px',
                            fontStyle:'normal',
                            fontWeight:400,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'9px',
                            },
                        }}>
                            {playerNum}
                        </Typography>
                    </Box> */}
                </Grid>
            </Box>
        </React.Fragment>
    );
}

export default HomeContentMainInHouseGameItem;
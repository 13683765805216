import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const FooterContentRightItem = (props) => {
    const { textArr,isEmail } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    const supportEmail = 'support@ttbetgame.com';

    const handleOpenMailApp = (item) => {
        if(item.type === 'email') {
            // 替换成你的主题和正文内容
            // const subject = '主题';
            // const body = '邮件正文内容';
        
            // 构建mailto链接
            // const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            const mailtoLink = `mailto:${supportEmail}`;
        
            // 打开默认邮件客户端
            window.location.href = mailtoLink;
        }else if(item.type === 'termOfService') {
            navigate(RoutePath.helpCenter,{state:{categoryIndex:3}});
        }
    };

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'start',
            gap:'7px',
        }}>
            {textArr.map((item,index) => (
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:400,
                    cursor:item.type === 'contactUs' ? 'default' : 'pointer',
                    '&:hover': {
                        color:item.type === 'contactUs' ? '#ACB3D7' : '#FFFFFF',
                    },
                    transition: theme.transitions.create(['color'], {
                        duration: 300,
                    }),
                }} key={index} onClick={() => handleOpenMailApp(item)}>
                    {item.text}
                    {item.type === 'email' ? <span style={{color:'#1CA45F'}}> {supportEmail}</span> : ''}
                </Typography>
            ))}
        </Box>
    );
}

export default FooterContentRightItem;
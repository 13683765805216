import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import WalletContentMainLeft from './WalletContentMainLeft';
import WalletContentMainRight from './WalletContentMainRight';
import WalletContentMainNoticeAlert from './WalletContentMainNoticeAlert';

const WalletContentMain = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    const [ openNotice,setOpenNotice ] = useState(false);

    useEffect(() => {
        const first = localStorage.getItem('ttFirstLookWallet');
        if(first === undefined || first === null || first.toString().length <= 0) {
            setTimeout(() => {
                setOpenNotice(true);
            }, 500);
        }
    }, []);

    const handleCloseNotice = () => {
        localStorage.setItem('ttFirstLookWallet','1');
        setOpenNotice(false);
    };

    return (
        <React.Fragment>
            <WalletContentMainNoticeAlert 
                isOpen={openNotice}
                handleCloseClick={handleCloseNotice}
            />

            <Grid container sx={{
                padding:'36px 60px 145px 60px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'18px 0px 0px 0px',
                },
            }}>
                <Container sx={{
                    // bgcolor:'#bbbbbb',
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'start',
                    gap:'16px',
                    '@media': {
                        pl:'0px',
                        pr:'0px',
                        maxWidth:'1000px',
                    }
                }}>
                    <WalletContentMainLeft />
                    {screenMD ? '' : <WalletContentMainRight />}
                </Container>
            </Grid>
        </React.Fragment>
    );
}

export default WalletContentMain;
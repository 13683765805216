import React from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import depositBannerIcon from '../../../assets/images/wallet/wallet-deposit-banner.png';
import depositBannerMobileIcon from '../../../assets/images/wallet/wallet-deposit-banner-mobile.png';
import WalletContentMainLeftBalance from '../WalletContentMainLeftBalance';
import WalletContentMainLeftDepositNetwork from './WalletContentMainLeftDepositNetwork';
import WalletContentMainLeftRecord from '../WalletContentMainLeftRecord';
import WalletContentMainFAQ from "../WalletContentMainFAQ";

const WalletContentMainLeftDeposit = (props) => {
    const {
        yourBalance,transaction,network,next,recordOf,orderNo,type,amount,status,time,deposit,
        prepare,inProgress,success,failed,noData,theAmountThan,
        isShow
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    return (
        <Grid container sx={{
            display:isShow ? 'flex' : 'none',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            gap:'48px',
            width:'100%',
            mt:'48px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'18px',
                gap:'18px',
            },
        }}>
            <CardMedia
                component="img"
                image={ screenMD ? depositBannerMobileIcon : depositBannerIcon }
                sx={{
                    objectFit:'contain',
                    width:'100%',
                    height:'87px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        margin:'0px 18px',
                        width:'calc(100% - 36px)',
                        height:'calc((100vw - 36px)*88/355)',
                        borderRadius:'18px',
                        objectFit:'cover',
                    },
                }}
            />
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'18px',
                width:'100%',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    gap:'0px',
                },
            }}>
                <WalletContentMainLeftBalance 
                    yourBalance={yourBalance}
                    transaction={transaction}
                    type={0}
                />
                <WalletContentMainLeftDepositNetwork 
                    network={network}
                    next={next}
                    theAmountThan={theAmountThan}
                />
                {screenMD ? 
                    <WalletContentMainFAQ 
                        faq={'FAQ'}
                    /> : ''
                }
                <WalletContentMainLeftRecord 
                    recordOf={recordOf+' USDT'}
                    orderNo={orderNo}
                    type={type}
                    amount={amount}
                    status={status}
                    time={time}
                    deposit={deposit}
                    prepare={prepare}
                    inProgress={inProgress}
                    success={success}
                    failed={failed}
                    noData={noData}
                    recordType={isShow ? 'deposit' : ''}
                />
            </Box>
        </Grid>
    );
}

export default WalletContentMainLeftDeposit;
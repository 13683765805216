import { createSlice } from '@reduxjs/toolkit';

const generateRandomNumber = () => {
    const min = 500;
    const max = 1000;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNum;
};

const initialState = {
    onlineUserNumber: generateRandomNumber(),
    download_app_links_config: {
        ios_main_link:'',
        ios_backup_link:'',
        android_link:'',
        download_page_url:'',
    },
    popupIsShowed:false,
    homeShowLoading:true,
    launchRedirectSport:true,
    appCategory: 'casino',
    referralCategory: 'normal'
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        onlineUserRefresh(state,action) {
            state.onlineUserNumber = action.payload.number;
            // console.log('onlineUserRefresh state:',JSON.stringify(state));
        },
        pokerDownloadLinkRefresh(state,action) {
            state.download_app_links_config = action.payload.download_app_links_config;
        },
        popupShowRefresh(state,action) {
            state.popupIsShowed = action.payload.popupIsShowed;
        },
        switchAppCategory(state,action) {
            state.appCategory = action.payload.appCategory;
        },
        switchReferralCategory(state,action) {
            state.referralCategory = action.payload.referralCategory;
        },
        launchRedirectedSport(state) {
            state.launchRedirectSport = false
        },
        tgBotLogged(state) {
            state.homeShowLoading = false
        }
    }
});

export const { 
    onlineUserRefresh,
    pokerDownloadLinkRefresh,
    popupShowRefresh,
    switchAppCategory, 
    switchReferralCategory,
    launchRedirectedSport,
    tgBotLogged
} = appSlice.actions;

export default appSlice.reducer;
import { useEffect } from "react";
import { clearUserCookies } from "../tools/CommonTool";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import RoutePath from "../tools/RoutePath";
import { userForceLogin } from "../features/userSlice";
import httpManager from "./httpManager";

const ForceLogin = () => {
    // const navigate = useNavigate();
    // navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.home,isLogout:true}});
    const isForceLogin = useSelector((state) => state.user.forceLogin);
    const dispatch = useDispatch();
    useEffect(() => {
        // 在这里配置HTTP拦截器
        httpManager.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error && error.response && error.response.status === 401 && !isForceLogin) {
                clearUserCookies();
                dispatch(userForceLogin());
            }
            // 处理错误响应
            return Promise.reject(error);
        }
        );
    }, [dispatch]);
}
export default ForceLogin;
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TTLoading from '../component/common/TTLoading';
import Fab from '@mui/material/Fab';
import CardMedia from '@mui/material/CardMedia';
import GameContentMobile from '../component/game/mobile/GameContentMobile';
import backIcon from '../assets/images/game/game-mobile-back.png';
import customerIcon from '../assets/images/game/game-mobile-customer.png';
import { getCustomerLinkService } from '../network/service/home';

const GameMobile = () => {
    const location = useLocation();
    const category = location.state && location.state.category;
    const fromPage = location.state && location.state.fromPage;
    const gameIcon = location.state && location.state.gameIcon;
    const gameName = location.state && location.state.gameName;
    const gameUrl = location.state && location.state.gameUrl;

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    }

    const [ customerLink,setCustomerLink ] = useState('');
    const [ openLoading,setOpenLoading ] = useState(false);

    const handleCustomerClick = () => {
        if(customerLink === '') {
            setOpenLoading(true);
            handleFetchCustomer((link) => {
                setOpenLoading(false);
                window.location.href = link;
            });
            return;
        }
        window.location.href = customerLink;
    };

    const handleFetchCustomer = async (callBack) => {
        try {
            const response = await getCustomerLinkService();
            setCustomerLink(response.data.customer_service_link);
            callBack && callBack(response.data.customer_service_link);
        } catch (error) {
            console.log('handleFetchCustomer error:' + error);
        }
    }

    useEffect(() => {
        handleFetchCustomer();
    }, []);

    return (
        <React.Fragment>
            {/* <TTAppBar style={1} title={gameName} /> */}
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <Fab variant='extended' onClick={handleBackClick} sx={{
                bgcolor:'transparent',
                borderRadius:'0px',
                boxShadow:'none',
                position:'fixed',
                top:'25px',
                left:'15px',
                float:'left',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'26px',
                height:'26px',
                minWidth:'0px',
                minHeight:'0px',
                padding:'0px',
                '&:hover': {
                    bgcolor:'transparent',
                },
            }}>
                <CardMedia
                    component="img"
                    image={backIcon}
                    sx={{
                        width:'26px',
                        height:'26px',
                        objectFit:'contain',
                    }}
                />
            </Fab>
            <Fab variant='extended' onClick={handleCustomerClick} sx={{
                bgcolor:'transparent',
                borderRadius:'0px',
                boxShadow:'none',
                position:'fixed',
                top:'25px',
                left:'56px',
                float:'left',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'26px',
                height:'26px',
                minWidth:'0px',
                minHeight:'0px',
                padding:'0px',
                '&:hover': {
                    bgcolor:'transparent',
                },
            }}>
                <CardMedia
                    component="img"
                    image={customerIcon}
                    sx={{
                        width:'26px',
                        height:'26px',
                        objectFit:'contain',
                    }}
                />
            </Fab>
            <GameContentMobile
                category={category}
                fromPage={fromPage}
                gameIcon={gameIcon}
                gameName={gameName}
                gameUrl={gameUrl}
            />
        </React.Fragment>
    );
}

export default GameMobile;
import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TTAlert from '../../common/TTAlert';
import TTLoading from "../../common/TTLoading";
import { walletTransferService, walletWithdrawService } from "../../../network/service/wallet";
import { useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import { useNavigate } from "react-router-dom";

const WithdrawContentNoEditAddress = (props) => {
    const { 
        address,amount,balance,gasFee,gasFeeRequires,failed,withdrawSuccessfully,
        withdrawalAmountsFrom,theGasAmount,estimatedArrival,confirm,theWithdrawalBetween,
        defaultAddress,defaultAmount,defaultBalance,defaultFundingBalance,defaultGasFee,token,chain,chainType
    } = props;
    const theme = useTheme();
    const navigate = useNavigate();

    const [ openLoading,setOpenLoading ] = useState(false);
    const [ withdrawIsSuccess,setWithdrawIsSuccess ] = useState(false);

    const withdrawValue = BigNumber(defaultAmount).minus(BigNumber(defaultGasFee));
    const userId = useSelector((state) => state.user.user.pk);

    // alert
    const [ type, setType ] = useState('error');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);

        if(withdrawIsSuccess) {
            const date = new Date().getTime();
            localStorage.setItem('ttBetWithdraw',`${defaultAmount}-${defaultBalance}-${defaultFundingBalance}-${chain}-${token}-${date}`);
            // window.close();
            navigate(-1);
        }
    };

    // service
    const handleTransfer = async () => {
        try {
            const params = {
                user_id:parseInt(userId),
                amount:BigNumber(defaultAmount).minus(BigNumber(defaultFundingBalance)).toNumber(),
                trans_to:'funding',
                symbol:token
            }
            await walletTransferService(params);
            handleWithdraw();
        } catch (error) {
            setOpenLoading(false);
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };
    const handleWithdraw = async () => {
        try {
            const params = {
                user_id:parseInt(userId),
                amount:defaultAmount,
                address:defaultAddress,
                symbol:token,
                platform:chainType,
            }
            await walletWithdrawService(params);
            setOpenLoading(false);

            setWithdrawIsSuccess(true);
            setType('success');
            setAlertMessage(withdrawSuccessfully);
            setOpen(true);
        } catch (error) {
            setOpenLoading(false);
                
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };

    const handleConfirmClick = () => {
        // 先判断资金账户钱是否充足 不足先从现金账户划转过去
        setOpenLoading(true);
        if(BigNumber(defaultFundingBalance).isLessThan(BigNumber(defaultAmount))) {
            handleTransfer();
        }else {
            handleWithdraw();
        }
    };

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={type}
                isOpen={open}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />
            <Box sx={{
                bgcolor:'#1D1D28',
                borderRadius:'18px',
                padding:'19px 0px 50px 0px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'start',
                width:'100%',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'calc(100% - 36px)',
                    borderRadius:'0px',
                    padding:'18px',
                },
            }}>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'28px',
                    fontStyle:'normal',
                    fontWeight:500,
                    ml:'70px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                        ml:'0px',
                    },
                }}>
                    {address}
                </Typography>
                <Typography sx={{
                    color:'#1CA45F',
                    fontSize:'32px',
                    fontStyle:'normal',
                    fontWeight:500,
                    margin:'33px 70px 0px 70px',
                    wordBreak:'break-all',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'18px',
                        margin:'8px 0px 0px 0px',
                    },
                }}>
                    {defaultAddress}
                </Typography> 
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'28px',
                    fontStyle:'normal',
                    fontWeight:500,
                    mt:'102px',
                    ml:'70px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                        mt:'35px',
                        ml:'0px',
                    },
                }}>
                    {amount}
                </Typography>
                <Typography sx={{
                    color:'#1CA45F',
                    fontSize:'32px',
                    fontStyle:'normal',
                    fontWeight:500,
                    mt:'33px',
                    ml:'70px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'18px',
                        mt:'8px',
                        ml:'0px',
                    },
                }}>
                    {defaultAmount}
                </Typography>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    pl:'70px',
                    pr:'70px',
                    mt:'37px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        pl:'0px',
                        pr:'0px',
                        mt:'35px',
                    },
                }}>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'32px',
                        fontStyle:'normal',
                        fontWeight:500,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'18px',
                        },
                    }}>
                        {`${balance} :`}
                    </Typography>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'32px',
                        fontStyle:'normal',
                        fontWeight:500,
                        textAlign:'right',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'18px',
                        },
                    }}>
                        {`${BigNumber(defaultBalance).plus(BigNumber(defaultFundingBalance)).toFixed(4,1)} ${token}`}
                    </Typography>
                </Grid>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    pl:'70px',
                    pr:'70px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        pl:'0px',
                        pr:'0px',
                        mt:'8px',
                    },
                }}>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'32px',
                        fontStyle:'normal',
                        fontWeight:500,
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'18px',
                        },
                    }}>
                        {`${gasFee} :`}
                    </Typography>
                    <Typography sx={{
                        color:'#FFFFFF',
                        fontSize:'32px',
                        fontStyle:'normal',
                        fontWeight:500,
                        textAlign:'right',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'18px',
                        },
                    }}>
                        {`${defaultGasFee} ${token}`}
                    </Typography>
                </Grid>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'22px',
                    fontStyle:'normal',
                    fontWeight:500,
                    mt:"57px",
                    ml:'70px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                        mt:"8px",
                        ml:'0px',
                    },
                }}>
                    {`· ${chain} ${gasFeeRequires}`}
                </Typography>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'22px',
                    fontStyle:'normal',
                    fontWeight:500,
                    ml:'70px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                        ml:'0px',
                    },
                }}>
                    {`· ${withdrawalAmountsFrom} 50 - 9800 ${token}`}
                </Typography>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'22px',
                    fontStyle:'normal',
                    fontWeight:500,
                    ml:'70px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                        ml:'0px',
                    },
                }}>
                    {`· ${theGasAmount}`}
                </Typography>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'22px',
                    fontStyle:'normal',
                    fontWeight:500,
                    textAlign:'center',
                    width:'100%',
                    mt:'164px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                        mt:'80px',
                    },
                }}>
                    {`${estimatedArrival} : ${token} ${withdrawValue}`}
                </Typography>
                <Box sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    mt:'50px',
                    width:'100%',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        mt:'18px',
                    },
                }}>
                    <Button variant="text" sx={{
                        bgcolor:'#1CA45F',
                        color:'#FFFFFF',
                        fontSize:'30px',
                        fontStyle:'normal',
                        fontWeight:500,
                        textTransform:'none',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        height:'98px',
                        width:'750px',
                        borderRadius:'49px',
                        '&:hover': {
                            bgcolor:'#1CA45F',
                            color: '#FFFFFF',
                        },
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'18px',
                            height:'52px',
                            width:'100%',
                            borderRadius:'26px',
                        },
                    }} onClick={handleConfirmClick}>
                        {confirm}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default WithdrawContentNoEditAddress;
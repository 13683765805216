import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import backIcon from '../../assets/images/game/game-mobile-home.png';
import customerIcon from '../../assets/images/game/game-mobile-customer.png';
import { getCustomerLinkService } from '../../network/service/home';
import RoutePath from '../../tools/RoutePath';
import { getSportsGetStartUrlService } from '../../network/service/home';
import TTLoading from '../common/TTLoading';
import TTAlert from '../common/TTAlert';

const SportsContentMobile = () => {

    const navigate = useNavigate();

    const iframeRef = useRef(null)
    const [ openMenu, setOpenMenu ] = useState(false);
    const [ iframeLoaded, setIframeLoaded ] = useState(false);
    const [ sportUrl, setSportUrl ] = useState('')
    const [ alertType,setAlertType ] = useState('info');
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    const fetchStartUrl = async () => {
        try {
            const param = {
                platform: 2
            }
            const response = await getSportsGetStartUrlService(param)
            if(response.data.code.toString() === '200') {
                setSportUrl(response.data.data.game_url)
            }else if(response.data.code.toString() === '401') {
                navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.home}});
            }else {
                setAlertType('error');
                setAlertMessage(response.data.msg);
                setOpenAlert(true);
            }
        } catch (error) {
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setAlertType('error');
            setAlertMessage(errorMsg);
            setOpenAlert(true);
        }
    }

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    const handleOpenClick = () => {
        setOpenMenu(!openMenu)
    }

    const handleBackClick = () => {
        navigate(RoutePath.home);
    }

    const [ customerLink,setCustomerLink ] = useState('');
    const [ openLoading,setOpenLoading ] = useState(false);

    const handleCustomerClick = () => {
        if(customerLink === '') {
            setOpenLoading(true);
            handleFetchCustomer((link) => {
                setOpenLoading(false);
                window.location.href = link;
            });
            return;
        }
        window.location.href = customerLink;
    };

    const handleFetchCustomer = async (callBack) => {
        try {
            const response = await getCustomerLinkService();
            setCustomerLink(response.data.customer_service_link);
            callBack && callBack(response.data.customer_service_link);
        } catch (error) {
            console.log('handleFetchCustomer error:' + error);
        }
    }

    useEffect(() => {
        handleFetchCustomer();

        fetchStartUrl()

        const iframe = iframeRef.current;
        iframe.addEventListener('load', handleIframeLoad);
        return () => {
            iframe.removeEventListener('load', handleIframeLoad);
        };
    }, []);

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={alertType}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />

            <Fab variant='extended' disableRipple disableFocusRipple sx={{
                bgcolor:'transparent',
                borderRadius:'0px',
                boxShadow:'none',
                position:'fixed',
                right:'0px',
                bottom:'190px',
                float:'right',
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                height:'105px',
                padding:'0px',
                transform: !openMenu ? 'translateX(43px)' : 'translateX(px)',
                transition:'all 0.2s ease-out',
                '&:hover': {
                    bgcolor:'transparent',
                    boxShadow:'none',
                },
                '&:active': {
                    bgcolor:'transparent',
                    boxShadow:'none',
                },
            }}>
                {
                    !openMenu ? 
                    <svg onClick={handleOpenClick} xmlns="http://www.w3.org/2000/svg" width="16" height="48" viewBox="0 0 16 48" fill="none">
                        <path opacity="0.68" d="M0 8C0 3.58172 3.58172 0 8 0H16V48H8C3.58172 48 0 44.4183 0 40V8Z" fill="#58ECF5"/>
                        <path d="M11.2941 18L7.52941 23L11.2941 28" fill="#272731"/>
                    </svg> :
                    <svg onClick={handleOpenClick} xmlns="http://www.w3.org/2000/svg" width="16" height="48" viewBox="0 0 16 48" fill="none">
                        <path opacity="0.68" d="M0 8C0 3.58172 3.58172 0 8 0H16V48H8C3.58172 48 0 44.4183 0 40V8Z" fill="#58ECF5"/>
                        <path d="M7.52948 18L11.2942 23L7.52948 28" fill="#272731"/>
                    </svg>
                }
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'20px',
                    width:'43px',
                    height:'105px',
                    borderRadius:'8px 0px 0px 8px',
                    borderTop:'1px solid #58ECF5',
                    borderBottom:'1px solid #58ECF5',
                    backgroundColor:'rgba(21,21,27,0.5)'
                }}>
                    <CardMedia
                        component="img"
                        image={backIcon}
                        sx={{
                            width:'30px',
                            height:'30px',
                            objectFit:'contain',
                            cursor:'pointer'
                        }}
                        onClick={handleBackClick}
                    />
                    <CardMedia
                        component="img"
                        image={customerIcon}
                        sx={{
                            width:'30px',
                            height:'30px',
                            objectFit:'contain',
                            cursor:'pointer'
                        }}
                        onClick={handleCustomerClick}
                    />
                </Box>
            </Fab>

            <CardMedia
                ref={iframeRef}
                component='iframe'
                src={sportUrl}
                sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    border:'none',
                }}
            />

            {(sportUrl !== null && sportUrl.length > 0 && iframeLoaded) ? '' : 
                <Box sx={{
                    zIndex: 1,
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color:'#FFFFFF'
                }}>
                    <CircularProgress color="inherit" />
                </Box>
            }
        </React.Fragment>
    );
}

export default SportsContentMobile;
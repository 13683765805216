import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    casinoList: {
        popular:null,
        new:null,
        hot:null,
        fishing:null,
    },
    currentChooseCategory: {
        popular:0,
        new:0,
        hot:0,
        fishing:0,
    },
}

const casinoListSlice = createSlice({
    name: 'casinoList',
    initialState,
    reducers: {
        // popular
        casinoPopularListDataRefresh(state,action) {
            state.casinoList.popular =  action.payload.casinoPopularList;
        },
        casinoPopularListDataLoadMore(state,action) {
            state.casinoList.popular.data =  state.casinoList.popular.data.concat(action.payload.casinoPopularList.data);
        },
        casinoPopularListRefreshCategory(state,action) {
            state.currentChooseCategory.popular = action.payload.casinoPopularListCategory;
        },
        // new
        casinoNewListDataRefresh(state,action) {
            state.casinoList.new =  action.payload.casinoNewList;
        },
        casinoNewListDataLoadMore(state,action) {
            state.casinoList.new.data =  state.casinoList.new.data.concat(action.payload.casinoNewList.data);
        },
        casinoNewListRefreshCategory(state,action) {
            state.currentChooseCategory.new = action.payload.casinoNewListCategory;
        },
        // hot
        casinoHotListDataRefresh(state,action) {
            state.casinoList.hot =  action.payload.casinoHotList;
        },
        casinoHotListDataLoadMore(state,action) {
            state.casinoList.hot.data =  state.casinoList.hot.data.concat(action.payload.casinoHotList.data);
        },
        casinoHotListRefreshCategory(state,action) {
            state.currentChooseCategory.hot = action.payload.casinoHotListCategory;
        },
        // fishing
        casinoFishingListDataRefresh(state,action) {
            state.casinoList.fishing =  action.payload.casinoFishingList;
        },
        casinoFishingListDataLoadMore(state,action) {
            state.casinoList.fishing.data =  state.casinoList.fishing.data.concat(action.payload.casinoFishingList.data);
        },
        casinoFishingListRefreshCategory(state,action) {
            state.currentChooseCategory.fishing = action.payload.casinoFishingListCategory;
        },
    }
});

export const { 
    casinoPopularListDataRefresh,
    casinoPopularListDataLoadMore,
    casinoPopularListRefreshCategory,
    casinoNewListDataRefresh,
    casinoNewListDataLoadMore,
    casinoNewListRefreshCategory,
    casinoHotListDataRefresh,
    casinoHotListDataLoadMore,
    casinoHotListRefreshCategory,
    casinoFishingListDataRefresh,
    casinoFishingListDataLoadMore,
    casinoFishingListRefreshCategory,
} = casinoListSlice.actions;

export default casinoListSlice.reducer;
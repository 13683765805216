import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../../tools/MobileBreakpoint';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { walletDepositInfoService } from "../../../network/service/wallet";

const WalletContentMainLeftWithdrawNetwork = (props) => {
    const { network,chooseCallback } = props;
    const theme = useTheme();

    // const chainArr = [
    //     'ERC20','TRC20','BEP20'
    // ];

    const [ chainArr, setChainArr ] = useState([]);
    const [ chain, setChain ] = useState(0);

    const handleChainClick = (index) => {
        setChain(index);

        chooseCallback && chooseCallback(chainArr[index]);
    };

    const handleChainInfos = async () => {
        try {
            const response = await walletDepositInfoService({type:2});
            for (let index = 0; index < response.data.data.length; index++) {
                const element = response.data.data[index];
                if(element.symbol.toUpperCase() === 'USDT') {
                    setChainArr(element.chains);

                    chooseCallback && chooseCallback(element.chains[chain]);
                    break;
                }
            }
        } catch (error) {
            console.log('handleChainInfos error:'+error);
        }
    };

    useEffect(() => {
        handleChainInfos();
    },[]);

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width:'100%',
            gap:'18px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                bgcolor:'#1D1D28',
                mt:'10px',
                gap:'0px',
            },
        }}>
            <Typography sx={{
                color:'#6D728D',
                fontSize:'20px',
                fontStyle:'normal',
                fontWeight:400,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'15px',
                    mt:'8px',
                    ml:'18px',
                },
            }}>
                {network}
            </Typography>
            <Grid container sx={{
                bgcolor:'#1D1D28',
                borderRadius:'18px',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                gap:'18px',
                padding:'15px 18px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'14px 18px',
                    borderRadius:'0px',
                    gap:'8px',
                },
            }}>
                {chainArr.map((item,index) => (
                    <Box sx={{
                        bgcolor:chain === index ? '#3A3E53' : '#2A2A39',
                        borderRadius:'23px',
                        width:'155px',
                        height:'46px',
                        cursor:'pointer',
                        padding:'0px 18px',
                        display:'flex',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        gap:'13px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            borderRadius:'18px',
                            width:'calc((100% - 124px) / 3)',
                            height:'36px',
                            gap:'8px',
                        },
                    }} key={index} onClick={() => handleChainClick(index)}>
                        <Box sx={{
                            bgcolor:chain === index ? '#1CA45F' : '#3A3E53',
                            width:'18px',
                            height:'18px',
                            borderRadius:'9px',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'16px',
                                height:'16px',
                                borderRadius:'8px',
                            },
                        }}/>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:400,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'12px',
                            },
                        }}>
                            {item.platform_name}
                        </Typography>
                    </Box>
                ))}
            </Grid>
        </Box>
    );
}

export default WalletContentMainLeftWithdrawNetwork;
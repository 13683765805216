import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    recentData:null,
}

const recentSlice = createSlice({
    name: 'recent',
    initialState,
    reducers: {
        recentDataRefresh(state,action) {
            state.recentData = action.payload.recentData;
        },
        recentDataLoadMore(state,action) {
            state.recentData.total_count = action.payload.recentData.total_count;
            state.recentData.data = state.recentData.data.concat(action.payload.recentData.data);
        },
    }
});

export const { 
    recentDataRefresh,
    recentDataLoadMore,
} = recentSlice.actions;

export default recentSlice.reducer;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Fab from '@mui/material/Fab';
import bannerIcon from '../../assets/images/referral/referral-banner.png';
import ReferralContentMainPlaceholderStructure from './ReferralContentMainPlaceholderStructure';
import ReferralContentMainPlaceholderWorks from './ReferralContentMainPlaceholderWorks';
import ReferralContentMainPlaceholderRank from './ReferralContentMainPlaceholderRank';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';
import TTLoadImage from '../common/TTLoadImage';
import ReferralContentMainPlaceholderSettlement from './ReferralContentMainPlaceholderSettlement';

const ReferralContentMainPlaceholder = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t } = useTranslation();
    const textData = {
        getItNow: t("get_it_now"),
    };

    const navigate = useNavigate();

    const handleGetItNowClick = () => {
        navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.referral}});
    };

    if(screenMD) {
        return (
            <React.Fragment>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                    gap:'8px',
                }}>
                    <Box sx={{
                        width:'calc(100vw - 36px)',
                        height:'calc((100vw - 36px) * 99px / 339px)',
                        objectFit:'cover',
                        borderRadius:'8px',
                        padding:'0px 18px',
                        cursor:'pointer',
                    }} onClick={handleGetItNowClick}>
                        <TTLoadImage 
                            image={bannerIcon}
                            objectFit={'cover'}
                            width={'100%'}
                            height={'100%'}
                            borderRadius={'8px'}
                            borderRadiusMobile={'8px'}
                            skeletonBgColor={'#2A2A39'}
                        />
                    </Box>
                    
                    <ReferralContentMainPlaceholderStructure />
                    <ReferralContentMainPlaceholderWorks />
                    <ReferralContentMainPlaceholderSettlement />
                    {/* <ReferralContentMainPlaceholderReward /> */}
                    <ReferralContentMainPlaceholderRank />
                </Box>
                
                <Fab variant='extended' onClick={handleGetItNowClick} sx={{
                    bgcolor:'transparent',
                    position:'fixed',
                    bottom:'80px',
                    right:'calc((100vw - 209px) / 2)',
                    float:'right',
                    width:'209px',
                    height:'34px',
                    '&:hover': {
                        bgcolor:'transparent',
                    },
                }}>
                    <Button sx={{
                        textTransform:'none',
                        width:'209px',
                        height:'34px',
                        borderRadius:'17px',
                        background:'linear-gradient(90deg, #DE9948 0%, #FDD971 100%)',
                        color:'#511F0D',
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:500,
                    }} onClick={handleGetItNowClick}>
                        {textData.getItNow}
                    </Button>
                </Fab>
            </React.Fragment>
        );
    }
    
    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width:'100%',
        }}>
            <CardMedia
                component="img"
                image={bannerIcon}
                sx={{
                    width:'100%',
                    height:'292px',
                    objectFit:'cover',
                    borderRadius:'18px',
                    cursor:'pointer',
                }}
                onClick={handleGetItNowClick}
            />
            <Grid sx={{
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'18px',
            }}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    gap:'18px',
                    width:'588px',
                    mt:'18px',
                }}>
                    <ReferralContentMainPlaceholderStructure />
                    <ReferralContentMainPlaceholderWorks />
                    <ReferralContentMainPlaceholderSettlement />
                    {/* <ReferralContentMainPlaceholderReward /> */}
                </Box>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    gap:'18px',
                    width:'415px',
                    mt:'18px',
                }}>
                    {/* <ReferralContentMainPlaceholderExample /> */}
                    <ReferralContentMainPlaceholderRank />
                </Box>
            </Grid>
        </Box>
    );
}

export default ReferralContentMainPlaceholder;
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import SportsContent from '../component/sports/SportsContent';
import SportsContentMobile from '../component/sports/SportsContentMobile';
import { useDispatch, useSelector } from 'react-redux';
import { switchAppCategory } from '../features/appSlice';

const Sports = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const appCategory = useSelector((state) => state.app.appCategory);
    useEffect(() => {
        if(appCategory !== 'sports') {
            dispatch(switchAppCategory({appCategory:'sports'}));
        }
    }, []);

    if(screenMD) {
        return (
            <SportsContentMobile />
        );
    }

    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.sports} drawerIndex={9} noDraw={screenMD ? false : true} />
            <SportsContent />
        </React.Fragment>
    )
}

export default Sports;
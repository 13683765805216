import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HomeContentMainHistoryBlockTitle from "./HomeContentMainHistoryBlockTitle";
import HomeContentMainHistoryBlockItem from "./HomeContentMainHistoryBlockItem";
import List from "@mui/material/List";
import Grow from '@mui/material/Grow';

const HomeContentMainHistoryBlock = (props) => {
    const { title, leftTitle, middleTitle, rightTitle,historyArr,isToday } = props;

    const maxAmountLength = historyArr.reduce((maxLen, item) => {
        const profitLength = (isToday ? item.profit : item.amount).toString().length;
        return profitLength > maxLen ? profitLength : maxLen;
    }, 0);

    const [resultMaxAmountLength, setResultMaxAmountLength] = useState(maxAmountLength);

    const handleAddResultMaxAmountLength = (addNum) => {
        setResultMaxAmountLength(maxAmountLength + addNum);
    };
    useEffect(() => {
        setResultMaxAmountLength(maxAmountLength);
    }, [historyArr]);

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'start',
            alignItems:'start',
            gap:'18px',
            overflow:'hidden'
        }}>
            <Typography sx={{
                color:'#FFFFFF',
                fontSize:'28px',
                fontStyle:'normal',
                fontWeight:500,
                width:'100%',
            }}>
                {title}
            </Typography>
            <Box sx={{
                bgcolor:'#1D1D28',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                padding:'26px 18px',
                gap:'25px',
                width:'100%',
            }}>
                <HomeContentMainHistoryBlockTitle 
                    leftTitle={leftTitle}
                    middleTitle={middleTitle}
                    rightTitle={rightTitle}
                    isToday={isToday}
                />
                {/* {historyArr.map((item,index) => (
                    <HomeContentMainHistoryBlockItem 
                        item={item}
                        isToday={isToday}
                        maxAmountLength={resultMaxAmountLength}
                        handleAddResultMaxAmountLength={handleAddResultMaxAmountLength}
                        index={index+1}
                        key={index}
                    />
                ))} */}
                
                {/* <List sx={{
                    height:'400px',
                    width:'100%',
                }}>
                    <TransitionGroup>
                    {historyArr.map((item,index) => (
                        <Collapse key={
                            (isToday ? item.rank : item.game) + item.user+(isToday ? item.profit : item.amount)
                        } 
                        timeout={{enter:800,exit:0,}}
                        >
                            <Box sx={{
                                mt:index === 0 ? '0px' : '25px',
                            }}>
                                <HomeContentMainHistoryBlockItem 
                                    item={item}
                                    isToday={isToday}
                                    maxAmountLength={resultMaxAmountLength}
                                    handleAddResultMaxAmountLength={handleAddResultMaxAmountLength}
                                    index={index+1}
                                />
                            </Box>
                        </Collapse>
                    ))}
                    </TransitionGroup>
                </List> */}

                <List sx={{
                    height:'400px',
                    width:'100%',
                }}>
                    {historyArr.map((item,index) => (
                        <Grow in={true} key={
                            (isToday ? item.rank : item.game) + item.user+(isToday ? item.profit : item.amount)
                        } 
                        style={{ 
                            transformOrigin: '0 0 0',
                            transitionDelay: `${index * 80}ms`
                        }}
                        timeout={{enter:(historyArr.length - 1) * 180,exit:0,}}
                        >
                            <Box sx={{
                                mt:index === 0 ? '0px' : '25px',
                            }}>
                                <HomeContentMainHistoryBlockItem 
                                    item={item}
                                    isToday={isToday}
                                    maxAmountLength={resultMaxAmountLength}
                                    handleAddResultMaxAmountLength={handleAddResultMaxAmountLength}
                                    index={index+1}
                                />
                            </Box>
                        </Grow>
                    ))}
                </List>
                
            </Box>
        </Box>
    );
}

export default HomeContentMainHistoryBlock;
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ProfileContentPersonalInfo from './ProfileContentPersonalInfo';
import ProfileContentStatistics from './ProfileContentStatistics';
import ProfileContentBottom from './ProfileContentBottom';

const ProfileContentMain = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        profile: t("profile"),
        joinedOn: t("joined_on"),
        privacySetting: t("privacy_setting"),
        totalBets: t("total_bets"),
        totalWagered: t("total_wagered"),
        profit: t("profit"),
        accountSetting: t("account_setting"),
        email: t("email"),
        password: t("password"),
        telephone: t("telephone"),
        balanceExchange: t("balance_exchange"),
        set: t("set"),
        reset: t("reset"),
        bind: t("bind"),
        enterYourCode: t("enter_your___code"),
        youHaveCode: t("you_have___code"),
        youAreContinue: t("you_are___continue"),
        pleaseInputEmail: t("please_input__email"),
        emailIsRequired: t("email_is_required"),
        comingSoon: t("coming_soon"),
        logout: t("logout"),
        allowOtherTimes: t("allow_other___times"),
        allowOtherProfit: t("allow_other___profit"),
        allowOtherWagered: t("allow_other___wagered"),
        pleaseEnterNickname: t("please_enter_nickname"),
    };

    const [ isShowPrivacy,setIsShowPrivacy ] = useState(true);

    const handleEditNicknameClick = () => {
        setIsShowPrivacy(!isShowPrivacy);
    };

    return (
        <Grid container sx={{
            padding:'36px 60px 145px 60px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'18px 0px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                '@media': {
                    pl:'0px',
                    pr:'0px',
                    maxWidth:'1000px',
                }
            }}>
                <Typography sx={{
                    color:"#ACB3D7",
                    fontSize:'24px',
                    fontStyle:'normal',
                    fontWeight:400,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        display:'none',
                    },
                }}>
                    {textData.profile}
                </Typography>
                <ProfileContentPersonalInfo 
                    joinedOn={textData.joinedOn}
                    privacySetting={textData.privacySetting}
                    pleaseEnterNickname={textData.pleaseEnterNickname}
                    isShowPrivacy={isShowPrivacy}
                    privacySettingOnClick={handleEditNicknameClick}
                />
                <ProfileContentStatistics 
                    totalBets={textData.totalBets}
                    totalWagered={textData.totalWagered}
                    profit={textData.profit}
                    isShowPrivacy={isShowPrivacy}
                />
                <ProfileContentBottom 
                    accountSetting={textData.accountSetting}
                    email={textData.email}
                    password={textData.password}
                    telephone={textData.telephone}
                    balanceExchange={textData.balanceExchange}
                    mfa={'MFA'}
                    google={'Google'}
                    privacySetting={textData.privacySetting}
                    set={textData.set}
                    reset={textData.reset}
                    bind={textData.bind}
                    enterYourCode={textData.enterYourCode}
                    youHaveCode={textData.youHaveCode}
                    emailIsRequired={textData.emailIsRequired}
                    comingSoon={textData.comingSoon}
                    logout={textData.logout}
                    allowOtherTimes={textData.allowOtherTimes}
                    allowOtherProfit={textData.allowOtherProfit}
                    allowOtherWagered={textData.allowOtherWagered}
                />
            </Container>
        </Grid>
    );
}

export default ProfileContentMain;
import React, { useEffect } from 'react';
import RoutePath from '../tools/RoutePath';
import Customer from '../component/common/Customer';
import Header from '../component/header/Header';
import HouseGamesContent from '../component/inHouseGames/HouseGamesContent';
import { useDispatch, useSelector } from 'react-redux';
import { switchAppCategory } from '../features/appSlice';

const HouseGames = () => {
    const dispatch = useDispatch();
    const appCategory = useSelector((state) => state.app.appCategory);
    useEffect(() => {
        if(appCategory !== 'casino') {
            dispatch(switchAppCategory({appCategory:'casino'}));
        }
    }, []);

    return (
        <React.Fragment>
            <Customer />
            <Header fromPage={RoutePath.inHouseGames} drawerIndex={5}/>
            <HouseGamesContent />
        </React.Fragment>
    )
}

export default HouseGames;
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import DepositContentQR from './DepositContentQR';
import DepositContentToken from './DepositContentToken';
import DepositContentDesc from './DepositContentDesc';
import DepositContentNotice from './DepositContentNotice';

const DepositContentMain = (props) => {
    const {
        network,token,address,language,depositLimit,
    } = props;
    // if(!i18next.language.toLowerCase().includes(language.toLowerCase())) {
    //     i18next.changeLanguage(language);
    // }
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        theAddressDepositing: t("the_address___depositing"),
        itDoesDeposit: t("it_does___deposit"),
        token: t("token"), 
        network: t("network"), 
        depositAddress: t("deposit_address"), 
        youCanOnlyDeposit: t("you_can_only_deposit"), 
        toThisAmount: t("to__this___amount"), 
        theMinimumLeakage: t("the_minimum___leakage"), 
        copySuccessfully: t("copy_successfully"), 
        copyFailed: t("copy_failed"), 
        deposit: t("deposit"), 
    };

    const [ openNotice,setOpenNotice ] = useState(true);
    const handleCloseNotice = () => {
        setOpenNotice(false);
    };

    return (
        <React.Fragment>
            <DepositContentNotice 
                isOpen={openNotice}
                depositLimit={depositLimit}
                handleCloseClick={handleCloseNotice}
            />

            <Grid container sx={{
                mt:'100px',
                padding:'0px 60px 100px 60px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    mt:'64px',
                    padding:'18px 0px 8px 0px',
                },
            }}>
                <Container sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'start',
                    gap:'48px',
                    '@media': {
                        pl:'0px',
                        pr:'0px',
                        maxWidth:'1000px',
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        gap:'8px',
                    },
                }}>
                    {/* <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'24px',
                        fontStyle:'normal',
                        fontWeight:400,
                        textAlign:'center',
                        width:'100%',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            pb:'10px',
                        },
                    }}>
                        {textData.deposit}
                    </Typography> */}
                    <DepositContentQR 
                        qrText={address}
                        theAddressDepositing={textData.theAddressDepositing}
                        itDoesDeposit={textData.itDoesDeposit}
                        token={token}
                    />
                    <DepositContentToken 
                        token={textData.token}
                        network={textData.network}
                        depositAddress={textData.depositAddress}
                        copySuccessfully={textData.copySuccessfully}
                        copyFailed={textData.copyFailed}
                        address={address}
                        tokenValue={token}
                        networkValue={network}
                    />
                    <DepositContentDesc 
                        youCanOnlyDeposit={textData.youCanOnlyDeposit}
                        toThisAmount={textData.toThisAmount}
                        theMinimumLeakage={textData.theMinimumLeakage}
                        token={token}
                        network={network}
                        depositLimit={depositLimit}
                    />
                </Container>
            </Grid>
        </React.Fragment>
    )
}

export default DepositContentMain;
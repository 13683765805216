import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import logoIcon from '../../assets/images/wallet/wallet-tt-logo.png';
import Lodash from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { 
    favoriteSearchResultRefresh,
    favoriteSearchTextRefresh,
    fishingSearchResultRefresh,
    fishingSearchTextRefresh, 
    homeSearchResultRefresh, 
    homeSearchTextRefresh, 
    hotSearchResultRefresh, 
    hotSearchTextRefresh, 
    newSearchResultRefresh, 
    newSearchTextRefresh, 
    popularSearchResultRefresh, 
    popularSearchTextRefresh, 
    recentSearchResultRefresh, 
    recentSearchTextRefresh
} from '../../features/gameSearchSlice';
import { searchGamesService } from '../../network/service/game';
import HomeContentMainSlotsItem from '../home/HomeContentMainSlotsItem';
import TTGameItemSkeleton from './TTGameItemSkeleton';

const SearchInput = styled(InputBase)(({ theme }) => ({
    [`& .${inputBaseClasses.input}`]: {
      position: 'relative',
      border: '0px solid',
      backgroundColor:'transparent',
      borderColor: 'transparent',
      fontSize: '20px',
      fontWeight: 500,
      color:'#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      caretColor:'#1CA45F',
      '&::placeholder': {
        color: '#6D728D',
        opacity: 1,
      },
      '&:-webkit-autofill': {
        backgroundColor: 'transparent !important',
        color:'#FFFFFF !important',
        fontSize: '20px !important',
        transition: theme.transitions.create(['background-color','color','font-size'], {
            duration: 99999,
            easing: 'ease-in-out',
            delay: 99999,
        }),
      },
    },
}));

const TTSearchAlert = (props) => {
    const { 
        type,
        gameProvider,
        isOpen,
        closeCallback,
        topHeight,
    } = props;
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t,i18n } = useTranslation();
    const textData = {
        input_search: t("input_search"),
        search_results: t("search_results"),
        no_data: t("no_data"),
        coming_soon: t("coming_soon"),
        home: t("home"),
        popular_slots: t("popular_slots"),
        new_slots: t("new_slots"),
        hot_slots: t("hot_slots"),
        fishing: t("fishing"),
        favorite: t("favorite"),
        recent: t("recent"),
    };

    const [ isSearching,setIsSearching ] = useState(false);
    const handleCloseSearchAlert = () => {
        setIsSearching(false);
        closeCallback && closeCallback();
    };
    const selectSearchText = useSelector((state) => state.gameSearch.searchText);
    const selectSearchGames = useSelector((state) => state.gameSearch.searchGames);
    let searchText = '';
    let searchGames = null;
    if(type === 'home') {
        searchText = selectSearchText.home;
        searchGames = selectSearchGames.home;
    }else if(type === 'popular') {
        searchText = selectSearchText.popular;
        searchGames = selectSearchGames.popular;
    }else if(type === 'new') {
        searchText = selectSearchText.new;
        searchGames = selectSearchGames.new;
    }else if(type === 'hot') {
        searchText = selectSearchText.hot;
        searchGames = selectSearchGames.hot;
    }else if(type === 'fishing') {
        searchText = selectSearchText.fishing;
        searchGames = selectSearchGames.fishing;
    }else if(type === 'favorite') {
        searchText = selectSearchText.favorite;
        searchGames = selectSearchGames.favorite;
    }else if(type === 'recent') {
        searchText = selectSearchText.recent;
        searchGames = selectSearchGames.recent;
    }
    const handleSearchType = () => {
        let searchType = null;
        if(type === 'home') {
            searchType = 'home';
        }else if(type === 'popular') {
            searchType = 'popular';
        }else if(type === 'new') {
            searchType = 'new';
        }else if(type === 'hot') {
            searchType = 'hot';
        }else if(type === 'fishing') {
            searchType = 'fish';
        }else if(type === 'favorite') {
            searchType = 'favorite';
        }else if(type === 'recent') {
            searchType = 'recent';
        }
        return searchType;
    };
    const handleCategoryText = () => {
        let categoryText = '';
        if(type === 'home') {
            categoryText = textData.home;
        }else if(type === 'popular') {
            categoryText = textData.popular_slots;
        }else if(type === 'new') {
            categoryText = textData.new_slots;
        }else if(type === 'hot') {
            categoryText = textData.hot_slots;
        }else if(type === 'fishing') {
            categoryText = textData.fishing;
        }else if(type === 'favorite') {
            categoryText = textData.favorite;
        }else if(type === 'recent') {
            categoryText = textData.recent;
        }
        return categoryText;
    };

    const searchGamesCallback = useCallback(Lodash.debounce((v) => {
        handleStartSearch(v);
    }, 300), [type]);
    const handleSearchInputChanged = (e) => {
        const inputValue = e.target.value;
        
        if(type === 'home') {
            dispatch(homeSearchTextRefresh({home:inputValue}));
        }else if(type === 'popular') {
            dispatch(popularSearchTextRefresh({popular:inputValue}));
        }else if(type === 'new') {
            dispatch(newSearchTextRefresh({new:inputValue}));
        }else if(type === 'hot') {
            dispatch(hotSearchTextRefresh({hot:inputValue}));
        }else if(type === 'fishing') {
            dispatch(fishingSearchTextRefresh({fishing:inputValue}));
        }else if(type === 'favorite') {
            dispatch(favoriteSearchTextRefresh({favorite:inputValue}));
        }else if(type === 'recent') {
            dispatch(recentSearchTextRefresh({recent:inputValue}));
        }
        
        if(inputValue.length > 0) {
            searchGamesCallback(inputValue);
        }else {
            if(type === 'home') {
                dispatch(homeSearchResultRefresh({home:null}));
            }else if(type === 'popular') {
                dispatch(popularSearchResultRefresh({popular:null}));
            }else if(type === 'new') {
                dispatch(newSearchResultRefresh({new:null}));
            }else if(type === 'hot') {
                dispatch(hotSearchResultRefresh({hot:null}));
            }else if(type === 'fishing') {
                dispatch(fishingSearchResultRefresh({fishing:null}));
            }else if(type === 'favorite') {
                dispatch(favoriteSearchResultRefresh({favorite:null}));
            }else if(type === 'recent') {
                dispatch(recentSearchResultRefresh({recent:null}));
            }
        }
    };

    // 清除搜索
    const handleClearSearchClick = () => {
        if(type === 'home') {
            dispatch(homeSearchTextRefresh({home:''}));
            dispatch(homeSearchResultRefresh({home:null}));
        }else if(type === 'popular') {
            dispatch(popularSearchTextRefresh({popular:''}));
            dispatch(popularSearchResultRefresh({popular:null}));
        }else if(type === 'new') {
            dispatch(newSearchTextRefresh({new:''}));
            dispatch(newSearchResultRefresh({new:null}));
        }else if(type === 'hot') {
            dispatch(hotSearchTextRefresh({hot:''}));
            dispatch(hotSearchResultRefresh({hot:null}));
        }else if(type === 'fishing') {
            dispatch(fishingSearchTextRefresh({fishing:''}));
            dispatch(fishingSearchResultRefresh({fishing:null}));
        }else if(type === 'favorite') {
            dispatch(favoriteSearchTextRefresh({favorite:''}));
            dispatch(favoriteSearchResultRefresh({favorite:null}));
        }else if(type === 'recent') {
            dispatch(recentSearchTextRefresh({recent:''}));
            dispatch(recentSearchResultRefresh({recent:null}));
        }
    };
    // 服务器搜索
    const handleStartSearch = async (text) => {
        try {
            let param = {
                game_type:'CasinoSlot',
                name:text,
            };
            if(type !== 'home') {
                param = {
                    game_type:'CasinoSlot',
                    name:text,
                    page_name:handleSearchType(),
                };
                if(gameProvider !== undefined && gameProvider !== null && gameProvider.length > 0) {
                    param = {
                        game_type:'CasinoSlot',
                        name:text,
                        page_name:handleSearchType(),
                        game_provider:gameProvider,
                    };
                }
            }
            setIsSearching(true);
            const response = await searchGamesService(param);
            setIsSearching(false);
            if(type === 'home') {
                dispatch(homeSearchResultRefresh({home:response.data}));
            }else if(type === 'popular') {
                dispatch(popularSearchResultRefresh({popular:response.data}));
            }else if(type === 'new') {
                dispatch(newSearchResultRefresh({new:response.data}));
            }else if(type === 'hot') {
                dispatch(hotSearchResultRefresh({hot:response.data}));
            }else if(type === 'fishing') {
                dispatch(fishingSearchResultRefresh({fishing:response.data}));
            }else if(type === 'favorite') {
                dispatch(favoriteSearchResultRefresh({favorite:response.data}));
            }else if(type === 'recent') {
                dispatch(recentSearchResultRefresh({recent:response.data}));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsSearching(false);
        }
    };
    // 切换语言重新搜索当前
    useEffect(() => {
        if(searchText !== undefined && searchText !== null && searchText.length > 0) {
            handleStartSearch(searchText);
        }
    }, [i18n.language]);
    useEffect(() => {
        setIsSearching(false);
    }, [type]);

    return (
        <Dialog
            open={isOpen}
            onClose={handleCloseSearchAlert}
            sx={{
                [`& .${dialogClasses.paper}`]: {
                    bgcolor:'transparent',
                    width:'calc(100% - 380px)',
                    maxWidth:'1100px',
                    maxHeight:'100%',
                    height:topHeight < 98 ? 'calc(100% - 128px)' : 'calc(100% - 196px)',
                    margin:topHeight < 98 ? '64px 60px 64px 320px' : '98px 60px 98px 320px',
                },
            }}
        >
            <DialogContent sx={{
                bgcolor:'transparent',
                width:'100%',
                height:'100%',
                maxWidth:'100%',
                margin:'0px',
                padding:'0px',
            }}>
                <Box sx={{
                    bgcolor:'transparent',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'9px',
                    width:'100%',
                    height:'100%',
                }}>
                    <Box sx={{
                        display:'flex',
                        padding:'0px 18px',
                        justifyContent:'flex-start',
                        alignItems:'center',
                        borderRadius:'100px',
                        border:'1px solid #ACB3D7',
                        bgcolor:'#000000',
                        gap:'8px',
                        height:'48px',
                        width:'calc(100% - 36px - 4px)',
                        margin:'0px 2px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            height:'40px',
                        },
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M19.7337 18.1817L14.7383 13.6622L14.8577 13.5756C16.0882 12.2163 16.7952 10.4674 16.7952 8.57997C16.7952 4.36352 13.168 0.943604 8.4573 0.943604C3.74656 0.943604 0 4.36352 0 8.57131C0 12.7791 3.75574 16.3289 8.4573 16.3289C9.62351 16.3289 10.7254 16.1124 11.7264 15.7228L11.8641 15.6276L17.0799 20.6839C17.4472 21.0302 18.0349 21.0302 18.4022 20.6839L19.7245 19.4371C20.0918 19.0908 20.0918 18.5367 19.7245 18.1904L19.7337 18.1817ZM8.4573 14.8484C4.61892 14.8484 1.56107 12.0605 1.56107 8.62326C1.56107 5.18603 4.60973 2.39815 8.44812 2.39815C12.2865 2.39815 15.2709 5.18603 15.2709 8.62326C15.2709 12.0605 12.2865 14.8484 8.44812 14.8484H8.4573Z" fill="#6D728D"/>
                        </svg>
                        <FormControl sx={{
                            width:'calc(100% - 66px)',
                            height:'100%',
                        }}>
                            <SearchInput type="text" 
                                placeholder={textData.input_search}
                                onChange={handleSearchInputChanged}
                                autoFocus={true}
                                value={searchText}
                                sx={{
                                    width:'100%',
                                    height:'100%',
                                }}
                            />
                        </FormControl>
                        <IconButton sx={{
                            display:searchText.length > 0 ? 'block' : 'none',
                            width:'28px',
                            height:'100%',
                            ml:'8px',
                        }} onClick={handleClearSearchClick}>
                            <CardMedia
                                component="img"
                                image={closeIcon}
                                sx={{
                                    width:'20px',
                                    height:'20px',
                                    mt:'-2px',
                                    objectFit:'contain',
                                    '&:hover': {
                                        filter: 'brightness(0) invert(1)',
                                    },
                                }}
                            />
                        </IconButton>
                    </Box>

                    <Box sx={{
                        background:'linear-gradient(90deg, #282833 0%, #252530 50.35%, #282833 100%)',
                        borderRadius:'8px',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignItems:'flex-start',
                        padding:'18px',
                        width:'calc(100% - 36px)',
                        height:'calc(100% - 57px - 36px)',
                        gap:'18px',
                    }}>
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'20px',
                            fontStyle:'normal',
                            fontWeight:500,
                        }}>
                            {textData.search_results}
                        </Typography>
                        {isSearching ? 
                            <Grid container sx={{
                                display:'flex',
                                justifyContent:'flex-start',
                                alignItems:'flex-start',
                                rowGap:'32px',
                                columnGap:'10px',
                                width:'100%',
                                height:'100%',
                                overflow:'scroll',
                                scrollbarWidth: 'none', // Firefox
                                msOverflowStyle: 'none', // Internet Explorer
                                '&::-webkit-scrollbar': {
                                    display: 'none', // Chrome, Safari, Edge
                                },
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    columnGap:'15px'
                                },
                            }}>
                                {Array(14).fill('').map((_,index) => (
                                    <TTGameItemSkeleton 
                                        itemWidth={'143px'}
                                        key={index}
                                    />
                                ))}
                            </Grid> : 
                            ((searchGames !== null && searchGames.data.length > 0) ? 
                                <Grid container sx={{
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    alignItems:'flex-start',
                                    rowGap:'32px',
                                    columnGap:'10px',
                                    width:'100%',
                                    height:'100%',
                                    overflow:'scroll',
                                    scrollbarWidth: 'none', // Firefox
                                    msOverflowStyle: 'none', // Internet Explorer
                                    '&::-webkit-scrollbar': {
                                        display: 'none', // Chrome, Safari, Edge
                                    },
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        columnGap:'15px'
                                    },
                                }}>
                                    {searchGames.data.map((item,index) => (
                                        <HomeContentMainSlotsItem 
                                            width={'143px'}
                                            slots={handleCategoryText()}
                                            image={item.game_image}
                                            title={item.game_name}
                                            gameUid={item.game_uid}
                                            isCloseFreePlayMode={!item.has_demo_mode}
                                            isFavorite={item.is_favorite}
                                            favoriteNum={item.favorite_count}
                                            comingSoon={textData.coming_soon}
                                            key={index}
                                        />
                                    ))}
                                </Grid> : 
                                <Box sx={{
                                    borderRadius:'0px',
                                    display:'flex',
                                    flexDirection:'column',
                                    justifyContent:'center',
                                    alignItems:'center',
                                    gap:'18px',
                                    width:'100%',
                                    height:'100%',
                                }}>
                                    <CardMedia
                                        component="img"
                                        image={ logoIcon }
                                        sx={{
                                            objectFit:'contain',
                                            width:'185px',
                                            height:'58px',
                                        }}
                                    />
                                    <Typography sx={{
                                        color:'#6D728D',
                                        fontSize:'20px',
                                        fontStyle:'normal',
                                        fontWeight:400,
                                    }}>
                                        {textData.no_data}
                                    </Typography>
                                </Box>
                            )
                        }
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default TTSearchAlert;
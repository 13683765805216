import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import goldIcon from '../../assets/images/referral/referral-gold.png';
import avatar1 from '../../assets/images/referral/referral-rank-avatar1.png';
import avatar2 from '../../assets/images/referral/referral-rank-avatar2.png';
import avatar3 from '../../assets/images/referral/referral-rank-avatar3.png';
import avatar4 from '../../assets/images/referral/referral-rank-avatar4.png';
import avatar5 from '../../assets/images/referral/referral-rank-avatar5.png';
import avatar6 from '../../assets/images/referral/referral-rank-avatar6.png';
import avatar7 from '../../assets/images/referral/referral-rank-avatar7.png';
import avatar8 from '../../assets/images/referral/referral-rank-avatar8.png';
import avatar9 from '../../assets/images/referral/referral-rank-avatar9.png';
import avatar10 from '../../assets/images/referral/referral-rank-avatar10.png';
import coin1 from '../../assets/images/footer/footer-token-1.png';
import coin2 from '../../assets/images/footer/footer-token-2.png';
import coin3 from '../../assets/images/footer/footer-token-3.png';
import coin4 from '../../assets/images/footer/footer-token-4.png';

const ReferralContentMainPlaceholderRank = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        rank: t("rank"),
        updatedDate: t("updated_date"),
    };

    const [formattedDate, setFormattedDate] = useState('2023-9-27');
    useEffect(() => {
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setFormattedDate(formattedDate);
    }, []); 

    const arr = [
        {
            level:'01',
            avatar:avatar1,
            nickname:'Player123a123',
            token:coin1,
            amount:'$86818.8668616',
        },
        {
            level:'02',
            avatar:avatar2,
            nickname:'Player123a123',
            token:coin3,
            amount:'$86818.8668616',
        },
        {
            level:'03',
            avatar:avatar3,
            nickname:'Player123a123',
            token:coin2,
            amount:'$86818.8668616',
        },
        {
            level:'04',
            avatar:avatar4,
            nickname:'Player123a123',
            token:coin4,
            amount:'$86818.8668616',
        },
        {
            level:'05',
            avatar:avatar5,
            nickname:'Player123a123',
            token:coin3,
            amount:'$86818.8668616',
        },
        {
            level:'06',
            avatar:avatar6,
            nickname:'Player123a123',
            token:coin3,
            amount:'$86818.8668616',
        },
        {
            level:'07',
            avatar:avatar7,
            nickname:'Player123a123',
            token:coin1,
            amount:'$86818.8668616',
        },
        {
            level:'08',
            avatar:avatar8,
            nickname:'Player123a123',
            token:coin1,
            amount:'$86818.8668616',
        },
        {
            level:'09',
            avatar:avatar9,
            nickname:'Player123a123',
            token:coin4,
            amount:'$86818.8668616',
        },
        {
            level:'10',
            avatar:avatar10,
            nickname:'Player123a123',
            token:coin1,
            amount:'$86818.8668616',
        },
    ];

    return (
        <Box sx={{
            bgcolor:'#1D1D28',
            borderRadius:'18px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            alignSelf:'stretch',
            gap:'18px',
            padding:'18px',
            width:'calc(100% - 36px)',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                borderRadius:'0px',
            },
        }}>
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
            }}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'8px'
                }}>
                    <CardMedia
                        component="img"
                        image={goldIcon}
                        sx={{
                            width:'16px',
                            height:'17px',
                            objectFit:'contain',
                        }}
                    />
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:400,
                    }}>
                        {textData.rank}
                    </Typography>
                </Box>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'10px',
                    fontStyle:'normal',
                    fontWeight:400,
                }}>
                    {`${textData.updatedDate} ${formattedDate}`}
                </Typography>
            </Grid>
            
            <Box sx={{
                bgcolor:'#171721',
                borderRadius:'18px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'51px',
                padding:'26px 18px',
                width:'calc(100% - 36px)',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    gap:'48px',
                    bgcolor:'transparent',
                },
            }}>
                {arr.map((item,index) => (
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width:'100%',
                    }} key={index}>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                        }}>
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'12px',
                                fontStyle:'normal',
                                fontWeight:400,
                                width:'52px',
                            }}>
                                {item.level}
                            </Typography>
                            <CardMedia
                                component="img"
                                image={item.avatar}
                                sx={{
                                    width:'18px',
                                    height:'18px',
                                    objectFit:'contain',
                                }}
                            />
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'12px',
                                fontStyle:'normal',
                                fontWeight:400,
                                width:'52px',
                                ml:'8px',
                            }}>
                                {item.nickname}
                            </Typography>
                        </Box>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-end',
                            alignItems:'center',
                            gap:'8px',
                        }}>
                            <CardMedia
                                component="img"
                                image={item.token}
                                sx={{
                                    width:'18px',
                                    height:'18px',
                                    objectFit:'contain',
                                }}
                            />
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'12px',
                                fontStyle:'normal',
                                fontWeight:400,
                                textAlign:'right',
                            }}>
                                {item.amount}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Box>
        </Box>
    );
}

export default ReferralContentMainPlaceholderRank;

import React from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import CardMedia from '@mui/material/CardMedia';
import { styled } from "@mui/material/styles";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import depositIcon from '../../assets/images/wallet/wallet-deposit.png';
import withdrawIcon from '../../assets/images/wallet/wallet-withdraw.png';

const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))(({ theme }) => ({
    backgroundColor:'#1D1D28',
    borderRadius:'18px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    width:'100%',
    marginTop:'18px',
    gap:'18px',
    [theme.breakpoints.down(MobileBreakpoint.match)]: {
        justifyContent:'space-between',
        borderRadius:'8px',
        margin:'0px 18px',
        width:'calc(100% - 36px)',
        gap:'0px',
    },
    [`& .${tabsClasses.indicator}`]: {
      borderRadius:'18px',
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#3A3E53",
      height: "100%",
      zIndex: theme.zIndex.Tab - 1,
      [theme.breakpoints.down(MobileBreakpoint.match)]: {
        borderRadius:'8px',
      },
    },
    [`& .${tabsClasses.indicatorSpan}`]: {
      maxWidth: 40,
      height: "1px",
      width: "100%",
      backgroundColor: "transparent"
    }
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    color: '#ACB3D7',
    zIndex: 2,
    width:'calc(100%/2)',
    height:'84px',
    borderRadius:'8px',
    [theme.breakpoints.down(MobileBreakpoint.match)]: {
        fontSize:'10px',
        width:'calc(100%/2)',
        height:'66px',
        gap:'5px',
    },
    "&.Mui-selected": {
      color: "#ACB3D7"
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)"
    }
  })
);

const WalletContentMainLeftMenu = (props) => {
    const { deposit,withdraw,menuIndex,menuOnClick } = props;
    const theme = useTheme();

    const btnArr = [
        {title:deposit,image:depositIcon},
        {title:withdraw,image:withdrawIcon}
    ];
    const handleBtnClick = (event, newValue) => {
        menuOnClick && menuOnClick(newValue);
    };

    return (
        <StyledTabs
            value={menuIndex}
            onChange={handleBtnClick}
            aria-label="styled tabs example"
        >
            <StyledTab icon={
                <CardMedia
                component="img"
                image={ depositIcon }
                sx={{
                    objectFit:'contain',
                    width:'36px',
                    height:'36px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'28px',
                        height:'28px',
                    },
                }}
            />
            } label={deposit} />
            <StyledTab icon={
                <CardMedia
                component="img"
                image={ withdrawIcon }
                sx={{
                    objectFit:'contain',
                    width:'36px',
                    height:'36px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'28px',
                        height:'28px',
                    },
                }}
            />
            } label={withdraw} />
        </StyledTabs>
    );

    // return (
    //     <Grid container sx={{
    //         bgcolor:'#1D1D28',
    //         borderRadius:'18px',
    //         display:'flex',
    //         justifyContent:'space-around',
    //         alignItems:'center',
    //         width:'100%',
    //         mt:'18px',
    //         gap:'18px',
    //         [theme.breakpoints.down(MobileBreakpoint.match)]: {
    //             justifyContent:'space-between',
    //             borderRadius:'8px',
    //             margin:'0px 18px',
    //             width:'calc(100% - 36px)',
    //             gap:'0px',
    //         },
    //     }}>
    //         {btnArr.map((item,index) => (
    //             <Button variant='contained' sx={{
    //                 display:'flex',
    //                 flexDirection:'column',
    //                 justifyContent:'center',
    //                 alignItems:'center',
    //                 textDecoration:'none',
    //                 textTransform:'none',
    //                 color:'#ACB3D7',
    //                 fontSize:'18px',
    //                 fontStyle:'normal',
    //                 fontWeight:400,
    //                 textAlign:'center',
    //                 width:'159px',
    //                 height:'84px',
    //                 borderRadius:'8px',
    //                 bgcolor:(menuIndex === index) ? '#3A3E53' : 'transparent',
    //                 '&:hover': {
    //                     background: '#3A3E53',
    //                 },
    //                 [theme.breakpoints.down(MobileBreakpoint.match)]: {
    //                     fontSize:'10px',
    //                     width:'calc(100%/2)',
    //                     height:'66px',
    //                     gap:'5px',
    //                 },
    //             }} key={index} onClick={() => handleBtnClick(index)}>
    //                 <CardMedia
    //                     component="img"
    //                     image={ item.image }
    //                     sx={{
    //                         objectFit:'contain',
    //                         width:'36px',
    //                         height:'36px',
    //                         [theme.breakpoints.down(MobileBreakpoint.match)]: {
    //                             width:'28px',
    //                             height:'28px',
    //                         },
    //                     }}
    //                 />
    //                 {item.title}
    //             </Button>
    //         ))}
    //     </Grid>
    // );
}

export default WalletContentMainLeftMenu;
import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { getSportsGetStartUrlService } from '../../network/service/home';
import TTAlert from '../common/TTAlert';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const SportsContentMain = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const iframeRef = useRef(null)
    const [ iframeLoaded, setIframeLoaded ] = useState(false);
    const [ sportUrl, setSportUrl ] = useState('')
    const [ alertType,setAlertType ] = useState('info');
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    const fetchStartUrl = async () => {
        try {
            const param = {
                platform: 1
            }
            const response = await getSportsGetStartUrlService(param)
            if(response.data.code.toString() === '200') {
                setSportUrl(response.data.data.game_url)
            }else if(response.data.code.toString() === '401') {
                navigate(RoutePath.login,{state: {type:'login',fromPage:RoutePath.home}});
            }else {
                setAlertType('error');
                setAlertMessage(response.data.msg);
                setOpenAlert(true);
            }
        } catch (error) {
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setAlertType('error');
            setAlertMessage(errorMsg);
            setOpenAlert(true);
        }
    }

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };
    useEffect(() => {
        fetchStartUrl()

        const iframe = iframeRef.current;
        iframe.addEventListener('load', handleIframeLoad);
        return () => {
            iframe.removeEventListener('load', handleIframeLoad);
        };
    }, [])

    return (
        <Grid container sx={{
            padding:'48px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'18px',
            },
        }}>
            <Container sx={{
                // bgcolor:'#bbbbbb',
                display:'flex',
                flexDirection:'column',
                justifyContent:'start',
                alignItems:'start',
                minHeight:'500px',
                '@media': {
                    padding: '0px',
                    maxWidth:'100%',
                }
            }}>
                <TTAlert 
                    type={alertType}
                    isOpen={openAlert}
                    message={alertMessage}
                    closeCallback={handleAlertClose}
                />

                <CardMedia
                    ref={iframeRef}
                    component='iframe'
                    src={sportUrl}
                    sx={{
                        width:'100%',
                        height:'calc(100vh - 200px)',
                        border:'none',
                    }}
                /> 
                
                {(sportUrl !== null && sportUrl.length > 0 && iframeLoaded) ? '' : 
                    <Box sx={{
                        zIndex: 1,
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color:'#FFFFFF'
                    }}>
                        <CircularProgress color="inherit" />
                    </Box>
                }
            </Container>
        </Grid>
    )
}

export default SportsContentMain;
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { switchClasses } from '@mui/material/Switch';
import thumbIcon from '../../assets/images/common/common-switch-thumb.png';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 60,
    height: 26,
    padding: 5,
    [`& .${switchClasses.switchBase}`]: {
      margin: 1,
      padding: 0,
      color:'#DADADA',
      transform: 'translateX(0px)',
      '&.Mui-checked': {
        color:'#DADADA',
        transform: 'translateX(34px)',
        [`& .${switchClasses.thumb}:before`]: {
            backgroundImage:thumbIcon,
        },
        [`& + .${switchClasses.track}`]: {
          opacity: 1,
          backgroundColor: '#1CA45F',
          borderColor: 'transparent',
        },
      },
    },
    [`& .${switchClasses.thumb}`]: {
      width: 24,
      height: 24,
      marginTop:1,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage:thumbIcon,
      },
    },
    [`& .${switchClasses.track}`]: {
      opacity: 1,
      border: '1px solid',
      borderColor: 'rgba(255,255,255,0.3)',
      backgroundColor: '#141620',
      borderRadius: 26 / 2,
    },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 60,
  height: 30,
  padding: 0,
  [`& .${switchClasses.switchBase}`]: {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#0D0F13',
      [`& + .${switchClasses.track}`]: {
        backgroundColor: '#1CA45F',
        opacity: 1,
        border: 0,
      },
      [`&.Mui-disabled + .${switchClasses.track}`]: {
        opacity: 0.5,
      },
    },
    [`&.Mui-focusVisible .${switchClasses.thumb}`]: {
      color: '#33cf4d',
      border: '6px solid #0D0F13',
    },
    [`&.Mui-disabled .${switchClasses.thumb}`]: {
      color: '#0D0F13',
    },
    [`&.Mui-disabled + .${switchClasses.track}`]: {
      opacity:  0.7,
    },
  },
  [`& .${switchClasses.thumb}`]: {
    boxSizing: 'border-box',
    color: '#0D0F13',
    width: 26,
    height: 26,
  },
  [`& .${switchClasses.track}`]: {
    borderRadius: 30 / 2,
    backgroundColor: '#6D728D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const TTSwitch = (props) => {
    const {
        iOSStyle,defaultChecked,value,onChangeClick
    } = props;

    if(iOSStyle) {
        return (
          <IOSSwitch 
              checked={value}
              defaultChecked={defaultChecked}
              onChange={onChangeClick}
          />
      );
    }

    return (
        <CustomSwitch 
            checked={value}
            defaultChecked={defaultChecked}
            onChange={onChangeClick}
        />
    );
}

export default TTSwitch;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import selectedIcon from '../../assets/images/poker/poker-selected.webp';

const PokerContentSelected = (props) => {
    const { title } = props;
    const theme = useTheme();

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            gap:'8px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                gap:'6px',
            },
        }}>
            <CardMedia
                component="img"
                image={selectedIcon}
                sx={{
                    width:'24px',
                    height:'24px',
                    objectFit:'contain',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'17px',
                        height:'17px',
                    },
                }}
            />
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'16px',
                fontStyle:'normal',
                fontWeight:700,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                },
            }}>
                {title}
            </Typography>
        </Box>
    );
}

export default PokerContentSelected;
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';

const TTLoadImage = (props) => {
    const {
        image,objectFit,width,height,boxWidth,boxHeight,borderRadius,borderRadiusMobile,skeletonBgColor,
    } = props;
    const theme = useTheme();

    const [imageLoaded, setImageLoaded] = useState(false);
    const handleImageLoad = () => {
        // setTimeout(() => {
        //     setImageLoaded(true);
        // }, 5000);
        setImageLoaded(true);
    };

    return (
        <Box sx={{
            position:'relative',
            width:boxWidth === undefined ? width : boxWidth,
            height:boxHeight === undefined ? height : boxHeight,
            overflow:'hidden',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            borderRadius:borderRadius,
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                borderRadius:borderRadiusMobile,
            },
        }}>
            <CardMedia
                component="img"
                image={ image }
                onLoad={handleImageLoad}
                sx={{
                    objectFit:objectFit,
                    width:width,
                    height:height,
                }}
            /> 
            {!imageLoaded ?
                <Skeleton 
                variant="rounded" 
                animation="wave"
                sx={{
                    bgcolor:(skeletonBgColor !== undefined && skeletonBgColor.length > 0) ? skeletonBgColor : '#2A2A39',
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    borderRadius:borderRadius,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        borderRadius:borderRadiusMobile,
                    },
                }} /> : ''
            }
        </Box>
    );
}

export default TTLoadImage;
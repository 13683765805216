import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    rank:[],
    round:[],
    slots:null,
    popularSlots:null,
    newSlots:null,
    hotSlots:null,
    liveCasinos:null,
}

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        biggestTodayRefresh(state,action) {
            state.rank = action.payload.rank;
            // console.log('betRankRefresh state:',JSON.stringify(state));
        },
        latestRoundRefresh(state,action) {
            state.round = action.payload.round;
            // console.log('betRankRefresh state:',JSON.stringify(state));
        },
        slotsRefresh(state,action) {
            state.slots = action.payload.slots;
        },
        popularSlotsRefresh(state,action) {
            state.popularSlots = action.payload.popularSlots;
        },
        newSlotsRefresh(state,action) {
            state.newSlots = action.payload.newSlots;
        },
        hotSlotsRefresh(state,action) {
            state.hotSlots = action.payload.hotSlots;
        },
        liveCasinosRefresh(state,action) {
            state.liveCasinos = action.payload.liveCasinos;
        },
    }
});

export const { 
    biggestTodayRefresh,
    latestRoundRefresh,
    slotsRefresh,
    popularSlotsRefresh,
    newSlotsRefresh,
    hotSlotsRefresh,
    liveCasinosRefresh,
} = homeSlice.actions;

export default homeSlice.reducer;
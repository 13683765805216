import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Menu, { menuClasses } from '@mui/material/Menu';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { popoverClasses } from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import backIcon from '../../assets/images/wallet/wallet-back.png';
import arrowRightIcon from '../../assets/images/drawer/drawer-arrow_right.png';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    [`& .${paperClasses.root}`]: {
      padding:'0px 0px 18px 0px',
      marginTop: 10,
      bgcolor:'#2A2A39',
      borderRadius: 18,
      minWidth:'136px',
      color: '#FFFFFF',
      [`& .${menuClasses.list}`]: {
        padding: '0px',
      },
      [`& .${menuItemClasses.root}`]: {
        '&:active': {
          backgroundColor: alpha(
            '#000000',
            0.8,
          ),
        },
      },
    },
    [`& .${popoverClasses.paper}`]: {
        backgroundColor: '#2A2A39',
    }
}));

const TokenRecordContentHeader = (props) => {
    const {
        recordOf,token,all,deposit,withdraw,back,category,categoryOnClick,
    } = props;
    const menuTitleArr = [deposit,withdraw];

    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const [ currentCategory,setCurrentCategory ] = useState(menuTitleArr[category]);

    const handleCategoryClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleBackClick = () => {
        navigate(-1);
    };

    // menu
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (index) => {
        handleClose();
        setCurrentCategory(menuTitleArr[index]);
        categoryOnClick && categoryOnClick(index);
    }

    return (
        <React.Fragment>
            {screenMD ? 
                <React.Fragment>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-end',
                        alignItems:'center',
                        width:'calc(100% - 36px)',
                        padding:'0px 18px',
                    }}>
                        <Button variant='contained' sx={{
                            bgcolor:'#2A2A39',
                            borderRadius:'18px',
                            height:'46px',
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:400,
                            textTransform:'none',
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            gap:'13px',
                            minWidth:'104px',
                            padding:'0px 18px',
                            '&:hover': {
                                bgcolor:'#2A2A39',
                            }
                        }} onClick={handleCategoryClick}>
                            {currentCategory}
                            <CardMedia
                                component="img"
                                image={arrowRightIcon}
                                sx={{
                                    width:'8px',
                                    height:'18px',
                                    objectFit:'contain',
                                }}
                            />
                        </Button>
                    </Box>
                    <Typography sx={{
                        color:"#ACB3D7",
                        fontSize:'15px',
                        fontStyle:'normal',
                        fontWeight:400,
                        mt:'23px',
                        ml:'18px'
                    }}>
                        {`${recordOf} ${token}`}
                    </Typography>
                </React.Fragment>
                 :
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    gap:'18px',
                }}>
                    <Typography sx={{
                        color:"#ACB3D7",
                        fontSize:'24px',
                        fontStyle:'normal',
                        fontWeight:400
                    }}>
                        {`${recordOf} ${token}`}
                    </Typography>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'flex-end',
                        alignItems:'center',
                        gap:'18px',
                    }}>
                        <Button variant='contained' sx={{
                            bgcolor:'#2A2A39',
                            borderRadius:'23px',
                            height:'46px',
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:400,
                            textTransform:'none',
                            display:'flex',
                            justifyContent:'space-between',
                            alignItems:'center',
                            gap:'13px',
                            minWidth:'104px',
                            padding:'0px 18px',
                            '&:hover': {
                                bgcolor:'#2A2A39',
                            }
                        }} onClick={handleCategoryClick}>
                            {currentCategory}
                            <CardMedia
                                component="img"
                                image={arrowRightIcon}
                                sx={{
                                    width:'8px',
                                    height:'18px',
                                    objectFit:'contain',
                                }}
                            />
                        </Button>
                        <Button variant='contained' sx={{
                            bgcolor:'#2A2A39',
                            borderRadius:'23px',
                            height:'46px',
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:400,
                            textTransform:'none',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            gap:'13px',
                            padding:'0px 18px',
                            '&:hover': {
                                bgcolor:'#2A2A39',
                            }
                        }} onClick={handleBackClick}>
                            <CardMedia
                                component="img"
                                image={backIcon}
                                sx={{
                                    width:'22px',
                                    height:'18px',
                                    objectFit:'contain',
                                }}
                            />
                            {back}
                        </Button>
                    </Box>
                </Grid>
            }

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuTitleArr.map((item,index) => (
                    <MenuItem onClick={() => handleMenuClick(index)} disableRipple key={index} sx={{
                        display:'flex',
                        justifyContent:'start',
                        alignItems:'center',
                        mt:'18px',
                        pl:'18px',
                        pr:'18px',
                        color:'#ACB3D7',
                        fontSize:'12px',
                        fontStyle:'normal',
                        fontWeight:400
                    }}>
                        {item}
                    </MenuItem>
                ))}
            </StyledMenu>
        </React.Fragment>
    );
}

export default TokenRecordContentHeader;
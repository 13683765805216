import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import closeIcon from '../../assets/images/login-and-register/login-and-register-close.png';
import chooseBorderIcon from '../../assets/images/profile/profile-avatar-choose-border.png';
import TTAlert from "../common/TTAlert";
import TTLoading from "../common/TTLoading";
import avatar1 from '../../assets/images/profile/avatar/profile-avatar1.png';
import avatar2 from '../../assets/images/profile/avatar/profile-avatar2.png';
import avatar3 from '../../assets/images/profile/avatar/profile-avatar3.png';
import avatar4 from '../../assets/images/profile/avatar/profile-avatar4.png';
import avatar5 from '../../assets/images/profile/avatar/profile-avatar5.png';
import avatar6 from '../../assets/images/profile/avatar/profile-avatar6.png';
import avatar7 from '../../assets/images/profile/avatar/profile-avatar7.png';
import avatar8 from '../../assets/images/profile/avatar/profile-avatar8.png';
import avatar9 from '../../assets/images/profile/avatar/profile-avatar9.png';
import avatar10 from '../../assets/images/profile/avatar/profile-avatar10.png';
import avatar11 from '../../assets/images/profile/avatar/profile-avatar11.png';
import avatar12 from '../../assets/images/profile/avatar/profile-avatar12.png';
import avatar13 from '../../assets/images/profile/avatar/profile-avatar13.png';
import avatar14 from '../../assets/images/profile/avatar/profile-avatar14.png';
import avatar15 from '../../assets/images/profile/avatar/profile-avatar15.png';
import avatar16 from '../../assets/images/profile/avatar/profile-avatar16.png';
import { updateUserInfoService } from "../../network/service/user";
import { useDispatch, useSelector } from "react-redux";
import { userRefreshUserInfo } from "../../features/userSlice";

const ProfileContentEditAvatarAlertContent = (props) => {
    const {
        selectAvatar,confirm,defaultIndex,handleCloseClick,handleConfirmClick
    } = props;
    const theme = useTheme();

    const [ selectIndex, setSelectIndex ] = useState(defaultIndex);

    const avatarArr = [
        avatar1,avatar2,avatar3,avatar4,avatar5,avatar6,avatar7,avatar8,
        avatar9,avatar10,avatar11,avatar12,avatar13,avatar14,avatar15,avatar16,
    ];

    const handleSelectAvatarClick = (index) => {
        setSelectIndex(index);
    };

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'flex-start',
            padding:'36px 28px',
            width:'calc(100% - 56px)',
            overflow:'scroll',
            scrollbarWidth: 'none', // Firefox
            msOverflowStyle: 'none', // Internet Explorer
            '&::-webkit-scrollbar': {
                display: 'none', // Chrome, Safari, Edge
            },
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'0px',
                width:'100%',
            },
        }}>
            <Grid container sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                width:'100%',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'0px 18px',
                    width:'100%',
                    height:'64px',
                },
            }}>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'24px',
                    fontStyle:'normal',
                    fontWeight:400,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                    },
                }}>
                    {selectAvatar}
                </Typography>
                <CardMedia
                    component='img'
                    image={closeIcon}
                    sx={{
                        objectFit:'contain',
                        width:'32px',
                        height:'32px',
                        cursor:'pointer',
                        '&:hover': {
                            filter: 'brightness(0) invert(1)',
                        },
                    }}
                    onClick={handleCloseClick}
                />
            </Grid>
            <Grid container sx={{
                bgcolor:'#2A2A39',
                borderRadius:'18px',
                padding:'17px 50px',
                width:'100%',
                height:avatarArr.length > 16 ? '480px' : '444px',
                rowGap:'35px',
                columnGap:'42px',
                mt:'32px',
                display:'flex',
                justifyContent:'flex-start',
                alignContent:'flex-start',
                alignItems:'flex-start',
                overflow:'scroll',
                scrollbarWidth: 'none', // Firefox
                msOverflowStyle: 'none', // Internet Explorer
                '&::-webkit-scrollbar': {
                    display: 'none', // Chrome, Safari, Edge
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    borderRadius:'0px',
                    mt:'0px',
                    height:'calc((100vw - 138px) + 72px + 25px * 3)',
                    padding:'36px 18px',
                    rowGap:'25px',
                    columnGap:'34px',
                },
            }}>
                
                {avatarArr.map((item,index) => (
                    <Box sx={{
                        width:'76px',
                        height:'76px',
                        borderRadius: '50%',
                        position: 'relative',
                        cursor:'pointer',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'calc((100vw - 138px) / 4)',
                            height:'calc((100vw - 138px) / 4)',
                        },
                    }} onClick={() => handleSelectAvatarClick(index)} key={index}>
                        <CardMedia
                            component='img'
                            image={item}
                            sx={{
                                width:'100%',
                                height:'100%',
                                objectFit: 'cover',
                            }}
                        />
                        <CardMedia
                            component='img'
                            image={chooseBorderIcon}
                            sx={{
                                display:selectIndex === index ? 'block' : 'none',
                                width:'calc(100% + 6px)',
                                height:'calc(100% + 6px)',
                                objectFit: 'cover',
                                position:'absolute',
                                top: -3,
                                left: -3,
                                right: 0,
                                bottom: 0,
                            }}
                        />
                    </Box>
                    
                ))}
            </Grid>
            <Button variant='text' sx={{
                textDecoration:'none',
                bgcolor:'#3A3E53',
                borderRadius:'27px',
                height:'54px',
                width:'100%',
                mt:'49px',
                color:'#FFFFFF',
                fontSize:'18px',
                fontStyle:'normal',
                fontWeight:500,
                '&:hover': {
                    bgcolor:'#3A3E53',
                },
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    margin:'18px',
                    width:'calc(100% - 36px)',
                },
            }} onClick={() => handleConfirmClick(selectIndex)}>
                {confirm}
            </Button>
        </Box>
    );
}

const ProfileContentEditAvatarAlert = (props) => {
    const { 
        isOpen,
        defaultIndex,
        closeCallback,
    } = props;

    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        selectAvatar: t("select_avatar"),
        confirm: t("confirm"),
        pleaseChooseAvatar: t("please_choose___avatar"),
    }

    const [ openLoading,setOpenLoading ] = useState(false);

    // 登录状态
    const userId = useSelector((state) => state.user.user.pk);

    const handleCloseClick = () => {
        closeCallback && closeCallback();
    };
    
    const handleConfirmClick = async (selectIndex) => {
        if(selectIndex === defaultIndex) {
            closeCallback && closeCallback();
            return;
        }
        if(selectIndex < 0) {
            setType('info');
            setAlertMessage(textData.pleaseChooseAvatar);
            setOpen(true);
            return;
        }
        setOpenLoading(true);
        try {
            let params = {
                profile_image_url:`avatars://profile-avatar${selectIndex+1}`,
                userId:userId,
            };
            const response = await updateUserInfoService(params);
            dispatch(userRefreshUserInfo(response.data));
            setOpenLoading(false);
            closeCallback && closeCallback();
        } catch (error) {
            setOpenLoading(false);
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if(error.response.status === 500) {
                errorMsg = error.message;
            }
            setType('error');
            setAlertMessage(errorMsg);
            setOpen(true);
        }
    };

    //alert 
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <TTLoading 
                open={openLoading}
                isForce={true}
            />
            <TTAlert 
                type={type}
                isOpen={open}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />

            {screenMD ? 
                <Drawer
                    anchor='bottom'
                    open={isOpen}
                    onClose={handleCloseClick}
                    sx={{
                        [`& .${drawerClasses.paper}`]: {
                            bgcolor:'#1D1D28',
                            borderRadius:'18px 18px 0px 0px',
                            padding:'0px',
                        }
                    }}
                >
                    <ProfileContentEditAvatarAlertContent 
                        selectAvatar={textData.selectAvatar}
                        confirm={textData.confirm}
                        defaultIndex={defaultIndex}
                        handleCloseClick={handleCloseClick}
                        handleConfirmClick={handleConfirmClick}
                    />
                </Drawer> : 
                <Dialog
                    open={isOpen}
                    onClose={handleCloseClick}
                    sx={{
                        [`& .${dialogClasses.paper}`]: {
                            bgcolor:'#1D1D28',
                            borderRadius:'18px',
                        },
                    }}
                >
                    <DialogContent sx={{
                        bgcolor:'#1D1D28',
                        width:'598px',
                        m:'0px',
                        p:'0px',
                        overflow:'scroll',
                        scrollbarWidth: 'none', // Firefox
                        msOverflowStyle: 'none', // Internet Explorer
                        '&::-webkit-scrollbar': {
                            display: 'none', // Chrome, Safari, Edge
                        },
                    }}>
                        <ProfileContentEditAvatarAlertContent 
                            selectAvatar={textData.selectAvatar}
                            confirm={textData.confirm}
                            defaultIndex={defaultIndex}
                            handleCloseClick={handleCloseClick}
                            handleConfirmClick={handleConfirmClick}
                        />
                    </DialogContent>
                </Dialog>
            }
            
        </React.Fragment>
    );
}

export default ProfileContentEditAvatarAlert;
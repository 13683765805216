import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import flagshipIcon from '../../assets/images/poker/poker-flagship.webp';
import mobileFlagshipIcon from '../../assets/images/poker/poker-mobile-flagship.webp';
import PokerContentSelected from './PokerContentSelected';

const PokerContentFlagship = (props) => {
    const {
        ourFlagshipPoker,theRevenueSource,thereAreRake,theOddsInsurance
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignContent:'center',
            alignItems:'center',
            gap:'32px',
            mt:'100px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                gap:'18px',
                mt:'18px',
            },
        }}>
            {screenMD ? '' : 
                <CardMedia
                    component="img"
                    image={flagshipIcon}
                    sx={{
                        width:'470px',
                        height:'317px',
                        objectFit:'cover',
                    }}
                />
            }
            
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:'490px',
                gap:'18px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'100%',
                },
            }}>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'48px',
                    fontStyle:'normal',
                    fontWeight:700,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'30px',
                    },
                }}>
                    {ourFlagshipPoker}
                </Typography>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'16px',
                    fontStyle:'normal',
                    fontWeight:400,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                    },
                }}>
                    {theRevenueSource}
                </Typography>
                <PokerContentSelected 
                    title={thereAreRake}
                />
                <PokerContentSelected 
                    title={theOddsInsurance}
                />
            </Box>

            {screenMD ? 
                <CardMedia
                    component="img"
                    image={mobileFlagshipIcon}
                    sx={{
                        width:'100%',
                        height:'calc((100vw - 36px) * 202 / 335)',
                        objectFit:'cover',
                        borderRadius:'13px',
                    }}
                /> : ''
            }
        </Grid>
    );
}

export default PokerContentFlagship;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import topIcon from '../../assets/images/home/home-top.webp';
import topMobileIcon from '../../assets/images/home/home-top-mobile.webp';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../tools/RoutePath';

const HomeContentMainTop = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const navigate = useNavigate();
    const { t } = useTranslation();
    const textData = {
        sign_up__to: t("sign_up__to"),
        play_jiu__win: t("play_jiu__win"),
        cash_prize: t("cash_prize"),
        sign_up_now: t("sign_up_now"),
        deposit_now: t("deposit_now"),
    };

    const token = useSelector((state) => state.user.access_token); 

    const handleBtnClick = () => {
        if(token !== undefined && token !== null && token.length > 0) {
            navigate(RoutePath.wallet);
        }else {
            navigate(RoutePath.register,{state: {type:'register',fromPage:RoutePath.home}});
        }
    }

    return (
        <Grid sx={{
            width:'100%',
            position:'relative',
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            padding:'0px 40px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                padding:'0px',
                pt:'18px',
            },
        }}>
            <CardMedia
                component="img"
                image={screenMD ? topMobileIcon : topIcon}
                sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0px',
                    width:'100%',
                    height:'100%',
                    objectFit:'cover',
                }}
            />
            <Box sx={{
                width:'100%',
                maxWidth:'768px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'center',
                alignSelf:'stretch',
                padding:'39px',
                zIndex:1,
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    maxWidth:'auto',
                    padding:'18px',
                    justifyContent:'center',
                },
            }}>
                <Typography sx={{
                    color:'#94A3B0',
                    fontSize:'14px',
                    fontWeight:700,
                    textTransform:'capitalize',
                    width:'100%',
                    lineHeight:'normal',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        textAlign:'center',
                        fontSize:'12px',
                        mt:'10px'
                    }
                }}>
                    {textData.sign_up__to}
                </Typography>
                <Typography sx={{
                    mt:'8px',
                    color:'#FFFFFF',
                    fontSize:'24px',
                    fontWeight:700,
                    width:'100%',
                    lineHeight:'normal',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        textAlign:'center',
                        fontSize:'20px',
                    }
                }}>
                    {textData.play_jiu__win}
                </Typography>
                <Typography sx={{
                    mt:'4px',
                    fontSize:'48px',
                    fontWeight:700,
                    width:'100%',
                    lineHeight:'normal',
                    background:'linear-gradient(90deg, #F05F60 0%, #D02443 100%)',
                    backgroundClip:'text',
                    WebkitBackgroundClip:'text',
                    WebkitTextFillColor:'transparent',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        textAlign:'center',
                        fontSize:'36px',
                        mt:'8px',
                    }
                }}>
                    {textData.cash_prize}
                </Typography>
                <Box sx={{
                    mt:'18px',
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        justifyContent:'center',
                        mt:'32px',
                    }
                }}>
                <Button sx={{
                    textTransform:'none',
                    bgcolor:'#8A2525',
                    borderRadius:'22px',
                    height:'40px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    color:'#FFFFFF',
                    fontSize:'20px',
                    fontWeight:700,
                    lineHeight:'normal',
                    padding:'0px 44px',
                    '&:hover':{
                        bgcolor:'#8A2525',
                    },
                }} onClick={handleBtnClick}>
                    {(token !== undefined && token !== null && token.length > 0) ? textData.deposit_now : textData.sign_up_now}
                </Button>
                </Box>
            </Box>
        </Grid>
    );
}

export default HomeContentMainTop;
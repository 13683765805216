import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ColorModeContext } from './context';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from 'react-redux';
import store from './store/store';
import themePalette from "./themes/palette";
import MainRoutes from "./routes";
import customStyles from './themes/custom';
import themeBreakPoints from './themes/breakpoint';
import themeComponent from './themes/component';
import ForceLogin from './network/ForceLogin';
import LaunchLoading from './component/common/LaunchLoading';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { setFingerPrintInfo } from './tools/CommonTool';

function App() {
  // if(window.Telegram && window.Telegram.WebApp) {
  //   if(window.Telegram.WebApp.initData) {
  //     localStorage.setItem('TTTelegramWebAppInitData',window.Telegram.WebApp.initData);

  //     if(!window.Telegram.WebApp.isExpanded) {
  //       window.Telegram.WebApp.expand();
  //     }
  //     window.Telegram.WebApp.enableClosingConfirmation();
  //     window.Telegram.WebApp.setHeaderColor('#171721');
  //     window.Telegram.WebApp.setBackgroundColor('#171721');
  //   }
  // }
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState(prefersDarkMode ? 'dark' : 'light');
  const themeMode = React.useMemo(
    () => ({
      toggleThemeMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const params = new URLSearchParams(window.location.search);
  const inviteCode = params.get('referral_code');
  if(inviteCode !== undefined && inviteCode !== null && inviteCode.length > 0) {
      localStorage.setItem('TTInviteCode',inviteCode);
  }

  console.log('v:0.1.2');

  React.useEffect(
    () => {
      setMode(prefersDarkMode ? 'dark' : 'light');
    },
    [prefersDarkMode]
  )

  const theme = React.useMemo(
    () => 
    createTheme({
      palette: themePalette(mode),
      components: themeComponent(mode),
      breakpoints: themeBreakPoints(),
      custom: customStyles(mode),
      typography: {
        // fontFamily: 'Gilroy',
        fontFamily: [
          'Gilroy',
          'Ubuntu-Regular',
          '"Helvetica Neue"',
          'Condensed',
          'DisplayRegular',
          'Helvetica',
          'Arial',
          '"PingFang SC"',
          '"Hiragino Sans GB"',
          '"WenQuanYi Micro Hei"',
          '"Microsoft Yahei"',
          'sans-serif',
        ].join(','),
      }
    }),
    [mode],
  );

  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
    setLoading(false);

    const setFp = async () => {
      try {
        const fp = await FingerprintJS.load();
        const { visitorId } = await fp.get();
        // console.log('FingerprintJS:'+visitorId)
        setFingerPrintInfo(visitorId)
      } catch (error) {
        console.error('FingerprintJS error:'+error)
      }
    };

    setFp();
  }, []);
  
  return (
    <React.Fragment>
      {loading ? <LaunchLoading /> : 
      <ColorModeContext.Provider value={themeMode}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <React.Fragment>
              <ForceLogin />
              <MainRoutes /> 
            </React.Fragment>
          </Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    }
    </React.Fragment>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentGameUrl:'',
    recordList:[],
    headsTailsRecordList:[],
    commonRecordList:[],
}

const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        gameRefresh(state,action) {
            state.currentGameUrl = action.payload.currentGameUrl;
            console.log('gameRefresh state:',JSON.stringify(state));
        },
        gameRecordRefresh(state,action) {
            state.recordList = action.payload.recordList;
            console.log('gameRecordRefresh state:',JSON.stringify(state));
        },
        gameHeadsTailsRecordRefresh(state,action) {
            state.headsTailsRecordList = action.payload.headsTailsRecordList;
            console.log('gameHeadsTailsRecordRefresh state:',JSON.stringify(state));
        }, 
        gameCommonRecordRefresh(state,action) {
            state.commonRecordList = action.payload.commonRecordList;
            console.log('gameCommonRecordRefresh state:',JSON.stringify(state));
        },
    }
});

export const { 
    gameRefresh,
    gameRecordRefresh,
    gameHeadsTailsRecordRefresh,
    gameCommonRecordRefresh 
} = gameSlice.actions;

export default gameSlice.reducer;
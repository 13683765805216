import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    favoriteData:null,
}

const favoriteSlice = createSlice({
    name: 'favorite',
    initialState,
    reducers: {
        favoriteDataRefresh(state,action) {
            state.favoriteData = action.payload.favoriteData;
        },
        favoriteDataLoadMore(state,action) {
            state.favoriteData.total_count = action.payload.favoriteData.total_count;
            state.favoriteData.data = state.favoriteData.data.concat(action.payload.favoriteData.data);
        },
    }
});

export const { 
    favoriteDataRefresh,
    favoriteDataLoadMore,
} = favoriteSlice.actions;

export default favoriteSlice.reducer;
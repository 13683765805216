import React, { useEffect, useRef } from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import HomeContentMainHistoryBlock from "./HomeContentMainHistoryBlock";
import HomeContentMobileHistoryBlock from "./mobile/HomeContentMobileHistoryBlock";
import { getBiggestTodayService,getLatestRoundService } from "../../network/service/home";
import { useDispatch, useSelector } from "react-redux";
import { biggestTodayRefresh, latestRoundRefresh } from "../../features/homeSlice";

const HomeContentMainHistory = () => {
    const intervalId = useRef();
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const textData = {
        biggestToday: t("biggest_today"),
        biggest: t("biggest"),
        latest: t("latest"),
        rank: t("rank"),
        player: t("player"),
        profit: t("profit"),
        latestRounds: t("latest_rounds"),
        game: t("game"),
        amount: t("amount"),
    };

    const rankArr = useSelector((state) => state.home.rank);
    const roundArr = useSelector((state) => state.home.round);

    const handleFetchBiggestTodayHistory = async () => {
        try {
            const response = await getBiggestTodayService();
            dispatch(biggestTodayRefresh({rank:response.data.data}));
        } catch (error) {
            console.log('handleFetchBiggestTodayHistory error:'+error);
        }
    };
    const handleFetchLatestRoundHistory = async () => {
        try {
            const response = await getLatestRoundService();
            dispatch(latestRoundRefresh({round:response.data.data}));
        } catch (error) {
            console.log('handleFetchLatestRoundHistory error:'+error);
        }
    };

    useEffect(() => {
        if(rankArr.length === 0) {
            handleFetchBiggestTodayHistory();
        }
        if(roundArr.length === 0) {
            handleFetchLatestRoundHistory();
        }

        intervalId.current = setInterval(() => {
            handleFetchBiggestTodayHistory();
            handleFetchLatestRoundHistory();
        }, 8000);

        return () => {
            clearInterval(intervalId.current);
        };
    }, []);

    if(screenMD) {
        return (
            <HomeContentMobileHistoryBlock 
                biggestToday={textData.biggestToday}
                biggest={textData.biggest}
                latest={textData.latest}
                rank={textData.rank}
                player={textData.player}
                profit={textData.profit}
                game={textData.game}
                amount={textData.amount}
                biggestHistoryArr={rankArr}
                latestHistoryArr={roundArr}
            />
        );
    }

    return (
        <Grid container sx={{
            // display:'flex',
            display:'grid',
            gridTemplateColumns:'repeat(2, 1fr)',
            // justifyContent:'space-between',
            // alignItems:'start',
            gap:'24px',
            mt:'36px',
        }}>
            <HomeContentMainHistoryBlock 
                title={textData.biggestToday}
                leftTitle={textData.rank}
                middleTitle={textData.player}
                rightTitle={textData.profit}
                historyArr={rankArr}
                isToday={true}
            />
            <HomeContentMainHistoryBlock 
                title={textData.latestRounds}
                leftTitle={textData.game}
                middleTitle={textData.player}
                rightTitle={textData.amount}
                historyArr={roundArr}
                isToday={false}
            />
        </Grid>
    );
}

export default HomeContentMainHistory;
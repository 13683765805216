import React from 'react';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import arrowLeftIcon from '../../assets/images/header/header-arrow-left.png';
import playIcon from '../../assets/images/game/game-play.png';

const GameContentGamePreviewMobile = (props) => {
    const {
        category,gameIcon,gameName,gameUid,isCloseFreePlayMode,isFavorite,favoriteNum,by,freePlay,realPlay,favorites,
        categoryOnClick,freePlayOnClick,realPlayOnclick,favoriteOnClick,questionOnClick,
    } = props;

    return (
        <Box sx={{
            bgcolor:'#1D1D28',
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            gap:'8px',
            width:'100%',
            pb:'18px',
        }}>
            <Button disableRipple sx={{
                bgcolor:'transparent',
                textTransform:'none',
                color:'#ACB3D7',
                fontSize:'18px',
                fontStyle:'normal',
                fontWeight:400,
                display:'flex',
                justifyContent:'flex-start',
                alignItems:'center',
                gap:'18px',
                height:'38px',
                pl:'18px',
                mt:'8px',
                '&:hover': {
                    bgcolor:'transparent',
                },
                '&:disabled': {
                    color:'#ACB3D7',
                },
            }} onClick={categoryOnClick}>
                <CardMedia
                    component="img"
                    image={arrowLeftIcon}
                    sx={{
                        width:'8px',
                        height:'16px',
                        objectFit:'contain',
                    }}
                />
                {category}
            </Button>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'flex-start',
                gap:'18px',
                padding:'0px 18px',
            }}>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignContent:'center',
                    alignItems:'center',
                    gap:'14px'
                }}>
                    <CardMedia
                        component="img"
                        image={gameIcon}
                        sx={{
                            width:'160px',
                            height:'160px',
                            borderRadius:'12px',
                            objectFit:'cover',
                        }}
                    />
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'flex-start',
                        alignContent:'flex-start',
                        alignItems:'flex-start',
                        gap:'18px',
                        width:'calc(100vw - 210px)',
                        height:'100%',
                    }}>
                        <Box sx={{
                            display:'flex',
                            justifyContent:'flex-start',
                            alignItems:'center',
                            gap:'0px',
                            width:'100%',
                        }}>
                            <Typography sx={{
                                color:'#ACB3D7',
                                fontSize:'15px',
                                fontStyle:'normal',
                                fontWeight:400,
                                maxWidth:'calc(100% - 26px)',
                                wordWrap:'break-word',
                            }}>
                                {gameName}
                            </Typography>
                            {/* <IconButton sx={{
                                width:'22px',
                                height:'22px',
                                ml:'4px',
                            }} onClick={questionOnClick}>
                                <CardMedia
                                    component='img'
                                    src={questionIcon}
                                    sx={{
                                        objectFit:'contain',
                                        width:'16px',
                                        height:'16px',
                                        border:'none',
                                    }}
                                />
                            </IconButton> */}
                        </Box>
                        <Typography sx={{
                            color:'#6D728D',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:400,
                            maxWidth:'calc(100% - 26px)',
                            wordWrap:'break-word',
                        }}>
                            {by}
                            <span style={{color:'#1CA45F',marginLeft:'5px',textDecoration:'underline #1CA45F',cursor:'pointer'}}>
                            JIU ZHOU INT
                            </span>
                        </Typography>
                        {(gameUid !== undefined && gameUid !== null && gameUid.length > 0) ?
                        <Button sx={{
                            bgcolor:'#2A2A39',
                            borderRadius:'100px',
                            textTransform:'none',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            gap:'9px',
                            padding:'5px 16px',
                            color:'#ACB3D7',
                            fontSize:'15px',
                            fontStyle:'normal',
                            fontWeight:400,
                            '&:hover': {
                                bgcolor:'#2A2A39',
                            }
                        }} onClick={favoriteOnClick}>
                            {isFavorite ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22" fill="none">
                            <path d="M19.8338 8.81219H13.9751C16.2454 0.429182 12.4133 0 12.4133 0C10.7879 0 11.1257 1.28501 11.0013 1.49833C11.0013 5.59714 6.64597 8.80965 6.64597 8.80965V20.4356C6.64597 21.5835 8.21032 21.9975 8.82235 21.9975H17.6244C18.4523 21.9975 19.1278 19.8262 19.1278 19.8262C21.3042 12.4234 21.3042 10.2191 21.3042 10.2191C21.3042 8.69029 19.8312 8.81219 19.8312 8.81219H19.8338ZM4.24356 8.81727H0.736465C0.0126977 8.81727 0 9.52834 0 9.52834L0.723768 21.2534C0.723768 22 1.47039 22 1.47039 22H4.50514C5.13748 22 5.1324 21.5073 5.1324 21.5073V9.70865C5.1324 8.80711 4.24103 8.81981 4.24103 8.81981L4.24356 8.81727Z" fill="url(#paint0_linear_7418_10308)"/>
                            <defs>
                            <linearGradient id="paint0_linear_7418_10308" x1="7.93642e-08" y1="11" x2="21.3042" y2="11" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#DE9948"/>
                            <stop offset="1" stop-color="#FDD971"/>
                            </linearGradient>
                            </defs>
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22" fill="none">
                            <path d="M19.8338 8.81219H13.9751C16.2454 0.429182 12.4133 0 12.4133 0C10.7879 0 11.1257 1.28501 11.0013 1.49833C11.0013 5.59714 6.64597 8.80965 6.64597 8.80965V20.4356C6.64597 21.5835 8.21032 21.9975 8.82235 21.9975H17.6244C18.4523 21.9975 19.1278 19.8262 19.1278 19.8262C21.3042 12.4234 21.3042 10.2191 21.3042 10.2191C21.3042 8.69029 19.8312 8.81219 19.8312 8.81219H19.8338ZM4.24356 8.81727H0.736465C0.0126977 8.81727 0 9.52834 0 9.52834L0.723768 21.2534C0.723768 22 1.47039 22 1.47039 22H4.50514C5.13748 22 5.1324 21.5073 5.1324 21.5073V9.70865C5.1324 8.80711 4.24103 8.81981 4.24103 8.81981L4.24356 8.81727Z" fill={isFavorite ? "red" : "#6D728D"}/>
                            </svg>
                            }
                            {`${favoriteNum}`}
                        </Button> : ''
                        }
                    </Box>
                </Grid>
                <Box sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap:'11px',
                    width:'100%',
                    height:'48px',
                }}>
                    {(isCloseFreePlayMode !== undefined && isCloseFreePlayMode !== null && isCloseFreePlayMode === true) ? '' :
                        <Button sx={{
                            bgcolor:'#3A3E53',
                            borderRadius:'24px',
                            textTransform:'none',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            gap:'7px',
                            padding:'0px',
                            color:'#FFFFFF',
                            fontSize:'17px',
                            fontStyle:'normal',
                            fontWeight:500,
                            height:'48px',
                            width:'calc((100vw - 47px) / 2)',
                            '&:hover': {
                                bgcolor:'#3A3E53',
                            }
                        }} onClick={freePlayOnClick}>
                            <CardMedia
                                component='img'
                                src={playIcon}
                                sx={{
                                    objectFit:'contain',
                                    width:'24px',
                                    height:'24px',
                                }}
                            />
                            {freePlay}
                        </Button>
                    }
                    <Button sx={{
                        bgcolor:'#1CA45F',
                        borderRadius:'24px',
                        textTransform:'none',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'7px',
                        padding:'0px',
                        color:'#FFFFFF',
                        fontSize:'17px',
                        fontStyle:'normal',
                        fontWeight:500,
                        height:'48px',
                        width:'calc((100vw - 47px) / 2)',
                        '&:hover': {
                            bgcolor:'#1CA45F',
                        }
                    }} onClick={realPlayOnclick}>
                        <CardMedia
                            component='img'
                            src={playIcon}
                            sx={{
                                objectFit:'contain',
                                width:'24px',
                                height:'24px',
                            }}
                        />
                        {realPlay}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default GameContentGamePreviewMobile;
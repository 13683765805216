import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    liveCasino:null,
    currentLiveCasinoCategory:0,
}

const liveCasinoSlice = createSlice({
    name: 'liveCasino',
    initialState,
    reducers: {
        liveCasinoDataRefresh(state,action) {
            state.liveCasino =  action.payload.liveCasino;
        },
        liveCasinoDataLoadMore(state,action) {
            state.liveCasino.total_count = action.payload.liveCasino.total_count;
            state.liveCasino.data =  state.liveCasino.data.concat(action.payload.liveCasino.data);
        },
        liveCasinoRefreshCategory(state,action) {
            state.currentLiveCasinoCategory = action.payload.currentLiveCasinoCategory;
        },
    }
});

export const { 
    liveCasinoDataRefresh,
    liveCasinoDataLoadMore,
    liveCasinoRefreshCategory 
} = liveCasinoSlice.actions;

export default liveCasinoSlice.reducer;
// 获取当前域名
const currentDomain = window.location.hostname;

const serverConfig = {
    baseURL: process.env.REACT_APP_BASE_URL, // 请求基础地址,可根据环境自定义
    useTokenAuthorization: true, // 是否开启 token 认证
    withCredentials: false, // 跨域请求是否需要携带 cookie
    botId: currentDomain.includes('www.ttbetgame.com') ? process.env.REACT_APP_BOT_ID : process.env.REACT_APP_BOT_ID_2
};

export default serverConfig;
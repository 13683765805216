import api from "../api";
import httpManager from "../httpManager";

// 获取下注 latest bets
export const getReferralGetReferralInfoService = () => {
    return httpManager({
        url:api.referralGetReferralInfo,
        method:'post',
        data:{},
    });
}
import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import logoIcon from '../../assets/images/login-and-register/login-and-register-logo.png';
import topRightIcon from '../../assets/images/login-and-register/login-and-register-top-right.png';
import bottomLeftIcon from '../../assets/images/login-and-register/login-and-register-bottom-left.png';

const LoginAndRegisterContentLeftPara = (props) => {
    const { item } = props;
    const { title, desc } = item;

    return (
        <Box>
            <Typography sx={{
                color:'#FFFFFF',
                fontSize:'12px',
                fontStyle:'normal',
                fontWeight:700,
            }}>
                {title}
                                    
            </Typography>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'12px',
                fontStyle:'normal',
                fontWeight:400,
            }}>
                {desc}
            </Typography> 
        </Box>
        
    );
}

const LoginAndRegisterContentLeft = (props) => {
    const { 
        welcomeTo,
        baoJia,
        theParadiseOf,
        iGaming,
        startYourNow,
        privacySecurity,
        endToCompany,
        fairness,
        toArchiveWith,
        funnyGames,
        overHundredsWant,
        instantCashOut,
        weAreMinutes,
    } = props;
    
    const paraArr = [
        {
            title:privacySecurity,
            desc:endToCompany
        },
        {
            title:fairness,
            desc:toArchiveWith
        },
        {
            title:funnyGames,
            desc:overHundredsWant
        },
        {
            title:instantCashOut,
            desc:weAreMinutes
        },
    ];

    return (
        <Box sx={{
            bgcolor:'dddddd',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            position:'relative',
            overflow:'hidden',
            height:'100%',
            width:'calc(100vw - 603px)',
            background:'linear-gradient(to bottom, #0E0C13, #180C12)',
        }}>
            <CardMedia
                component="img"
                image={topRightIcon}
                sx={{
                    width:'466px',
                    height:'239px',
                    objectFit:'contain',
                    position:'absolute',
                    top:'0px',
                    right:'0px'
                }}
            />
            <CardMedia
                component="img"
                image={bottomLeftIcon}
                sx={{
                    width:'500px',
                    height:'267px',
                    objectFit:'contain',
                    position:'absolute',
                    left:'0px',
                    bottom:'0px'
                }}
            />
            <Box sx={{
                maxWidth:'450px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'flex-start',
                gap:'20px',
                zIndex:'1',
            }}>
                <CardMedia
                    component="img"
                    image={logoIcon}
                    sx={{
                        width:'274px',
                        height:'88px',
                        objectFit:'contain',
                    }}
                />
                <Typography sx={{
                    color:'#FFD233',
                    fontSize:'20px',
                    fontStyle:'normal',
                    fontWeight:700,
                    textTransform:'capitalize',
                }}>
                    {welcomeTo} <span style={{color:'#F15A5A'}}>{baoJia}</span>
                    <br/>
                    {theParadiseOf}
                    <br/>
                    {iGaming}                    
                </Typography>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'12px',
                    fontStyle:'normal',
                    fontWeight:700,
                }}>
                    {startYourNow}
                </Typography>
                {paraArr.map((item,index) => (
                    <LoginAndRegisterContentLeftPara 
                        item={item}
                        key={index}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default LoginAndRegisterContentLeft;
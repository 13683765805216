import React from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const HomeContentMainHistoryBlockTitle = (props) => {
    const {leftTitle, middleTitle, rightTitle, isToday} = props;
    const theme = useTheme();

    return (
        <Grid container sx={{
            width:'calc(100% - 36px)',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
        }}>
            <Box sx={{
                display:'flex',
                justifyContent:'start',
                alignItems:'center',
            }}>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:400,
                    width:'130px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                        color:'#6D728D',
                        width:isToday ? '88px' : '120px',
                    },
                }}>
                    {leftTitle}
                </Typography>
                <Typography sx={{
                    color:'#ACB3D7',
                    fontSize:'15px',
                    fontStyle:'normal',
                    fontWeight:400,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                        color:'#6D728D',
                    },
                }}>
                    {middleTitle}
                </Typography>
            </Box>
            <Typography sx={{
                color:'#ACB3D7',
                fontSize:'15px',
                fontStyle:'normal',
                fontWeight:400,
                textAlign:'right',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    fontSize:'12px',
                    color:'#6D728D',
                },
            }}>
                {rightTitle}
            </Typography>
        </Grid>
    );
}

export default HomeContentMainHistoryBlockTitle;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import HomeContentMainGameProvidersTitle from './HomeContentMainGameProvidersTitle';
import HomeContentMainGameProvidersItem from './HomeContentMainGameProvidersItem';
import homeProviderIcon1 from '../../assets/images/home/home-provider-1.png';
import homeProviderIcon4 from '../../assets/images/home/home-provider-4.png';
import homeProviderIcon6 from '../../assets/images/home/home-provider-6.png';
import homeProviderIcon7 from '../../assets/images/home/home-provider-7.png';
import homeProviderIcon8 from '../../assets/images/home/home-provider-8.png';

const HomeContentMainGameProviders = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const textData = {
        gameProviders: t("game_providers"),
    };

    const providerArr = [
        homeProviderIcon1,homeProviderIcon4,homeProviderIcon6,homeProviderIcon7,homeProviderIcon8,
    ];

    const handlePrevClick = () => {
        console.log('查看上一页');
    }
    const handleNextClick = () => {
        console.log('查看下一页');
    }

    return (
        <Grid container sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'start',
            mt:'36px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'25px',
            },
        }}>
            <HomeContentMainGameProvidersTitle 
                title={textData.gameProviders}
                prevClick={handlePrevClick}
                nextClick={handleNextClick}
            />
            <Grid container sx={{
                display:'flex',
                justifyContent:'start',
                alignItems:'start',
                rowGap:'22px',
                columnGap:'22px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    rowGap:'18px',
                    columnGap:'10px',
                    mt:'18px',
                },
            }}>
                {providerArr.map((item,index) => (
                    <HomeContentMainGameProvidersItem 
                        image={item}
                        index={index}
                        key={index}
                    />
                ))}
            </Grid>
        </Grid>
    );
}

export default HomeContentMainGameProviders;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import clubModeIcon from '../../assets/images/poker/poker-club-mode.webp';
import mobileClubModeIcon from '../../assets/images/poker/poker-mobile-club-mode.webp';
import PokerContentSelected from './PokerContentSelected';

const PokerContentClubMode = (props) => {
    const {
        clubMode,clubModePlayers,inThisGames,theInsuranceBeats
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));

    return (
        <Grid container sx={{
            display:'flex',
            justifyContent:'space-between',
            alignContent:'center',
            alignItems:'center',
            gap:'32px',
            mt:'50px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                gap:'18px',
                mt:'18px',
            },
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:'460px',
                gap:'26px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'100%',
                    gap:'18px',
                },
            }}>
                <Typography sx={{
                    color:'#FFFFFF',
                    fontSize:'48px',
                    fontStyle:'normal',
                    fontWeight:700,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'30px',
                    },
                }}>
                    {clubMode}
                </Typography>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'16px',
                    fontStyle:'normal',
                    fontWeight:400,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'12px',
                    },
                }}>
                    {clubModePlayers}
                </Typography>
                <PokerContentSelected 
                    title={inThisGames}
                />
                <PokerContentSelected 
                    title={theInsuranceBeats}
                />
            </Box>
            <CardMedia
                component="img"
                image={screenMD ? mobileClubModeIcon : clubModeIcon}
                sx={{
                    width:'500px',
                    height:'355px',
                    objectFit:'contain',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        width:'100%',
                        height:'calc((100vw - 36px) * 256 / 335)',
                        objectFit:'cover',
                    },
                }}
            />
        </Grid>
    );
}

export default PokerContentClubMode;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Footer from '../footer/Footer';
import SportsContentMain from './SportsContentMain';

const SportsContent = () => {
    const theme = useTheme();

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    return (
        <Grid sx={{
            pt:'64px',
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between',
            }}>
                <SportsContentMain />
                <Footer />
            </Box>
        </Grid>
    );
}

export default SportsContent;
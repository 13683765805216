import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import RoutePath from '../../tools/RoutePath';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import TTAlert from '../common/TTAlert';
import TTGameIcon from '../common/TTGameIcon';
import { useDispatch } from 'react-redux';
import { gameRefresh } from '../../features/gameSlice';

const FavoriteContentItem = (props) => {
    const { image,sourceImageName, title, gameUrl, gameUid, isCloseFreePlayMode,isFavorite,favoriteNum, playerNum,category,comingSoon,fromPage,gameOnClick } = props;
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // let resourceImage = null;
    // if(sourceImageName !== undefined && sourceImageName != null && sourceImageName.length > 0) {
    //     resourceImage = require(`../../assets/images/temp/slots/${sourceImageName}.png`);
    // }

    const handleItemClick = () => {
        if(gameUrl.length <= 0 && !(gameUid !== undefined && gameUid !== null && gameUid.length > 0)) {
            setAlertType('info');
            setAlertMessage(comingSoon);
            setOpenAlert(true);
            return;
        }
        gameOnClick && gameOnClick();
        navigate(RoutePath.game,
            { 
                state: { 
                    category:category,
                    fromPage:fromPage,
                    gameIcon: image,
                    gameName:title,
                    gameUrl:gameUrl,
                    gameUid:gameUid,
                    isCloseFreePlayMode:isCloseFreePlayMode,
                    isFavorite:isFavorite,
                    favoriteNum:favoriteNum,
                }
            }
        );
        dispatch(gameRefresh({currentGameUrl:gameUrl}));
    }

    // alert
    const [ openAlert, setOpenAlert ] = useState(false);
    const [ alertType, setAlertType ] = useState('info');
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
    };

    return (
        <React.Fragment>
            <TTAlert 
                type={alertType}
                isOpen={openAlert}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                width:'148px',
                gap:'18px',
                cursor:'pointer',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    width:'calc((100vw - 105px) / 2)',
                    gap:'17px',
                },
            }} onClick={handleItemClick}>
                {/* <CardMedia
                    component="img"
                    image={ image }
                    sx={{
                        objectFit:'cover',
                        width:'148px',
                        height:'148px',
                        borderRadius:'24px',
                        // filter: gameUrl.length <= 0 ? 'grayscale(100%)' : 'grayscale(0%)',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            width:'calc((100vw - 90px) / 2)',
                            height:'calc((100vw - 90px) / 2)',
                            borderRadius:'16px',
                        },
                    }}
                /> */}
                <TTGameIcon 
                    width='148px'
                    height='148px'
                    mobileSpace='105px'
                    column={2}
                    image={image}
                />
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'flex-start',
                }}>
                    <Typography sx={{
                        color:'#ACB3D7',
                        fontSize:'16px',
                        fontStyle:'normal',
                        fontWeight:700,
                        // maxWidth:'100px',
                        width:'100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'12px',
                            // maxWidth:'80px',
                        },
                    }}>
                        {title}
                    </Typography>
                    {/* <Box sx={{
                        display:'flex',
                        justifyContent:'end',
                        alignItems:'center',
                        gap:'5px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            gap:'1.5px',
                        },
                    }}>
                        <CardMedia
                            component="img"
                            image={ homePeopleIcon }
                            sx={{
                                objectFit:'cover',
                                width:'14px',
                                height:'12px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'10px',
                                    height:'8px',
                                },
                            }}
                        />
                        <Typography sx={{
                            color:'#6D728D',
                            fontSize:'12px',
                            fontStyle:'normal',
                            fontWeight:400,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'9px',
                            },
                        }}>
                            {playerNum}
                        </Typography>
                    </Box> */}
                </Grid>
            </Box>
        </React.Fragment>
    );
}

export default FavoriteContentItem;
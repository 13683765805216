import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    slots:null,
    currentSlotsCategory:0,
}

const slotsSlice = createSlice({
    name: 'slots',
    initialState,
    reducers: {
        slotsDataRefresh(state,action) {
            state.slots =  action.payload.slots;
            // console.log('slotsDataRefresh state:',JSON.stringify(state));
        },
        slotsDataLoadMore(state,action) {
            state.slots.total_count = action.payload.slots.total_count;
            state.slots.data =  state.slots.data.concat(action.payload.slots.data);
            // console.log('slotsDataLoadMore state:',JSON.stringify(state));
        },
        slotsRefreshCategory(state,action) {
            state.currentSlotsCategory = action.payload.currentSlotsCategory;
        },
    }
});

export const { slotsDataRefresh,slotsDataLoadMore,slotsRefreshCategory } = slotsSlice.actions;

export default slotsSlice.reducer;
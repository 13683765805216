import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FooterContentLeft from './FooterContentLeft';
import FooterContentRightItem from './FooterContentRightItem';
import FooterMobileTop from './mobile/FooterMobileTop';
import FooterMobileCenter from './mobile/FooterMobileCenter';
import FooterMobileBottom from './mobile/FooterMobileBottom';

const FooterContent = () => {
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const { t, i18n } = useTranslation();
    const textData = {
        weAtCasinos: t("we_at_casinos"),
        privacySecurity: t("privacy_security"),
        age18: t("age_18"),
        ttbetIsMind: t("ttbet_is___mind"),
        termOfService: t("term_of_service"),
        privacyPolicy: t("privacy_policy"),
        bonusTerms: t("bonus_terms"),
        selfExclusionPolicy: t("self_exclusion_policy"),
        ttbetKycPolicy: t("ttbet_kyc_policy"),
        responsibleGaming: t("responsible_gaming"),
        amlPolicy: t("aml_policy"),
        contactUs: t("contact_us"),
        email: t("email"),
    };

    const titleArr1 = [
        {
            type:'termOfService',
            text:textData.termOfService,
        },
        {
            type:'privacyPolicy',
            text:textData.privacyPolicy,
        }
    ];
    let titleArr2 = [
        {
            type:'ttbetKycPolicy',
            text:textData.ttbetKycPolicy,
        },
    ];
    let titleArr3 = [
        {
            type:'contactUs',
            text:textData.contactUs,
        },
        {
            type:'email',
            text:`${textData.email}:`,
        },
    ];
    if(screenMD) {
        titleArr2 = [
            {
                type:'termOfService',
                text:textData.termOfService,
            },
            {
                type:'privacyPolicy',
                text:textData.privacyPolicy,
            },
            {
                type:'ttbetKycPolicy',
                text:textData.ttbetKycPolicy,
            },
        ];
    }

    return (
        <Grid container sx={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'flex-start',
            gap:'20px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                gap:'25px',
                flexDirection:'column',
                justifyContent:'flex-start',
            },
        }} wrap='nowrap'>
            {
            screenMD ? (
                <React.Fragment>
                    <FooterMobileTop 
                        ttbetIsMind={textData.ttbetIsMind}
                    />
                    <FooterMobileCenter 
                        weAtCasinos={textData.weAtCasinos}
                        privacyPolicy={textData.privacyPolicy}
                        age18={textData.age18}
                    />
                    <Box sx={{
                        display:'flex',
                        justifyContent:'start',
                        alignItems:'start',
                        width:'100%'
                    }}>
                        <FooterMobileBottom 
                            textArr={titleArr2}
                        />
                        <FooterMobileBottom 
                            textArr={titleArr3}
                            isEmail={true}
                        />
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <FooterContentLeft 
                        weAtCasinos={textData.weAtCasinos}
                        privacyPolicy={textData.privacyPolicy}
                        age18={textData.age18}
                        ttbetIsMind={textData.ttbetIsMind}
                    />
                    <FooterContentRightItem 
                        textArr={titleArr1}
                    />
                    <FooterContentRightItem 
                        textArr={titleArr2}
                    />
                    <FooterContentRightItem 
                        textArr={titleArr3}
                        isEmail={true}
                    />
                </React.Fragment>
            )}
        </Grid>
    );
}

export default FooterContent;
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab, { tabClasses } from "@mui/material/Tab";
import List from "@mui/material/List";
import Grow from '@mui/material/Grow';
import logoIcon from '../../assets/images/wallet/wallet-tt-logo.png';
import { getGameHistoryService, getGameLatestBetsService, getGameMyBetsService } from '../../network/service/game';
import GameContentBetHistoryItem from './GameContentBetHistoryItem';
import { useDispatch, useSelector } from 'react-redux';
import { gameCommonRecordRefresh, gameHeadsTailsRecordRefresh, gameRecordRefresh } from '../../features/gameSlice';
import GameContentBetHistoryHeadstailItem from './GameContentBetHistoryHeadstailItem';
import GameContentBetHistoryCommonItem from './GameContentBetHistoryCommonItem';

const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className={`${tabsClasses.indicatorSpan}`} /> }}
    />
  ))(
    ({ theme }) => ({
        maxWidth:'100%',
        width:'100%',
        [theme.breakpoints.down(MobileBreakpoint.match)]: {
            minHeight:'35px',
            height:'35px',
        },
        [`& .${tabsClasses.indicator}`]: {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          [theme.breakpoints.down(MobileBreakpoint.match)]: {
            marginBottom: 2,
          },
        },
        [`& .${tabsClasses.indicatorSpan}`]: {
          maxWidth: 21,
          height: 0,
          width: '100%',
          backgroundColor: 'transparent',
          borderRadius: 2,
          [theme.breakpoints.down(MobileBreakpoint.match)]: {
            height: 4,
            backgroundColor: '#D9D9D9',
          },
        },
    })
);
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: '400',
      fontSize: '24px',
      fontStyle: 'normal',
      color: '#6D728D',
      margin: '0px',
      padding: '0px',
      marginRight: '88px',
      [theme.breakpoints.down(MobileBreakpoint.match)]: {
        margin: '0px',
        padding: '0px',
        minHeight:'20px',
        height:'20px',
        fontSize: '12px',
        width:'calc(100% / 2)',
      },
      '&.Mui-selected': {
        color: '#ACB3D7',
        fontSize: '32px',
        [theme.breakpoints.down(MobileBreakpoint.match)]: {
            fontSize: '15px',
        },
      },
      [`& .${tabClasses.labelIcon}`]: {
        padding:'0px',
        margin:'0px',
      }
    }),
);

const GameContentBetHistory = (props) => {
    const {
        latestBets,myBets,player,time,bet,choice,betID,amount,payout,profit,noData,gameUrl,gameUid,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const dispatch = useDispatch();

    const [ currentCategory, setCurrentCategory ] = useState(0);
    
    let recordList = useSelector((state) => {
        if(gameUrl.includes('https://www.ttbetgame.com')) {
            if(gameUrl.includes('heads-and-tails')) {
                return state.game.headsTailsRecordList;
            }else {
                return state.game.recordList;
            }
        }else {
            return state.game.commonRecordList;
        }
    });
    const userId = useSelector((state) => state.user.user.pk);

    let titleArr = [
        time,bet,payout,profit,
    ];
    if(gameUrl.includes('heads-and-tails')) {
        titleArr = [
            player,choice,time,profit,
        ];
    }
    if(!gameUrl.includes('https://www.ttbetgame.com')) {
        titleArr = [
            time,player,bet,profit,
        ];
    }

    const maxAmountLength = recordList.reduce((maxLen, item) => {
        let numStr = '';
        if(item.bet_amount !== undefined && item.bet_amount !== null && item.bet_amount.toString().length > 0) {
            numStr = item.bet_amount.toString();
        }else if(item.profit !== undefined && item.profit !== null && item.profit.toString().length > 0) {
            numStr = item.profit.toString();
        }
        const profitLength = (numStr.toString().includes('-') || numStr.toString().includes('+')) ? numStr.toString().length : numStr.toString().length + 1;
        return profitLength > maxLen ? profitLength : maxLen;
    }, 0);
    const [resultMaxAmountLength, setResultMaxAmountLength] = useState(maxAmountLength);
    const handleAddResultMaxAmountLength = (addNum) => {
        setResultMaxAmountLength(maxAmountLength + addNum);
    };
    useEffect(() => {
        setResultMaxAmountLength(maxAmountLength);
    }, [recordList]);

    const handleMenuClick = (event, newValue) => {
        if(newValue !== currentCategory) {
            setCurrentCategory(newValue);
        }
    };

    const handleFetchBets = async () => {
        try {
            let response = null;
            let gameMatch = '';
            // 使用正则表达式提取想要的部分
            const match = gameUrl.match(/https:\/\/www\.ttbetgame\.com\/(.+?)\//);
            // 如果匹配成功，提取的值存储在match数组的第二个元素中
            if (match && match[1]) {
                gameMatch = match[1];
            }
            if(gameMatch === 'heads-and-tails') {
                gameMatch = 'headstails';
            }
            const params = {
                gameName:gameMatch.toLowerCase(),
            }
            if(currentCategory === 0) {
                response = await getGameLatestBetsService(params);
            }else {
                response = await getGameMyBetsService(params);
            }
            if(gameUrl.includes('heads-and-tails')) {
                dispatch(gameHeadsTailsRecordRefresh({headsTailsRecordList:response.data.data.slice(0,10)}));
            }else {
                dispatch(gameRecordRefresh({recordList:response.data.data.slice(0,10)}));
            }
        } catch (error) {
            console.log('handleFetchBets error:'+error);
            if(gameUrl.includes('heads-and-tails')) {
                dispatch(gameHeadsTailsRecordRefresh({headsTailsRecordList:[]}));
            }else {
                dispatch(gameRecordRefresh({recordList:[]}));
            }
        }
    };
    const handleFetchOpenBoxBets = async () => {
        try {
            let param = {
                page:1,
                page_size:20,
                game_uid:gameUid,
            }
            if(currentCategory === 1) {
                param = {
                    page:1,
                    page_size:20,
                    game_uid:gameUid,
                    user_id:parseInt(userId),
                }
            }
            const response = await getGameHistoryService(param);
            dispatch(gameCommonRecordRefresh({commonRecordList:response.data.data.slice(0,10)}));
        } catch (error) {
            console.log('handleFetchOpenBoxBets error:'+error);
            dispatch(gameRecordRefresh({commonRecordList:[]}));
        }
    };

    useEffect(() => {
        if(gameUrl.includes('https://www.ttbetgame.com')) {
            handleFetchBets();
        }else {
            handleFetchOpenBoxBets();
        }
    }, [currentCategory,gameUrl]);

    return (
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'flex-start',
            gap:'18px',
            marginTop:'28px',
            width:'100%',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                bgcolor:'#1D1D28',
                padding:'18px',
                marginTop:'8px',
                justifyContent:'center',
                width:"calc(100% - 36px)",
            },
        }}>
            <StyledTabs
                value={currentCategory}
                onChange={handleMenuClick}
                aria-label="styled tabs example"
            >
                <StyledTab label={latestBets} />
                <StyledTab label={myBets} />
            </StyledTabs>
            <Box sx={{
                bgcolor:'#1D1D28',
                borderRadius:'18px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                padding:'18px 33px',
                width:'calc(100% - 66px)',
                mt:'-10px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    padding:'0px',
                    width:'100%',
                },
            }}>
                <Grid container sx={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    width:"100%",
                    // height:'58px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        justifyContent:'space-between',
                        // height:'51px',
                        mt:'10px',
                    },
                }}>
                    {titleArr.map((item,index) => (
                        <Typography sx={{
                            color:'#ACB3D7',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:400,
                            width:`calc(100% / ${titleArr.length})`,
                            textAlign:index === 0 ? 'left' : (index === (titleArr.length - 1) ? 'right' : 'center'),
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'12px',
                            },
                        }} key={index}>
                            {item}
                        </Typography>
                    ))}
                </Grid>
                {recordList.length === 0 ?
                    <Box sx={{
                        display:recordList.length === 0 ? 'flex' : 'none',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        height:'578px',
                        gap:'30px',
                        width:'100%',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            height:'303px',
                            gap:'18px',
                        },
                    }}>
                        <CardMedia
                            component="img"
                            image={ logoIcon }
                            sx={{
                                objectFit:'contain',
                                width:'219px',
                                height:'70px',
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'185px',
                                    height:'58px',
                                },
                            }}
                        />
                        <Typography sx={{
                            color:'#6D728D',
                            fontSize:'18px',
                            fontStyle:'normal',
                            fontWeight:400,
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'12px',
                            },
                        }}>
                            {noData}
                        </Typography>
                    </Box> :
                    <List sx={{
                        height:'600px',
                        width:'100%',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            height:'350px',
                        },
                    }}>
                        {recordList.map((item,index) => (
                            <Grow in={true} key={
                                gameUrl.includes('https://www.ttbetgame.com') ?
                                (gameUrl.includes('heads-and-tails') ? 
                                `${item.user}${item.bet_amount}${item.choice}${item.created_at}` :
                                `${item.time}${item.bet}${item.payout}${item.profit}`) :
                                `${item.name}${item.bet_time}${item.bet_amount}${item.profit}`
                            } 
                            style={{ 
                                transformOrigin: '0 0 0',
                                transitionDelay: `${index * 80}ms`
                            }}
                            timeout={{enter:(recordList.length - 1) * 180,exit:0,}}
                            >
                                <Box sx={{
                                    mt:index === 0 ? '24px' : '34px',
                                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                        mt:index === 0 ? '10px' : '18px',
                                    },
                                }}>
                                    {gameUrl.includes('https://www.ttbetgame.com') ?
                                    (gameUrl.includes('heads-and-tails') ?
                                        <GameContentBetHistoryHeadstailItem 
                                            item={item}
                                            total={recordList.length}
                                            isMyBets={currentCategory === 0 ? false : true}
                                            maxAmountLength={resultMaxAmountLength}
                                            handleAddResultMaxAmountLength={handleAddResultMaxAmountLength}
                                        /> :

                                        <GameContentBetHistoryItem 
                                            item={item}
                                            total={recordList.length}
                                            isMyBets={currentCategory === 0 ? false : true}
                                            maxAmountLength={resultMaxAmountLength}
                                            handleAddResultMaxAmountLength={handleAddResultMaxAmountLength}
                                        />) :
                                        <GameContentBetHistoryCommonItem 
                                            name={item.player.name}
                                            betTime={item.bet_time}
                                            betAmount={item.bet_amount}
                                            betProfit={item.profit}
                                            total={recordList.length}
                                            isMyBets={currentCategory === 0 ? false : true}
                                            maxAmountLength={resultMaxAmountLength}
                                            handleAddResultMaxAmountLength={handleAddResultMaxAmountLength}
                                        />
                                    }
                                </Box>
                            </Grow>
                        ))}
                    </List>
                }
            </Box>
        </Box>
    );
}

export default GameContentBetHistory;
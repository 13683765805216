import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    searchText: {
        home:'',
        popular:'',
        new:'',
        hot:'',
        fishing:'',
        favorite:'',
        recent:'',
    },
    searchGames: {
        home:null,
        popular:null,
        new:null,
        hot:null,
        fishing:null,
        favorite:null,
        recent:null,
    },
}

const gameSearchSlice = createSlice({
    name: 'gameSearch',
    initialState,
    reducers: {
        // home
        homeSearchResultRefresh(state,action) {
            state.searchGames.home = action.payload.home;
        },
        homeSearchTextRefresh(state,action) {
            state.searchText.home = action.payload.home;
        },
        // popular
        popularSearchResultRefresh(state,action) {
            state.searchGames.popular = action.payload.popular;
        },
        popularSearchTextRefresh(state,action) {
            state.searchText.popular = action.payload.popular;
        },
        // new
        newSearchResultRefresh(state,action) {
            state.searchGames.new = action.payload.new;
        },
        newSearchTextRefresh(state,action) {
            state.searchText.new = action.payload.new;
        },
        // hot
        hotSearchResultRefresh(state,action) {
            state.searchGames.hot = action.payload.hot;
        },
        hotSearchTextRefresh(state,action) {
            state.searchText.hot = action.payload.hot;
        },
        // fishing
        fishingSearchResultRefresh(state,action) {
            state.searchGames.fishing = action.payload.fishing;
        },
        fishingSearchTextRefresh(state,action) {
            state.searchText.fishing = action.payload.fishing;
        },
        // favorite
        favoriteSearchResultRefresh(state,action) {
            state.searchGames.favorite = action.payload.favorite;
        },
        favoriteSearchTextRefresh(state,action) {
            state.searchText.favorite = action.payload.favorite;
        },
        // recent
        recentSearchResultRefresh(state,action) {
            state.searchGames.recent = action.payload.recent;
        },
        recentSearchTextRefresh(state,action) {
            state.searchText.recent = action.payload.recent;
        },
    }
});

export const { 
    homeSearchResultRefresh,
    homeSearchTextRefresh,
    popularSearchResultRefresh,
    popularSearchTextRefresh,
    newSearchResultRefresh,
    newSearchTextRefresh,
    hotSearchResultRefresh,
    hotSearchTextRefresh,
    fishingSearchResultRefresh,
    fishingSearchTextRefresh,
    favoriteSearchResultRefresh,
    favoriteSearchTextRefresh,
    recentSearchResultRefresh,
    recentSearchTextRefresh,
} = gameSearchSlice.actions;

export default gameSearchSlice.reducer;
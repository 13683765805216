import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import MobileBreakpoint from '../../../tools/MobileBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import RoutePath from "../../../tools/RoutePath";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import { filterDecimal } from "../../../tools/CommonTool";
import i18next from "i18next";
import TTAlert from "../../common/TTAlert";
import { useDispatch, useSelector } from "react-redux";
import { userWalletInfosService } from "../../../network/service/wallet";
import { walletRefresh } from "../../../features/walletSlice";
import BigNumber from "bignumber.js";
import { useNavigate } from "react-router-dom";
import WalletContentMainTip from "../WalletContentMainTip";

const WalletContentMainLeftWithdrawInput = styled(InputBase)(({ theme }) => ({
    [`& .${inputBaseClasses.input}`]: {
      position: 'relative',
      border: '0px solid',
      backgroundColor:'transparent',
      borderColor: 'transparent',
      fontSize: '20px',
      color:'#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      '&::placeholder': {
        color: '#3A3E53',
        opacity: 1,
      },
      [theme.breakpoints.down(MobileBreakpoint.match)]: {
        fontSize: '15px',
    },
    },
}));

const WalletContentMainLeftWithdrawAddressInput = (props) => {
    const { placeholder,handleInputChanged } = props;
    const theme = useTheme();
    const { focused } = useFormControl() || {};

    const borderColor = React.useMemo(() => {
        return focused ? '#F15A5A' : 'transparent';
    }, [focused]);

    return (
        <Box sx={{
            display:'flex',
            justifyContent:'start',
            alignItems:'center',
            width:'100%',
            height:'48px',
            backgroundColor: '#0D0F13',
            border: '1px solid',
            borderColor: borderColor,
            borderRadius:'24px',
            p:'0px 25px',
            mt:'18px',
            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                mt:'8px',
            },
        }}>
            <WalletContentMainLeftWithdrawInput type="text" 
                placeholder={placeholder} 
                onChange={handleInputChanged} 
                sx={{
                    width:'100%',
                    height:'100%',
                }}
            />
        </Box>
    );
}

const WalletContentMainLeftWithdrawAddress = (props) => {
    const { 
        withdrawAddress,withdrawAmount,withdrawableAmount,amountReceived,minimumAmount,fee,next,
        chain,token,withdrawalAmountsFrom,insufficientBalance,pleaseEnterAddress,
    } = props;
    const theme = useTheme();
    const screenMD = useMediaQuery(theme.breakpoints.down(MobileBreakpoint.match));
    const quickAmountInputArr = ['25%','50%','100%'];
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const balance = useSelector((state) => state.wallet.balance);
    const fundingBalance = useSelector((state) => state.wallet.funding_balance);
    const totalBalance = BigNumber(balance).plus(BigNumber(fundingBalance)).toFixed();
    const isRestricted = useSelector((state) => state.user.user.is_credit);

    const [ address, setAddress ] = useState('');
    const [ amount, setAmount ] = useState('0');
    const [ receiptAmount,setReceiptAmount ] = useState('0');
    const [ openTip, setOpenTip ] = useState(false);

    const handleAddressInputChanged = (e) => {
        setAddress(e.target.value);
    };
    const handleAmountInputChanged = (e) => {
        let number = 0;
        const decimalIndex = filterDecimal(e.target.value).indexOf('.');
        if (decimalIndex !== -1) {
            number = filterDecimal(e.target.value).length - decimalIndex - 1;
        }
        if(number > 4) {
            setAmount(BigNumber(filterDecimal(e.target.value)).toFixed(4,1));
        }else {
            setAmount(filterDecimal(e.target.value));
        }
    };
    const handleWithdrawAmountQuestion = () => {
        console.log('click withdraw amount question');
    };
    const handleQuickAmountInput = (index) => {
        let percent = '0';
        if(index === 0) {
            percent = '0.25';
        }else if(index === 1) {
            percent = '0.5';
        }else if(index === 2) {
            percent = '1';
        }
        setAmount(BigNumber(totalBalance).multipliedBy(BigNumber(percent)).toFixed(4,1));
    };

    useEffect(() => {
        if(amount === '') {
            setReceiptAmount('0');
            return;
        }

        if(BigNumber(amount).minus(BigNumber(chain.withdraw_fee)).isLessThanOrEqualTo(BigNumber('0'))) {            setReceiptAmount('0');
        }else {
            setReceiptAmount(BigNumber(amount).minus(BigNumber(chain.withdraw_fee)).toFixed(4,1));
        }
    }, [amount,chain]);
    useEffect(()=>{
        window.addEventListener('storage', handleWithdrawSuccess);
        return () => {
            window.removeEventListener('storage', handleWithdrawSuccess);
        };
    },[]);
    const handleWithdrawSuccess = (event) => {
        if(event.key === 'ttBetWithdraw') {
            handleUserWalletInfos();
        }
    };
    const handleUserWalletInfos = async () => {
        try {
            const response = await userWalletInfosService();
            if(response.data.data.length > 0) {
                for (let index = 0; index < response.data.data.length; index++) {
                    const element = response.data.data[index];
                    if(element.symbol.toUpperCase() === 'USDT') {
                        dispatch(walletRefresh(element))
                        break;
                    }
                }
            }
        } catch (error) {
            console.log('userWalletInfos error:'+error);
        }
    };

    //alert 
    const [ type, setType ] = useState('info');
    const [ open, setOpen ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState('');
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    // const params = new URLSearchParams();
    // params.append('network', chain.platform_name);
    // params.append('networkType', chain.platform);
    // params.append('token', token);
    // params.append('address', address);
    // params.append('amount', amount);
    // params.append('gas', chain.withdraw_fee);
    // params.append('balance', balance);
    // params.append('fundingBalance', fundingBalance);
    // params.append('language', i18next.language);
    const handleWithdrawNextClick = () => {
        if(isRestricted) {
            setOpenTip(true);
            return;
        }

        if(address.trim().length <= 0) {
            setType('error');
            setAlertMessage(pleaseEnterAddress);
            setOpen(true);
            return;
        }
        if(BigNumber(amount).isLessThan(BigNumber('50')) || 
            BigNumber(amount).isGreaterThan(BigNumber('9000'))) {
            setType('error');
            setAlertMessage(`${withdrawalAmountsFrom} 50 - 9000 ${token}`);
            setOpen(true);
            return;
        }
        if(BigNumber(amount).isGreaterThan(BigNumber(totalBalance))) {
            setType('error');
            setAlertMessage(insufficientBalance);
            setOpen(true);
            return;
        }
        // const link = document.createElement('a');
        // link.href = `${RoutePath.withdraw}?${params.toString()}`;
        // link.target = '_blank';
        // link.click();
        navigate(RoutePath.withdraw,
            { 
                state: { 
                    network:chain.platform_name,
                    networkType:chain.platform,
                    token:token,
                    address:address,
                    amount:amount,
                    gas:chain.withdraw_fee,
                    balance:balance,
                    fundingBalance:fundingBalance,
                    language:i18next.language,
                }
            }
        );
    };
    const handleTipClose = () => {
        setOpenTip(false);
    }

    return (
        <React.Fragment>
            <TTAlert 
                type={type}
                isOpen={open}
                message={alertMessage}
                closeCallback={handleAlertClose}
            />
            <WalletContentMainTip 
                isOpen={openTip}
                handleCloseClick={handleTipClose}
            />

            <Box sx={{
                bgcolor:'#1D1D28',
                borderRadius:'18px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                width:'calc(100% - 36px)',
                padding:'18px',
                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                    borderRadius:'0px',
                },
            }}>
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'20px',
                    fontStyle:'normal',
                    fontWeight:700,
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                    },
                }}>
                    {`· ${withdrawAddress}`}
                </Typography>
                <FormControl sx={{
                    width:'calc(100% - 50px)',
                }}>
                    <WalletContentMainLeftWithdrawAddressInput 
                        placeholder={withdrawAddress}
                        handleInputChanged={handleAddressInputChanged}
                    />
                </FormControl>
                {screenMD ? 
                    <Box sx={{bgcolor:'#171721',width:'calc(100% + 36px)',ml:'-18px',mt:'18px',height:'8px'}} /> : ''
                }
                <Typography sx={{
                    color:'#6D728D',
                    fontSize:'20px',
                    fontStyle:'normal',
                    fontWeight:700,
                    mt:'36px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                        mt:'18px',
                    },
                }}>
                    {`· ${withdrawAmount}`}
                </Typography>
                {/* <Button variant='text' disableRipple sx={{
                    color:'#6D728D',
                    fontSize:'20px',
                    fontStyle:'normal',
                    fontWeight:400,
                    textTransform:'none',
                    textDecoration:'none',
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    gap:'10px',
                    padding:'0px',
                    mt:'18px',
                    bgcolor:'transparent',
                    '&:hover': {
                        bgcolor:'transparent',
                        filter: 'brightness(0) invert(1)',
                    },
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        fontSize:'15px',
                    },
                }} onClick={handleWithdrawAmountQuestion}>
                    {`· ${withdrawableAmount} ${'USDT'} ${amount.trim() === '' ? '0.0' : amount}`}
                    <CardMedia
                        component="img"
                        image={questionIcon}
                        sx={{
                            width:'24px',
                            height:'24px',
                            objectFit:'contain',
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                width:'18px',
                                height:'18px',
                            },
                        }}
                    />
                </Button> */}
                <Grid container sx={{
                    bgcolor:'#0D0F13',
                    borderRadius:'18px',
                    padding:'18px',
                    mt:'18px',
                    [theme.breakpoints.down(MobileBreakpoint.match)]: {
                        padding:'8px 18px 18px 18px',
                    },
                }}>
                    <FormControl sx={{
                        width:'100%',
                    }}>
                        <WalletContentMainLeftWithdrawInput type="text" 
                            placeholder={minimumAmount} 
                            value={amount}
                            onChange={handleAmountInputChanged} 
                            sx={{
                                width:'100%',
                                height:'24px',
                            }}
                        />
                    </FormControl>
                    <Grid container sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        gap:'18px',
                        padding:'0px 0px',
                        mt:'18px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            mt:'8px',
                            gap:'8px',
                        },
                    }}>
                        {quickAmountInputArr.map((item,index) => (
                            <Button variant="text" sx={{
                                bgcolor:'#2A2A39',
                                borderRadius:'8px',
                                width:'180px',
                                height:'46px',
                                cursor:'pointer',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                gap:'13px',
                                color:'#ACB3D7',
                                fontSize:'20px',
                                fontStyle:'normal',
                                fontWeight:400,
                                '&:hover': {
                                    bgcolor: '#2A2A39',
                                },
                                [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                    width:'calc((100% - 24px) / 3)',
                                    height:'38px',
                                    fontSize:'15px',
                                },
                            }} key={index} onClick={() => handleQuickAmountInput(index)}>
                                {item}
                            </Button>
                        ))}
                    </Grid>
                    <Typography sx={{
                        color:"#6D728D",
                        fontSize:'20px',
                        fontStyle:'normal',
                        fontWeight:400,
                        width:'100%',
                        textAlign:'right',
                        mt:'36px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'16px',
                            mt:'18px',
                        },
                    }}>
                        {`${fee}: ${token} `}
                        <span style={{color:'#FFFFFF'}}>{chain.withdraw_fee.trim() === '' ? '0' : chain.withdraw_fee.split('.')[0]}</span>
                        {'.'}
                        {chain.withdraw_fee.trim() === '' ? '0' : (chain.withdraw_fee.split('.').length > 1 ? chain.withdraw_fee.split('.')[1] : '0')}
                    </Typography>
                    <Typography sx={{
                        color:"#6D728D",
                        fontSize:'20px',
                        fontStyle:'normal',
                        fontWeight:400,
                        width:'100%',
                        textAlign:'right',
                        mt:'10px',
                        [theme.breakpoints.down(MobileBreakpoint.match)]: {
                            fontSize:'16px',
                            mt:'18px',
                        },
                    }}>
                        {amountReceived}
                        <span style={{color:'#FFD233'}}>{` ${token} `}</span>
                        <span style={{color:'#FFFFFF'}}>{receiptAmount.trim() === '' ? '0' : receiptAmount.split('.')[0]}</span>
                        {'.'}
                        {receiptAmount.trim() === '' ? '0000' : (receiptAmount.split('.').length > 1 ? receiptAmount.split('.')[1] : '0000')}                    </Typography>
                    {/* <a href={`${RoutePath.withdraw}?${params.toString()}`} target='_blank' rel="noreferrer" style={{textDecoration:'none',width:'100%',}}> */}
                        <Button variant="text" sx={{
                            bgcolor:'#1CA45F',
                            color:'#FFFFFF',
                            fontSize:'24px',
                            fontStyle:'normal',
                            fontWeight:500,
                            textTransform:'none',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            height:'48px',
                            width:'100%',
                            borderRadius:'24px',
                            mt:'18px',
                            '&:hover': {
                                bgcolor:'#1CA45F',
                                color: '#FFFFFF',
                            },
                            [theme.breakpoints.down(MobileBreakpoint.match)]: {
                                fontSize:'18px',
                            },
                        }} onClick={handleWithdrawNextClick}>
                            {next}
                        </Button>
                    {/* </a> */}
                </Grid>
            </Box>
        </React.Fragment>
    );
}

export default WalletContentMainLeftWithdrawAddress;

import { createSlice } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';

const initialState = {
    symbol:'USDT', // 币种
    balance:'0.0000', // 现货余额
    funding_balance:'0.0000', // 资金余额
    totalBalance:'0.0000', // 总余额(现货+资金)
    recordList:[],
    menuCategoryIndex:0,
}


const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        walletRefresh(state,action) {
            state.symbol = action.payload.symbol;
            state.balance = action.payload.balance;
            state.funding_balance = action.payload.funding_balance;
            state.totalBalance = BigNumber(action.payload.balance).plus(BigNumber(action.payload.funding_balance)).toFixed(4,1);
        },
        walletTransfer(state,action) {
            state.balance = BigNumber(state.balance).minus(BigNumber(action.payload.amount)).toFixed();
            state.funding_balance = BigNumber(state.funding_balance).plus(BigNumber(action.payload.amount)).toFixed();
            console.log('walletTransfer state:'+JSON.stringify(state));
        },
        walletWithdraw(state,action) {
            state.funding_balance = BigNumber(state.funding_balance).minus(BigNumber(action.payload.amount)).toFixed();
            console.log('walletWithdraw state:'+JSON.stringify(state));
        },
        walletLogout(state) {
            state.balance = '0.0000';
            state.funding_balance = '0.0000';
            state.totalBalance = '0.0000';
            console.log('walletLogout state:'+JSON.stringify(state));
        },
        walletRecordRefresh(state,action) {
            state.recordList.length = 0;
            state.recordList = [].concat(action.payload.recordList);
            console.log('walletRecordRefresh state:'+JSON.stringify(state));
        },
        walletGameBalanceChanged(state,action) {         
            // 判断输赢   
            if(BigNumber(action.payload.won).isGreaterThan(BigNumber('0'))) {
                state.balance = BigNumber(state.balance).plus(BigNumber(action.payload.won)).toFixed();
            }else {
                const lockedNum = BigNumber(state.balance).plus(BigNumber(action.payload.won)).toFixed();
                if(!BigNumber(lockedNum).isGreaterThan(BigNumber('0'))) {
                    state.balance = '0.0000';
                    const free = BigNumber(state.funding_balance).plus(BigNumber(lockedNum)).toFixed();
                    state.funding_balance = BigNumber(free).isGreaterThan(BigNumber('0')) ? free : '0.0000';
                }else {
                    state.balance = lockedNum.toString();
                }
            }
            state.totalBalance = BigNumber(state.balance).plus(BigNumber(state.funding_balance)).toFixed(4,1);
            console.log('walletGameBalanceChanged state:'+JSON.stringify(state));
        },
        walletGameToBalance(state,action) {
            state.totalBalance = BigNumber(action.payload.totalBalance).toFixed();
            console.log('walletGameToBalance state:'+JSON.stringify(state));
        },
        walletMenuCategoryIndexRefresh(state,action) {
            state.menuCategoryIndex = action.payload.menuCategoryIndex;
        }
    }
});

export const { 
    walletRefresh,
    walletTransfer,
    walletWithdraw,
    walletLogout,
    walletRecordRefresh,
    walletGameBalanceChanged,
    walletGameToBalance,
    walletMenuCategoryIndexRefresh,
} = walletSlice.actions;

export default walletSlice.reducer;
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TokenRecordContentMain from './TokenRecordContentMain';
import Footer from '../footer/Footer';

const TokenRecordContent = (props) => {
    const { token,category,screenMD } = props;

    React.useEffect(() => {
        window.scrollTo({ 
            left: 0,
            top: 0,
            behavior: 'auto'
      });
    },[]);

    return (
        <Grid sx={{
            pl:screenMD ? '0px' : '180px',
            pt:screenMD ? '68px' : '64px',
        }}>
            <Box sx={{
                display:'flex',
                flexDirection:'column',
                justifyContent:screenMD ? 'flex-start' : 'space-between',
            }}>
                <TokenRecordContentMain 
                    token={token}
                    category={category}
                />
                {screenMD ? '' : <Footer />}
            </Box>
        </Grid>
    );
}

export default TokenRecordContent;